import endpoints from '../ruutsApi/endpoints'
import postApiData from '../ruutsApi/postApiData'

export const saveFarmSubdivision = async (currentFarm, data, token) => {
  try {
    const farmSubdivisionBody = {
      paddockIds: data.paddockIds,
      activitiesStatus: [
        {
          name: 'G',
          fullName: 'General',
          status: 'empty',
          selectedPaddocksIds: [],
        },
        {
          name: 'L',
          fullName: 'Loteo',
          status: 'empty',
          selectedPaddocksIds: [],
        },
        {
          name: 'Pa',
          fullName: 'Pastoreo',
          status: 'empty',
          selectedPaddocksIds: [],
        },
        {
          name: 'FO',
          fullName: 'Fertilizantes orgánicos',
          status: 'empty',
          selectedPaddocksIds: [],
        },
        {
          name: 'FS',
          fullName: 'Fertilizantes sintéticos',
          status: 'empty',
          selectedPaddocksIds: [],
        },
        {
          name: 'La',
          fullName: 'Labranza',
          status: 'empty',
          selectedPaddocksIds: [],
        },
        {
          name: 'Ag',
          fullName: 'Agua',
          status: 'empty',
          selectedPaddocksIds: [],
        },
        {
          name: 'Co',
          fullName: 'Combustible',
          status: 'empty',
          selectedPaddocksIds: [],
        },
        {
          name: 'Cu',
          fullName: 'Cultivos',
          status: 'empty',
          selectedPaddocksIds: [],
        },
      ],
      year: data.year,
      farmId: currentFarm.id,
    }
    const farmSubdivisionStatus = await postApiData(
      endpoints.farmSubdivisions,
      farmSubdivisionBody,
      token,
    )
    return farmSubdivisionStatus
  } catch (error) {
    throw new Error('APIError', 'Error saving farm subdivision', error.stack)
  }
}
