import * as turf from '@turf/turf'
import endpoints from '../ruutsApi/endpoints'
import updateApiData from '../ruutsApi/updateApiData'
import getApiData from '../ruutsApi/getApiData'

export const updateApiPerimeter = async (currentFarm, data, token, center) => {
  const farmBody = {
    id: currentFarm.id,
    data: {
      name: currentFarm.name,
      geometry: data.geometry,
      uncroppedGeometry: data.geometry,
      lat: center[0],
      lng: center[1],
      color: 'hsl(61, 90%, 71%)',
    },
  }
  await updateApiData(endpoints.farms, farmBody, token)
}

export const validatePerimeter = async ({
  id,
  geometry,
  originalGeometry,
  dryRun,
  overrideEdition,
  token,
}) => {
  const center = turf.center(geometry).geometry.coordinates
  const farmBody = {
    id,
    data: {
      perimeter: geometry,
      originalPerimeter: originalGeometry,
      lat: center[0],
      lng: center[1],
      color: 'hsl(61, 90%, 71%)',
    },
  }

  await updateApiData(
    `${endpoints.farms}/${id}/validate?dryRun=${dryRun}&overrideEdition=${overrideEdition}`,
    farmBody,
    token,
  )
}

export const validatePerimeterPreconditions = async ({ id, token, overrideEdition }) =>
  getApiData(
    `${endpoints.farms}/${id}/validate/preconditions?overrideEdition=${overrideEdition}`,
    null,
    token,
  )
