const agua = {
  namespace: 'farm_management_water',
  label: 'Uso de agua para riego',
  required: false,
  requiredMessage: 'No aplica: declaro no haber utilizado riego en todo el año',
  layout: {
    showMap: true,
    showTable: true,
  },
  fields: [
    {
      component_type: 'Title',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Uso de agua para riego',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'InfoText',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Los datos deben ser cargados por lote o conjunto de lotes con los mismos valores.',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'RadioGroup',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'irrigationTypeId',
      placeholder: 'Tipo de riego',
      label: 'Tipo de riego',
      defaultValue: 0,
      fullWidth: true,
      required: true,
      direction: 'column',
      dependencies: {
        disabled: [],
        display: [],
      },
      refDataKey: 'RefIrrigationType',
    },
    {
      component_type: 'TextField',
      type: 'number',
      defaultValue: 0,
      min: 0,
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'irrigationYrLt',
      unit: 'mm',
      placeholder: 'Cantidad de agua para riego anual',
      label: 'Cantidad de agua para riego anual',
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'irrigationEnergyConsumption',
      unit: 'Kw/h',
      placeholder: 'Energía para irrigación',
      label: 'Energía para irrigación',
      defaultValue: 0,
      min: 0,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [{ irrigationTypeId: { eq: '1' } }],
      },
    },
  ],
}

export default agua
