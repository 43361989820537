export async function processValidation({ contactSupportReasonType, doAction, getToken }) {
  try {
    const token = await getToken()
    const validation = await doAction({ token })

    return {
      contactSupportReasonType,
      value: validation.result,
      displayError: validation.reason,
      error: null,
    }
  } catch (error) {
    return {
      contactSupportReasonType,
      value: false,
      error,
      displayError:
        'Error al procesar lo datos, por favor intente nuevamente o contáctese con nosotros.',
    }
  }
}
