import L from 'leaflet'
import { GeoJSON } from './GeoJSON/index'

export const getPopupContent = layer => {
  const area = GeoJSON.area(layer.toGeoJSON())
  return {
    label: `Area: ${L.GeometryUtil.readableArea(area, true)}`,
    value: L.GeometryUtil.readableArea(area, true),
  }
}

export const getFeatureGroup = (map, type) => {
  let FG = null
  map.eachLayer(layer => {
    if (layer.options?.init_info?.farmLayerFG === type) {
      FG = layer
    }
  })
  return FG
}
