import { useAuth0 } from '@auth0/auth0-react'
import { ExpandMore, LogoutOutlined, RecentActors } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AppContext from '../../context/appContext'
import lfStore from '../../lfstore/lfStore'
import { roleTraduction } from '../../utils/roleTraduction'
import RuutsLogo from '../Icons/RuutsLogo'

const helpLink = process.env.REACT_APP_HELP_LINK

const NavBar = () => {
  const [value, setValue] = useState('')
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [program, setProgram] = useState(null)

  const {
    currentFarm,
    setCurrentFarm,
    setProgramConfig,
    hideNavbar,
    setConfirmationModalConfig,
    partialChanges,
    setPartialChanges,
    userRoleSelected,
    setUserRoleSelected,
    userRoles,
    setUserRoles,
    setUser,
  } = useContext(AppContext)
  const navigate = useNavigate()

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleTabChange = path => {
    if (partialChanges) {
      setConfirmationModalConfig({
        open: true,
        title: 'Cambios sin guardar',
        message: 'Si cambia de pantalla, se perderán los cambios. ¿Desea continuar?',
        confirmLabel: 'Continuar',
        cancelLabel: 'Cancelar',
        confirmAction: () => {
          setPartialChanges(false)
          navigate(path)
        },
      })
    } else {
      navigate(path)
    }
  }

  const { user, isAuthenticated, logout, getAccessTokenSilently } = useAuth0()

  const handleRole = event => {
    const selected = event.currentTarget.value
    lfStore.setItem('userRoleSelected', selected)
    setUserRoleSelected(selected)
  }

  const theme = useTheme()

  useEffect(() => {
    async function getCurrentFarm() {
      const farm = await lfStore.getItem('currentFarm')
      setCurrentFarm(farm)
    }
    getCurrentFarm()
  }, [setCurrentFarm])

  useEffect(() => {
    async function getProgramConfig() {
      setProgramConfig(await lfStore.getItem('programConfig'))
    }
    getProgramConfig()
  }, [setProgramConfig])

  const location = useLocation()

  useEffect(() => {
    const newValue = location.pathname.split('/', 2)[1]
    if (newValue !== 'tableau') {
      setValue(newValue)
    }
  }, [location])

  useEffect(() => {
    async function getProgramData() {
      const storedProgram = await lfStore.getItem('program')
      setProgram(storedProgram)
    }

    if (currentFarm) {
      getProgramData()
    }
  }, [currentFarm])

  useEffect(() => {
    if (user && user['/roles'].length > 0) {
      setUser({ email: user.email, getAccessTokenSilently })
      setUserRoles(user['/roles'])

      const getUserRoleSelected = async () => {
        // eslint-disable-next-line no-shadow
        const userRoles = user['/roles']
        const userRoleSelectedCached = await lfStore.getItem('userRoleSelected')
        const userRoleCachedIsAllowed = userRoles.includes(userRoleSelectedCached)
        const newUserRoleSelected = userRoleCachedIsAllowed ? userRoleSelectedCached : userRoles[0]
        setUserRoleSelected(newUserRoleSelected)
      }

      getUserRoleSelected()
    }
  }, [
    getAccessTokenSilently,
    userRoleSelected,
    setUserRoleSelected,
    userRoles,
    user,
    setUser,
    setUserRoles,
  ])

  return (
    <Stack direction="column" display={hideNavbar ? 'none' : 'inherit'}>
      <AppBar
        elevation={0}
        position="static"
        sx={{
          background: theme.palette.background.default,
          borderBottom: 1,
          borderColor: 'divider',
          color: grey[800],
        }}
      >
        <Container disableGutters maxWidth="false">
          <Toolbar disableGutters variant="dense">
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
              <Tabs aria-label="basic tabs example" sx={{ padding: '0px' }} value={value}>
                <Tab
                  icon={<RuutsLogo fontSize="large" />}
                  sx={{ padding: '0px' }}
                  value=""
                  onClick={() => {
                    handleTabChange('/')
                  }}
                />
                <Tab
                  disabled={!currentFarm?.valid}
                  label="Establecimiento"
                  sx={{ padding: '0px' }}
                  value="farms"
                  onClick={() => {
                    handleTabChange('/farms')
                  }}
                />
                <Tab
                  disabled={!currentFarm?.hasPerimeter}
                  label="Manejo"
                  sx={{ padding: '0px' }}
                  value="management"
                  onClick={() => {
                    handleTabChange('/management')
                  }}
                />
                <Tab
                  disabled={!currentFarm?.hasPerimeter}
                  label="Monitoreo"
                  sx={{ padding: '0px' }}
                  value="monitoring"
                  onClick={() => {
                    handleTabChange('/monitoring')
                  }}
                />
              </Tabs>
              <Button
                sx={{
                  padding: '0px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={() => {
                  window.open(helpLink, '_blank')
                }}
              >
                <Typography sx={{ color: 'grey' }}>AYUDA</Typography>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0, justifySelf: 'flex-end', mr: '1.5rem' }}>
              {isAuthenticated ? (
                <Stack alignItems="center" direction="row" display="flex" spacing={2}>
                  {currentFarm && (
                    <Stack
                      alignItems="right"
                      direction="column"
                      display="flex"
                      justifyContent="center"
                      spacing={0}
                      textAlign="right"
                    >
                      <Typography sx={{ lineHeight: '0.9rem' }} variant="bodyBold">
                        {currentFarm?.name.toUpperCase()}
                      </Typography>
                      <Typography sx={{ lineHeight: '0.8rem' }} variant="captionLight">
                        {`${program?.id !== 99 ? 'Programa: ' : ''}${program?.name}`}
                      </Typography>
                      <Typography sx={{ lineHeight: '0.8rem' }} variant="captionLight">
                        {`Rol: ${roleTraduction(userRoleSelected)}`}
                      </Typography>
                    </Stack>
                  )}

                  <Tooltip title="Open settings">
                    <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
                      <Avatar alt={user.name} src={user.picture} sx={{ width: 38, height: 38 }} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    keepMounted
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    id="menu-appbar"
                    open={Boolean(anchorElUser)}
                    sx={{ mt: '45px' }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem key="UserRole" sx={{ m: 0, p: 0 }}>
                      <Accordion sx={{ border: 'none', boxShadow: 'none' }}>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          expandIcon={<ExpandMore />}
                          id="panel1a-header"
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <RecentActors />
                            <Typography px={1}>
                              {userRoleSelected ? roleTraduction(userRoleSelected) : ''}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack>
                            {userRoles.map(role => (
                              <Button
                                key={role}
                                sx={{
                                  color:
                                    role === userRoleSelected ? 'selectedRole.main' : 'grey.500',
                                  fontWeight: 'normal',
                                  textTransform: 'capitalize',
                                  '&:hover': {
                                    backgroundColor: 'transparent',
                                  },
                                }}
                                value={role}
                                onClick={handleRole}
                              >
                                {roleTraduction(role)}
                              </Button>
                            ))}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </MenuItem>
                    <MenuItem key="Logout" onClick={() => logout()}>
                      <LogoutOutlined />
                      <Typography pl={1} textAlign="center">
                        Cerrar sesión
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Stack>
              ) : (
                <Button>LOGIN</Button>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Stack>
  )
}

export default NavBar
