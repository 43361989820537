import postApiData from '../ruutsApi/postApiData'
import endpoints from '../ruutsApi/endpoints'

const namespace = 'rpd'

async function readFileAsDataURL(fileBlob, fileLocationURL, token) {
  const reader = new FileReader()
  reader.readAsDataURL(fileBlob)

  return new Promise(resolve => {
    reader.onloadend = () => {
      const fileDataURL = reader.result
      const reqBody = { file: fileDataURL }
      resolve(postApiData(`${endpoints.objectStore}/${fileLocationURL}`, reqBody, token))
    }
  })
}

export default async function uploadFiles(directoryName, files, token) {
  try {
    return await Promise.all(
      files.map(async file => {
        const directoryNameEncoded = encodeURIComponent(directoryName)
        const fileNameEncoded = encodeURIComponent(file.file.name)
        const fileLocationUrl = `${namespace}/${directoryNameEncoded}/${fileNameEncoded}`

        return readFileAsDataURL(file.file, fileLocationUrl, token).then(
          fileDataURL => fileDataURL.payload.url,
        )
      }),
    )
  } catch (e) {
    console.error(e)
    throw new Error('Unable to upload files to S3')
  }
}
