// Material UI
import { Box, Typography } from '@mui/material'

const MapeoHeader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 3,
      }}
    >
      <Typography
        component="span"
        sx={{
          fontWeight: 600,
          fontSize: 18,
        }}
      >
        Mapeo de establecimiento
      </Typography>
      <Typography
        component="span"
        sx={{
          fontWeight: 300,
          fontSize: 16,
          m: 2,
        }}
      >
        Es necesario definir los límites del establecimiento antes de comenzar con la carga de
        información.
      </Typography>
    </Box>
  )
}

export default MapeoHeader
