import { useAuth0 } from '@auth0/auth0-react'
import Container from '@mui/material/Container'
import * as parseGeoraster from 'georaster'
import GeoRasterLayer from 'georaster-layer-for-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useContext, useEffect, useState } from 'react'
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet'
import AppContext from '../../context/appContext'
import { processLoading } from '../../utils/Loading/processLoading'
import { getRandomColor } from '../../utils/mapDrawHandle'

const ClassificationPreview = ({ perimeter, samplingAreas, georaster, mapWidth, mapHeight }) => {
  const [map, setMap] = useState()
  const { getAccessTokenSilently } = useAuth0()
  const { setLoadingModalConfig } = useContext(AppContext)

  const handleSamplingAreasStyle = _ => {
    const color = getRandomColor()

    return {
      fillColor: color,
      color,
      weight: 1,
      opacity: 1,
      fillOpacity: 0.5,
    }
  }

  const handlePerimeterStyle = _ => {
    return {
      color: 'black',
      weight: 3,
      opacity: 1,
      fillOpacity: 0,
    }
  }

  useEffect(() => {
    if (map && samplingAreas) {
      map.fitBounds(L.geoJSON(samplingAreas).getBounds(), { padding: [200, 200] })
    }
  }, [map, samplingAreas])

  useEffect(() => {
    async function loadGeoRaster() {
      await processLoading({
        getToken: getAccessTokenSilently,
        setLoadingModalConfig,
        loadingMessage: 'Cargando previsualización...',
        successfulMessage: '',
        errorMessage: 'Error al cargar la previsualización',
        doAction: async ({ _ }) => {
          const georesterParsed = await parseGeoraster(georaster.data)

          const layer = new GeoRasterLayer({
            georaster: georesterParsed,
            opacity: 0.9,
            pixelValuesToColorFn: values => {
              return values.map(value => {
                if (value === null || value === 0) {
                  return 'transparent'
                }
                const hue = (value / 10) * 360 + 100
                const saturation = 50
                const lightness = 50
                return `hsl(${hue}, ${saturation}%, ${lightness}%)`
              })
            },
            resolution: 2048,
          })

          layer.addTo(map)
          map.fitBounds(layer.getBounds(), { padding: [200, 200] })
        },
      })
    }

    if (map) {
      // Remove previous GeoRasterLayer if exists
      map.eachLayer(layer => {
        if (layer instanceof GeoRasterLayer) {
          map.removeLayer(layer)
        }
      })

      if (georaster) loadGeoRaster()
    }
  }, [map, georaster])

  return (
    <Container disableGutters sx={{ width: '100%' }}>
      <MapContainer
        ref={setMap}
        scrollWheelZoom
        zoomControl
        attributionControl={false}
        closePopupOnClick={false}
        doubleClickZoom={false}
        dragging={false}
        style={{ height: mapHeight, width: mapWidth }}
        touchZoom={false}
        trackResize={false}
        wheelPxPerZoomLevel={60}
        zoom={5}
        zoomDelta={1}
        zoomSnap={0.1}
      >
        <TileLayer
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
        />
        {samplingAreas && <GeoJSON data={samplingAreas} style={handleSamplingAreasStyle} />}
        {perimeter && <GeoJSON data={perimeter} style={handlePerimeterStyle} />}
      </MapContainer>
    </Container>
  )
}

export default ClassificationPreview
