import { Box, Chip, Typography } from '@mui/material'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import ColorDot from './ColorDot'
// import { useContext } from "react";
// import AppContext from "../../../context/appContext";

// React router
// import { useNavigate } from 'react-router-dom';

const AccordionHeader = ({
  title,
  elements,
  color,
  warning = false,
  // saved, withCTA = null, CTAlabel = null, innerTab = null
}) => {
  // const { setInnerTab } = useContext(AppContext);
  // const navigate = useNavigate();

  // const callToAction = () => {
  //     if (withCTA !== null) {
  //         setInnerTab(innerTab);
  //         navigate(withCTA);
  //     }
  // }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ColorDot color={color} />
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            marginX: 1,
          }}
        >
          {title}
        </Typography>
        {/*
                    saved &&
                    <Button
                        sx={{ 
                            display: withCTA ? 'flex' : 'none',
                            p:0,
                            m:0,
                        }}
                        onClick={callToAction}
                    >
                        <Typography textTransform={'none'} >{CTAlabel}</Typography>
                    </Button>
                */}
        {warning && <WarningAmberRoundedIcon sx={{ color: 'warning.main', marginLeft: 1 }} />}
      </Box>
      <Chip label={`${elements.length === undefined ? '1' : elements.length}`} />
    </Box>
  )
}

export default AccordionHeader
