import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import { useEffect, useState } from 'react'

const CheckboxesGroup = ({ options, onResultChange, disabled, required }) => {
  const [result, setResult] = useState([])

  useEffect(() => {
    if (!result?.length) {
      onResultChange([])
    } else {
      onResultChange(result)
    }
  }, [result])

  const handleChange = value => {
    setResult(prev => {
      let newResult = [...prev]
      if (newResult.includes(value)) {
        newResult = newResult.filter(v => v !== value)
      } else {
        newResult.push(value)
      }
      return newResult
    })
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl component="fieldset" disabled={disabled} sx={{ m: 2 }} variant="standard">
        <FormGroup>
          {options &&
            options.map(option => (
              <FormControlLabel
                key={option.label}
                control={<Checkbox defaultChecked={false} />}
                label={option.label}
                name={option.label}
                onChange={() => handleChange(option.value)}
              />
            ))}
        </FormGroup>
        {required && result?.length && (
          <FormHelperText sx={{ color: 'red' }}>
            Debe seleccionar al menos una opción
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  )
}

export default CheckboxesGroup
