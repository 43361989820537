export function getConfig(env) {
  return {
    local: {
      domain: 'dev-3k15sordpab5eoud.us.auth0.com',
      clientId: '6AYAG4i0GWNgT4xYcR1b4ksv3tulYm2f',
      audience: 'https://api-dev.ruuts.la',
    },
    development: {
      domain: 'dev-3k15sordpab5eoud.us.auth0.com',
      clientId: '6AYAG4i0GWNgT4xYcR1b4ksv3tulYm2f',
      audience: 'https://api-dev.ruuts.la',
    },
    staging: {
      domain: 'dev-3k15sordpab5eoud.us.auth0.com',
      clientId: 'E5hRBrSbI7GTv6GVNUO2Fsuof9C0WsBG',
      audience: 'https://api-dev.ruuts.la',
    },
    training: {
      domain: 'dev-3k15sordpab5eoud.us.auth0.com',
      clientId: 'E5hRBrSbI7GTv6GVNUO2Fsuof9C0WsBG',
      audience: 'https://api-dev.ruuts.la',
    },
    production: {
      domain: 'ruuts.us.auth0.com',
      clientId: 'ngElO73mIG4cJaxovRUq2gNm9cJqRUZE',
      audience: 'https://api.ruuts.la',
    },
  }[env]
}
