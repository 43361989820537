import setRefData from './localRefData/setRefData'
import endpoints from './ruutsApi/endpoints'
import getApiData from './ruutsApi/getApiData'
import { referenceDataKeys } from './localRefData'

export default async function getReferenceData(token) {
  try {
    const refDataEndpoints = [
      {
        key: referenceDataKeys.activities,
        endpoint: endpoints.taskStatus,
      },
      {
        key: referenceDataKeys.workflows,
        endpoint: endpoints.workflows,
      },
      {
        key: referenceDataKeys.exclusionAreaTypes,
        endpoint: endpoints.exclusionAreaTypes,
      },
      {
        key: referenceDataKeys.fieldUsage,
        endpoint: endpoints.fieldUsage,
      },
      {
        key: referenceDataKeys.livestockRaisingType,
        endpoint: endpoints.livestockRaisingType,
      },
      {
        key: referenceDataKeys.forageSource,
        endpoint: endpoints.forageSource,
      },
      {
        key: referenceDataKeys.pasturesGrowthType,
        endpoint: endpoints.pasturesGrowthType,
      },
      {
        key: referenceDataKeys.pasturesType,
        endpoint: endpoints.pasturesType,
      },
      {
        key: referenceDataKeys.pasturesFamily,
        endpoint: endpoints.pasturesFamily,
      },
      {
        key: referenceDataKeys.grazingType,
        endpoint: endpoints.grazingType,
      },
      {
        key: referenceDataKeys.grazingPlanType,
        endpoint: endpoints.grazingPlanType,
      },
      {
        key: referenceDataKeys.fuelType,
        endpoint: endpoints.fuelType,
      },
      {
        key: referenceDataKeys.findingType,
        endpoint: endpoints.findingType,
      },
      {
        key: referenceDataKeys.cattleClass,
        endpoint: endpoints.cattleClass,
      },
      {
        key: referenceDataKeys.cattleSubClass,
        endpoint: endpoints.cattleSubClass,
      },
      {
        key: referenceDataKeys.cattleType,
        endpoint: endpoints.cattleType,
      },
      {
        key: referenceDataKeys.bovineCattle,
        endpoint: endpoints.bovineCattle,
      },
      {
        key: referenceDataKeys.ovineCattle,
        endpoint: endpoints.ovineCattle,
      },
      {
        key: referenceDataKeys.equineCattle,
        endpoint: endpoints.equineCattle,
      },
      {
        key: referenceDataKeys.bubalineCattle,
        endpoint: endpoints.bubalineCattle,
      },
      {
        key: referenceDataKeys.caprineCattle,
        endpoint: endpoints.caprineCattle,
      },
      {
        key: referenceDataKeys.cervidCattle,
        endpoint: endpoints.cervidCattle,
      },
      {
        key: referenceDataKeys.camelidCattle,
        endpoint: endpoints.camelidCattle,
      },
      {
        key: referenceDataKeys.organicFertilizerType,
        endpoint: endpoints.organicFertilizerType,
      },
      {
        key: referenceDataKeys.mineralFertilizerType,
        endpoint: endpoints.mineralFertilizerType,
      },
      {
        key: referenceDataKeys.ammendmendType,
        endpoint: endpoints.ammendmendType,
      },
      {
        key: referenceDataKeys.tillageType,
        endpoint: endpoints.tillageType,
      },
      {
        key: referenceDataKeys.irrigationType,
        endpoint: endpoints.irrigationType,
      },
      {
        key: referenceDataKeys.cropType,
        endpoint: endpoints.cropType,
      },
      {
        key: referenceDataKeys.dataCollectionStatementStatus,
        endpoint: endpoints.dataCollectionStatementStatus,
      },
      {
        key: referenceDataKeys.grazingIntensityType,
        endpoint: endpoints.grazingIntensityType,
      },
      {
        key: referenceDataKeys.degreeDegradationSoil,
        endpoint: endpoints.degreeDegradationSoil,
      },
      {
        key: referenceDataKeys.documentType,
        endpoint: endpoints.documentType,
      },
      {
        key: referenceDataKeys.metricEventStatus,
        endpoint: endpoints.metricEventStatus,
      },
    ]

    await Promise.all(
      refDataEndpoints.map(async refData => {
        const data = await getApiData(refData.endpoint, null, token)
        setRefData(refData.key, data)
      }),
    )
  } catch (error) {
    console.error(error.message)
    throw new Error(`Unable to get reference data from API: ${error.message}`)
  }
}
