import { Button, CircularProgress, Stack, Typography } from '@mui/material'

const YearSelector = ({
  title,
  years,
  savedYears,
  selectedYears,
  setSelectedYears,
  multiselectYears,
}) => {
  return (
    <Stack
      direction="column"
      sx={{
        borderRadius: 4,
        backgroundColor: selectedYears.length ? '#f3f7f8' : '#f8f3f3',
        padding: 2,
        margin: 2,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Typography
        sx={{
          fontSize: 14,
          margin: 1,
          fontWeight: 'bold',
          // color: selectedYears.length ? "initial" : "red",
        }}
      >
        {/* {!selectedYears.length ? title:"Año de loteo"} */}
        {title}
      </Typography>
      {years.length > 0 ? (
        // flexbox margin top on every row
        <Stack
          direction="row"
          sx={{
            textAlign: 'center',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {years.map(year => {
            return (
              <Button
                key={year}
                color="primary"
                disabled={
                  (!multiselectYears && savedYears.indexOf(year) === -1) ||
                  (multiselectYears && savedYears.includes(year))
                }
                size="small"
                sx={{ marginTop: '3px', fontSize: '12px', minWidth: '50px', borderRadius: '10px' }}
                value={year}
                variant={selectedYears.includes(year) ? 'contained' : 'outlined'}
                onClick={() => {
                  if (multiselectYears) {
                    setSelectedYears(() => {
                      if (selectedYears.includes(year)) {
                        return selectedYears.filter(y => y !== year)
                      }
                      return [...selectedYears, year]
                    })
                  } else {
                    setSelectedYears([year])
                  }
                }}
              >
                {year}
              </Button>
            )
          })}
        </Stack>
      ) : (
        <CircularProgress sx={{ margin: 2 }} />
      )}
    </Stack>
  )
}

export default YearSelector
