import { ExpandMore, Send } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import FindingCommentCard from './FindingCommentCard'
import { findingCommentsServices } from '../../services/ruutsApi/findingComments'
import { dataCollectionStatementService } from '../../services/farmMapping/datacollectionStatement'
import AppContext from '../../context/appContext'

const FindingCommentsAccordion = ({ finding }) => {
  const { getAccessTokenSilently } = useAuth0()

  const { userRoleSelected, currentFarm } = useContext(AppContext)

  const [expanded, setExpanded] = useState(false)
  const [comments, setComments] = useState([])
  const [actualComment, setActualComment] = useState('')
  const [loading, setLoading] = useState(false)

  const sendComment = async () => {
    setLoading(true)
    try {
      if (actualComment) {
        const token = await getAccessTokenSilently()
        const dataCollectionStatements = await dataCollectionStatementService.getByFarmId(
          currentFarm.id,
          userRoleSelected,
          token,
        )
        const dataCollectionStatement = dataCollectionStatements.find(statement =>
          statement.metricsEventsIds.includes(finding.metricEventId),
        )

        await findingCommentsServices.saveComment(
          dataCollectionStatement.id,
          finding.id,
          userRoleSelected,
          actualComment,
          token,
        )
        const commentsFromFinding = await findingCommentsServices.getCommentsByFinding(
          finding.id,
          token,
        )
        setComments(commentsFromFinding)
        setActualComment('')
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    const getFindingComments = async () => {
      const token = await getAccessTokenSilently()
      const commentsFromFinding = await findingCommentsServices.getCommentsByFinding(
        finding.id,
        token,
      )
      setComments(commentsFromFinding)
    }

    if (finding) {
      getFindingComments()
    }
  }, [finding, getAccessTokenSilently])

  return (
    <Stack direction="column" pb={0.5} width="100%">
      <Accordion
        expanded={expanded}
        sx={{
          width: '100%',
          boxShadow: 'none',
        }}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            margin: 0,
            minHeight: '30px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
            '& .MuiAccordionSummary-root': {
              minHeight: '30px',
              '&.Mui-expanded': {
                minHeight: '30px',
              },
            },
          }}
        >
          <Typography sx={{ width: '50%', textAlign: 'start', fontSize: 14 }}>
            Comentarios
            <Badge
              badgeContent={comments.length}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: 'blue',
                  color: 'white',
                  fontSize: '0.6rem',
                  fontWeight: 'bold',
                  minWidth: '18px',
                  minHeight: '16px',
                  padding: '0 6px',
                  right: '-20px',
                },
              }}
            />
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {comments.length > 0 ? (
            <Paper
              elevation={0}
              sx={{
                padding: 1,
                margin: 0.5,
                borderRadius: '5px 5px 5px 5px',
                border: '1px solid #dcdcdc',
                backgroundColor: 'inherit',
              }}
            >
              {comments.map(comment => {
                return <FindingCommentCard key={comment.id} comment={comment} />
              })}
            </Paper>
          ) : null}
          <Grid container m={0.5}>
            <Grid xs={11}>
              <TextField
                fullWidth
                multiline
                disabled={loading}
                rows={2}
                value={actualComment}
                onChange={e => setActualComment(e.target.value)}
              />
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              xs={1}
            >
              <Button
                endIcon={
                  loading ? (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: 'white',
                      }}
                    />
                  ) : (
                    <Send />
                  )
                }
                sx={{
                  minWidth: 0,
                  maxWidth: 0,
                  '& .MuiButton-endIcon': {
                    margin: 0,
                  },
                }}
                variant="contained"
                onClick={sendComment}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Stack>
  )
}

export default FindingCommentsAccordion
