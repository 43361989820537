import { useState } from 'react'
import { Box, Button, Grid, Modal, Typography, TextField } from '@mui/material'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}

const ReRandomizeModal = ({
  open,
  strata,
  index,
  handleCloseReRandomizeModal,
  handleConfirmReRandomizeModal,
  saveLabel,
  cancelLabel,
}) => {
  const [randomizeReason, setRandomizeReason] = useState('')
  return (
    <Modal open={open} onClose={handleCloseReRandomizeModal}>
      <Box sx={modalStyle}>
        <ReportProblemOutlinedIcon
          sx={{
            position: 'absolute',
            top: -30,
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: 60,
            backgroundColor: 'white',
            color: '#ff9800',
            padding: 1,
            borderRadius: '50%',
          }}
        />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            margin: 2,
          }}
        >
          Indique la razón por la que desea re-aleatorizar este sitio de monitoreo.
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            margin: 2,
          }}
        >
          Es de suma importancia para la validez de los resultados de monitoreo a campo poder
          demostrar que los sitios de monitoreo fueron aleatorizados correctamente. Por lo tanto, se
          debe indicar la razón por la que se desea re-aleatorizar este sitio de monitoreo. <br />
          Cada sitio permite 2 iteraciones máximo.
        </Typography>
        <Grid container>
          <Grid item mb={3} xs={12}>
            <TextField onChange={e => setRandomizeReason(e.target.value)} />
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              disabled={!randomizeReason}
              variant="contained"
              onClick={() => handleConfirmReRandomizeModal(strata, index, randomizeReason)}
            >
              {saveLabel ?? 'Confirmar'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="primary" variant="outlined" onClick={handleCloseReRandomizeModal}>
              {cancelLabel ?? 'Cancelar'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default ReRandomizeModal
