import CustomAlert from './CustomAlert'
import { SupportButton } from '../Button/Button'

const SupportAlert = ({ contactSupportReasonType, message, error }) => {
  const reasonMessage = () => {
    return `${message} ${error ? `-${error}` : ''}`
  }

  return (
    <>
      <CustomAlert
        action={null}
        message={message}
        severity="warning"
        title={
          contactSupportReasonType
            ? contactSupportReasonType.title
            : 'Inconveniente en la plataforma'
        }
      />
      <SupportButton contactReasonSupportType={contactSupportReasonType} reason={reasonMessage()} />
    </>
  )
}

export default SupportAlert
