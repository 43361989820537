import endpoints from '../ruutsApi/endpoints'
import getApiData from '../ruutsApi/getApiData'

export default async function getFarmSubdivisionById(farm, token, id) {
  try {
    const farmSubdivisions = await getApiData(
      endpoints.farmSubdivisions,
      {
        id,
        farmId: farm.id,
      },
      token,
    )
    return farmSubdivisions[0]
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get farm sub divisions')
  }
}
