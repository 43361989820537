import endpoints from '../ruutsApi/endpoints'
import getApiData from '../ruutsApi/getApiData'

export default async function getOtherSites(farm, token) {
  try {
    const otherSites = await getApiData(
      endpoints.otherSites,
      {
        farmId: farm.id,
      },
      token,
    )
    return otherSites
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get form')
  }
}
