import { addPerimeter } from './FarmLayers/perimeter'

// Publicos
export const createArea = (layer, selectedFarmLayer, map, currentFarm) => {
  let newLayer = null
  switch (selectedFarmLayer) {
    case 'perimeter':
      newLayer = addPerimeter(layer, map, currentFarm)
      break
    default:
      // "default case"
      break
  }

  return newLayer
}

// Auxiliares de los demas handlers

export const getPerimeter = map => {
  let perimeter = null
  map.eachLayer(FGLayer => {
    if (FGLayer?.options?.init_info?.farmLayerFG === 'FG_perimeter') {
      FGLayer.eachLayer(layer => {
        perimeter = layer
      })
    }
  })
  return perimeter
}

export const switchCoords = coords => {
  const newCoords = []
  coords.forEach(ring => {
    const newRing = []
    ring.forEach(coord => {
      newRing.push([coord[1], coord[0], 0])
    })
    newCoords.push(newRing)
  })
  return newCoords
}

export const getPosibleFolderId = folder => {
  let posibleFolderId = 'unassigned'

  const folderNameWords = folder?.name?.split(' ') || []
  folderNameWords.forEach(word => {
    const folderName = word.toLowerCase()
    switch (folderName) {
      case 'perimetro':
      case 'perímetro':
      case 'perimeter':
        posibleFolderId = 'perimeter'
        break
      case 'paddocks':
      case 'paddock':
      case 'potreros':
      case 'potrero':
      case 'lote':
      case 'lotes':
        posibleFolderId = 'paddocks'
        break
      case 'estratas':
      case 'estrata':
      case 'stratas':
      case 'strata':
      case 'estratos':
      case 'estrato':
        posibleFolderId = 'estratas'
        break
      case 'mcp':
      case 'mlp':
        posibleFolderId = 'monitoringSites'
        break
      default:
        // keep posibleFolderId value
        break
    }
  })
  return posibleFolderId
}

export const sortAreas = areas => {
  const newFeatureGroup = areas
  newFeatureGroup.sort((a, b) => {
    const aArea = a.properties.area.split(' ')[0]
    const bArea = b.properties.area.split(' ')[0]
    return bArea - aArea
  })

  const squaremeters = []
  const hectares = []
  newFeatureGroup.forEach(feature => {
    const unit = feature.properties.area.split(' ')[1]
    if (unit === 'm2' || unit === 'm²') {
      squaremeters.push(feature)
    } else if (unit === 'ha') {
      hectares.push(feature)
    }
  })
  newFeatureGroup.features = hectares.concat(squaremeters)

  return newFeatureGroup
}
