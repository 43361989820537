import endpoints from '../ruutsApi/endpoints'
import getApiData from '../ruutsApi/getApiData'

export default async function getUnusedPaddocks(currentFarm, year, namespace, token) {
  const paddocks = await getApiData(
    `${endpoints.paddocks}/unused`,
    {
      farmId: currentFarm.id,
      year,
      namespace,
    },
    token,
  )
  return paddocks
}
