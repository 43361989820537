import { Auth0Provider } from '@auth0/auth0-react'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { getConfig } from './configAuth.js'
import './index.css'
import reportWebVitals from './reportWebVitals.js'

const config = getConfig(process.env.REACT_APP_ENV || process.env.NODE_ENV)

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin + window.location.pathname,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
}

const history = createBrowserHistory()
const onRedirectCallback = appState => {
  history.replace(appState?.returnTo ? appState.returnTo : window.location.pathname)
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig} onRedirectCallback={onRedirectCallback}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
