import { Box } from '@mui/material'
import { useState } from 'react'
import { MapContainer } from 'react-leaflet'
import MapElements from './MapElements'

const MapLayered = ({
  mapHeight,
  mapWidth,
  drawingType,
  center,
  etapa,
  handleEtapa,
  perimeter,
  handlePerimeter,
  paddocks,
  handlePaddocks,
  samplingAreas,
  handleSamplingAreas,
  monitoringSites,
  handleMonitoringSites,
  unassigned,
  handleUnassigned,
  unassignedPoints,
  handleUnassignedPoints,
  exclusionAreas,
  handleExclusionAreas,
  mapUpdate,
  handleMapUpdate,
  otherPolygons,
  handleOtherPolygons,
  otherSites,
  handleOtherSites,
}) => {
  const [map, setMap] = useState(null)

  return (
    <Box
      sx={{
        '& .leaflet-container': {
          width: mapWidth,
          height: mapHeight,
        },
      }}
    >
      <MapContainer
        ref={setMap}
        scrollWheelZoom
        zoomControl
        center={center}
        wheelPxPerZoomLevel={60}
        zoom={5}
        zoomDelta={1}
        zoomSnap={0.1}
      >
        <MapElements
          center={center}
          drawingType={drawingType}
          etapa={etapa}
          exclusionAreas={exclusionAreas}
          handleEtapa={handleEtapa}
          handleExclusionAreas={handleExclusionAreas}
          handleMapUpdate={handleMapUpdate}
          handleMonitoringSites={handleMonitoringSites}
          handleOtherPolygons={handleOtherPolygons}
          handleOtherSites={handleOtherSites}
          handlePaddocks={handlePaddocks}
          handlePerimeter={handlePerimeter}
          handleSamplingAreas={handleSamplingAreas}
          handleUnassigned={handleUnassigned}
          handleUnassignedPoints={handleUnassignedPoints}
          map={map}
          mapUpdate={mapUpdate}
          monitoringSites={monitoringSites}
          otherPolygons={otherPolygons}
          otherSites={otherSites}
          paddocks={paddocks}
          perimeter={perimeter}
          samplingAreas={samplingAreas}
          unassigned={unassigned}
          unassignedPoints={unassignedPoints}
        />
      </MapContainer>
    </Box>
  )
}

export default MapLayered
