import { useAuth0 } from '@auth0/auth0-react'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import * as turf from '@turf/turf'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext'
import endpoints from '../../services/ruutsApi/endpoints'
import { ruutsApi } from '../../services/ruutsApi/index'
import { GeoJSON } from '../../utils/GeoJSON/index'
import SamplePlanningMap from './SamplePlanningMap'
import SamplePlanningPanel from './SamplePlanningPanel'

const SamplePlanningHome = ({ setMonitoringSitesSaved }) => {
  const { getAccessTokenSilently } = useAuth0()
  const [farm, setFarm] = useState()
  const [perimeter, setPerimeter] = useState()
  const [mapWidth] = useState(window.innerWidth / 2)
  const [mapHeight] = useState(window.innerHeight)
  const [wasSaved, setWasSaved] = useState(false)
  const { currentFarm } = useContext(AppContext)
  const [stratas, setStratas] = useState()
  const [exclusionAreas, setExclusionAreas] = useState()
  const [monitoringSitesBySamplingAreas, setMonitoringSitesBySamplingAreas] = useState([])

  const createSamplingAreaForSitesWithoutSamplingAreasAssociated = sites => {
    let samplingAreaForSitesWihtoutSamplingAreas = null
    // Asociate all the monitong sites without sampling area to
    // the custom strato that don't belong to the farm
    const monitoringWihtoutSA = sites
      .filter(site => site.samplingAreaId == null)
      .map(siteWithoutSA => {
        const feature = turf.point([siteWithoutSA.location[1], siteWithoutSA.location[0]])
        feature.properties.name = siteWithoutSA.name
        feature.properties.id = siteWithoutSA.id
        feature.properties.isRandomSite = siteWithoutSA.isRandomSite
        return feature
      })

    if (monitoringWihtoutSA.length) {
      // In order to display monitoring sites that don't belong to a farm,
      // we need to create a custom strato feature and asociate them to it
      samplingAreaForSitesWihtoutSamplingAreas = turf.polygon([])
      samplingAreaForSitesWihtoutSamplingAreas.properties.name = 'Sin estrato asociado'
      samplingAreaForSitesWihtoutSamplingAreas.properties.color = 'black'
      samplingAreaForSitesWihtoutSamplingAreas.properties.belongToFarm = false
    }

    return [samplingAreaForSitesWihtoutSamplingAreas, monitoringWihtoutSA]
  }

  useEffect(() => {
    async function getFarm() {
      const token = await getAccessTokenSilently()
      const sArea = await ruutsApi.samplingAreas.getCroppedByFarm({ farmId: currentFarm.id, token })
      const sites = await ruutsApi.getApiData(endpoints.sites, { farmId: currentFarm.id }, token)
      const fC = turf.featureCollection([])

      sArea.forEach(sA => {
        const feature = sA.toGeoJSON
        feature.properties.color = sA.color
          ? sA.color
          : `#${Math.floor(Math.random() * 16777215).toString(16)}`
        feature.properties.area = GeoJSON.hectareArea(feature).toFixed(2)
        feature.properties.belongToFarm = true
        fC.features.push(feature)
      })

      setFarm(currentFarm)
      setPerimeter(currentFarm.toGeoJSON)

      if (sites.length) {
        const newMonitoringSitesBySamplingAreas = {}

        // Esta sección contempla los sitios que poseen estrato asociado
        sArea.forEach(area => {
          newMonitoringSitesBySamplingAreas[area.name] = sites.map(site => {
            if (site.samplingAreaId === area.id) {
              const feature = turf.point([site.location[1], site.location[0]])
              feature.properties.name = site.name
              feature.properties.id = site.id
              feature.properties.isRandomSite = site.isRandomSite
              feature.properties.strata = area.name
              return feature
            }
            return undefined
          })

          newMonitoringSitesBySamplingAreas[area.name] = newMonitoringSitesBySamplingAreas[
            area.name
          ].filter(site => {
            return site !== undefined
          })
        })

        // Esta sección contempla los sitios que no poseen estrato asociado
        const [samplingAreaForSitesWithoutSamplingAreas, monitoringWithoutSA] =
          createSamplingAreaForSitesWithoutSamplingAreasAssociated(sites)
        if (samplingAreaForSitesWithoutSamplingAreas) {
          fC.features.push(samplingAreaForSitesWithoutSamplingAreas)
          newMonitoringSitesBySamplingAreas[
            samplingAreaForSitesWithoutSamplingAreas.properties.name
          ] = monitoringWithoutSA
        }

        setMonitoringSitesBySamplingAreas(newMonitoringSitesBySamplingAreas)
        if (sites.filter(site => site.isRandomSite).length > 0) {
          setWasSaved(true)
        }
      }

      const newExclusionAreas = await ruutsApi.getApiData(
        endpoints.exclusionAreas,
        { farmId: currentFarm.id },
        token,
      )
      setExclusionAreas(newExclusionAreas)
      setStratas(fC)
    }
    getFarm()
  }, [currentFarm, getAccessTokenSilently])

  return (
    <Box>
      {' '}
      {currentFarm && exclusionAreas && stratas && (
        <Grid container>
          <Grid item xs={6}>
            <SamplePlanningPanel
              exclusionAreas={exclusionAreas}
              farm={farm}
              monitoringSitesBySamplingAreas={monitoringSitesBySamplingAreas}
              setMonitoringSitesBySamplingAreas={setMonitoringSitesBySamplingAreas}
              setMonitoringSitesSaved={setMonitoringSitesSaved}
              setWasSaved={setWasSaved}
              stratas={stratas}
              wasSaved={wasSaved}
            />
          </Grid>
          <Grid item xs={6}>
            <SamplePlanningMap
              mapHeight={mapHeight}
              mapWidth={mapWidth}
              monitoringSitesBySamplingAreas={monitoringSitesBySamplingAreas}
              perimeter={perimeter}
              stratas={stratas}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default SamplePlanningHome
