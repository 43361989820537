import * as turf from '@turf/turf'

export const defaultOptions = {
  precision: 7,
}

export const minimumPrecisionAllowed = 5

export function truncate(poly1, options = defaultOptions) {
  return turf.truncate(poly1, { ...options, mutate: true })
}

export function lessPrecision(options = defaultOptions) {
  return {
    ...options,
    precision: options.precision - 1,
  }
}
