export function getGeoJSONFromBounds(bounds) {
  const sw = bounds.getSouthWest()
  const ne = bounds.getNorthEast()

  const polygonCoordinates = [
    [sw.lng, sw.lat],
    [ne.lng, sw.lat],
    [ne.lng, ne.lat],
    [sw.lng, ne.lat],
    [sw.lng, sw.lat], // Closing the polygon by repeating the first coordinate
  ]

  return {
    geometry: {
      type: 'Polygon',
      coordinates: [polygonCoordinates],
    },
  }
}
