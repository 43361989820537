import formDefs from './formDefinitions'

export default async function getFormDefs(formKey) {
  try {
    return formDefs[formKey]
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get form')
  }
}
