import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Paper,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { findingsTypes } from '../../utils/constants'
import { findingsTypesConventions } from '../../utils/findingColors'
import FindingCard from './FindingCard'

const FindingAccordions = ({ findings, readOnly, handleMetricReset }) => {
  const [findingsClassified, setFindingsClassified] = useState([
    {
      type: 'Observación',
      nro: 0,
      findings: [],
      color: findingsTypesConventions.observed.color,
    },
    {
      type: 'No Conformidad a Corregir',
      nro: 0,
      findings: [],
      color: findingsTypesConventions.nonComplaintToFix.color,
    },
    {
      type: 'No Conformidad Grave',
      nro: 0,
      findings: [],
      color: findingsTypesConventions.nonComplaintSevere.color,
    },
    {
      type: 'Resuelta',
      nro: 0,
      findings: [],
      color: findingsTypesConventions.resolved.color,
    },
  ])
  const [expanded, setExpanded] = useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    if (findings) {
      const resolvedFindings = []
      const observedFindings = []
      const nonComplaintToFixFindings = []
      const nonComplaintSevereFindings = []

      findings.forEach(finding => {
        if (finding.resolved) {
          resolvedFindings.push(finding)
        } else {
          switch (finding.type) {
            case findingsTypes.Observation:
              observedFindings.push(finding)
              break
            case findingsTypes.NonComplaintToFix:
              nonComplaintToFixFindings.push(finding)
              break
            case findingsTypes.NonComplaintSevere:
              nonComplaintSevereFindings.push(finding)
              break
            default:
              break
          }
        }
      })

      setFindingsClassified([
        {
          type: 'Observación',
          nro: observedFindings.length,
          findings: observedFindings,
          color: findingsTypesConventions.observed.color,
        },
        {
          type: 'No Conformidad a Corregir',
          nro: nonComplaintToFixFindings.length,
          findings: nonComplaintToFixFindings,
          color: findingsTypesConventions.nonComplaintToFix.color,
        },
        {
          type: 'No Conformidad Grave',
          nro: nonComplaintSevereFindings.length,
          findings: nonComplaintSevereFindings,
          color: findingsTypesConventions.nonComplaintSevere.color,
        },
        {
          type: 'Resuelta',
          nro: resolvedFindings.length,
          findings: resolvedFindings,
          color: findingsTypesConventions.resolved.color,
        },
      ])
    }
  }, [findings])

  return findingsClassified.map(findingClassified => {
    return (
      <>
        {findingClassified.nro > 0 ? (
          <Accordion
            key={findingClassified.type}
            expanded={expanded === findingClassified.type}
            sx={{
              width: '100%',
              boxShadow: 'none',
            }}
            onChange={handleChange(findingClassified.type)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                margin: 0,
                minHeight: '40px',
                '& .MuiAccordionSummary-content': {
                  margin: 0,
                },
              }}
            >
              <Typography sx={{ width: '50%', textAlign: 'start', fontSize: 16 }}>
                {findingClassified.type}
                <Badge
                  badgeContent={findingClassified.nro}
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: findingClassified.color,
                      color: 'white',
                      fontSize: '0.8rem',
                      fontWeight: 'bold',
                      minWidth: '20px',
                      minHeight: '20px',
                      padding: '0 6px',
                      right: '-20px',
                    },
                  }}
                />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper
                elevation={0}
                sx={{
                  padding: 1,
                  borderRadius: '5px 5px 5px 5px',
                  border: '1px solid #dcdcdc',
                  backgroundColor: 'inherit',
                }}
              >
                {findingClassified.findings.map(finding => {
                  return (
                    <FindingCard
                      key={finding.id}
                      finding={finding}
                      handleMetricReset={handleMetricReset}
                      readOnly={readOnly}
                    />
                  )
                })}
              </Paper>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </>
    )
  })
}

export default FindingAccordions
