import axios from 'axios'
import { catchHTTPErrors } from './postApiData'

export default async function deleteApiData(endpoint, body, token) {
  try {
    const options = {
      url: process.env.REACT_APP_RUUTS_API_URL + endpoint,
      method: 'DELETE',
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.request(options)
    return response.data
  } catch (error) {
    catchHTTPErrors({ endpoint, request: body, error })
    return null
  }
}
