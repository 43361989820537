import { assignSamplingAreasToMonitoringPoints } from '../../utils/FarmLayers/monitoringSites'
import { saveExclusionAreas } from './saveExclusionAreas'
import { saveFarmSubdivision } from './saveFarmSubdivision'
import { saveMonitoringSites } from './saveMonitoringSites'
import { saveOtherPolygons } from './saveOtherPolygons'
import { saveOtherSites } from './saveOtherSites'
import { updateApiPerimeter } from './updatePerimeter'
import { ruutsApi } from '../ruutsApi'

export const saveFarmMapping = async (
  currentFarm,
  user,
  token,
  selectedYears,
  perimeter,
  paddocks,
  samplingAreas,
  monitoringSites,
  exclusionAreas,
  center,
  otherPolygons,
  otherSites,
) => {
  const parsedYears = selectedYears.map(year => {
    return parseInt(year, 10)
  })

  // Perimeter
  if (perimeter) {
    await updateApiPerimeter(currentFarm, perimeter, token, center)
  }

  // Save paddocks and farm subdivision
  if (parsedYears && paddocks?.length > 0) {
    // Save a new paddock for each year selected
    await Promise.all(
      parsedYears.map(async year => {
        // Save paddocks for the year selected
        const newPaddocksIds = await ruutsApi.paddocks.savePaddocksFromKML({
          farm: currentFarm,
          paddocks,
          token,
        })
        // Save farm subdivision with the new paddocks created
        await saveFarmSubdivision(
          currentFarm,
          {
            year,
            paddockIds: newPaddocksIds,
          },
          token,
          user,
        )
      }),
    )
  }

  // Sampling Areas && Monitoring Sites
  let sitesWithinAreas = []
  if (samplingAreas) {
    // Remove id from properties to create a new sampling area
    const samplingAreasToCreate = samplingAreas.map(
      ({ properties: { id, ...rest }, ...samplingArea }) => ({
        ...samplingArea,
        properties: rest,
      }),
    )
    const savedSamplingAreas = await ruutsApi.samplingAreas.createByFarm({
      farmId: currentFarm.id,
      features: samplingAreasToCreate,
      token,
    })
    const areaFeatures = savedSamplingAreas.map(sa => sa.toGeoJSON)

    // Se asigna el samplingAreaId a los monitoringSites, si hay monitoringSites.
    if (monitoringSites && monitoringSites.length) {
      sitesWithinAreas = assignSamplingAreasToMonitoringPoints(areaFeatures, monitoringSites)
      if (sitesWithinAreas?.length)
        await Promise.all(
          sitesWithinAreas.map(site => saveMonitoringSites(currentFarm, site, token, user)),
        )
    }
  }

  // Save monitoring sites without sampling area associated
  if (monitoringSites && monitoringSites.length) {
    const sitesWithingAreasName = sitesWithinAreas.map(siteWithinArea => {
      return siteWithinArea.properties.name
    })
    const sitesWithoutAreas = monitoringSites.filter(
      monitoringSite => !sitesWithingAreasName.includes(monitoringSite.properties.name),
    )

    if (sitesWithoutAreas?.length)
      await Promise.all(
        sitesWithoutAreas.map(site => saveMonitoringSites(currentFarm, site, token, user)),
      )
  }

  // Other Polygons
  if (otherPolygons?.length)
    await Promise.all(
      otherPolygons.map(otherPolygon => saveOtherPolygons(currentFarm, otherPolygon, token)),
    )

  // Exclusion Areas
  if (exclusionAreas?.length) await saveExclusionAreas(currentFarm, exclusionAreas, token)

  // Other Sites
  if (otherSites?.length)
    await Promise.all(otherSites.map(otherSite => saveOtherSites(currentFarm, otherSite, token)))
}
