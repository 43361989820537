import general from './general'
import lotes from './lotes'
import pastoreo from './pastoreo'
import labranza from './labranza'
import agua from './agua'
import combustible from './combustible'
import cultivos from './cultivos'
import fertilizantesOrganicos from './fertilizantesOrganicos'
import fertilizantesSinteticos from './fertilizantesSinteticos'

const formDefs = {
  'management.general': general,
  'management.paddocks': lotes,
  'management.grazing': pastoreo,
  'management.organicFertilizers': fertilizantesOrganicos,
  'management.syntheticFertilizers': fertilizantesSinteticos,
  'management.tillage': labranza,
  'management.water': agua,
  'management.fuel': combustible,
  'management.crops': cultivos,
}

export default formDefs
