export const parseErrors = (errors, errArray = []) => {
  if (!errors) return errArray

  Object.keys(errors).forEach(key => {
    if (Array.isArray(errors[key])) {
      errors[key].forEach(nestedError => {
        parseErrors(nestedError, errArray)
      })
    } else {
      errArray.push(`${errors[key].message}`)
    }
  })

  return errArray
}

// TODO: Remove index parameter, it is not used
// eslint-disable-next-line default-param-last
export const checkDependencies = (dependencies = [], type, index, watch) => {
  if (dependencies && dependencies.length) {
    const depResults = []
    dependencies.forEach(dependency => {
      const [targetField] = Object.keys(dependency)
      const condition = dependency[targetField]
      const [operator] = Object.keys(condition)
      const conditionValue = condition[operator]
      const dependencyValue = watch(targetField)
      switch (operator) {
        case 'eq':
          depResults.push(dependencyValue === conditionValue)
          break
        case 'neq':
          depResults.push(dependencyValue !== conditionValue)
          break
        case 'gt':
          depResults.push(dependencyValue > conditionValue)
          break
        case 'lt':
          depResults.push(dependencyValue < conditionValue)
          break
        case 'in':
          depResults.push(conditionValue?.includes(dependencyValue))
          break
        case 'notIn':
          depResults.push(!conditionValue?.includes(dependencyValue))
          break
        case 'arrEq':
          depResults.push(conditionValue.join() === dependencyValue?.join())
          break
        case 'arrNotEq':
          depResults.push(conditionValue.join() !== dependencyValue?.join())
          break
        default:
          depResults.push(true)
      }
    })
    const depResult = depResults.reduce((acc, cur) => {
      return acc && cur
    }, true)
    return depResult
  }
  return type === 'display'
}
