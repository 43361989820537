import endpoints from '../endpoints'
import getApiData from '../getApiData'

export async function getByDCSNamespace({ dataCollectionStatementId, namespace, resolved, token }) {
  try {
    const hasFindingToResolve = await getApiData(
      `${endpoints.findings}/dataCollectionStatements/${dataCollectionStatementId}/namespaces/${namespace}?resolved=${resolved}`,
      null,
      token,
    )
    return hasFindingToResolve
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get has fingings.')
  }
}
