// Material UI
import { Checkbox, FormControlLabel } from '@mui/material'

// Hook Form
import { Controller } from 'react-hook-form'

// lfStore
import lfStore from '../../../lfstore/lfStore'

const CheckBoxWrapper = ({ control, formField, rules, groupIndex, readOnly }) => {
  return (
    <FormControlLabel
      control={
        <Controller
          control={control}
          defaultValue={formField.defaultValue}
          name={`${formField.name}${groupIndex ? `-${groupIndex}` : ''}`}
          render={({ field }) => (
            <Checkbox
              checked={field.value}
              disabled={readOnly}
              onChange={(evt, value) => {
                field.onChange(value)
                if (!readOnly) lfStore.setItem('dirtyForm', true)
              }}
            />
          )}
          rules={rules}
        />
      }
      label={formField.label}
    />
  )
}

export default CheckBoxWrapper
