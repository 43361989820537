import { useState, useEffect, useCallback, useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { helpers } from '@turf/turf'
import lfstore from '../../lfstore/lfStore'
import { ruutsApi } from '../../services/ruutsApi/index.js'
import endpoints from '../../services/ruutsApi/endpoints'
import AppContext from '../../context/appContext'
import DynamicForm from '../DynamicForm/DynamicForm'
import DataCollectionMap from '../DataCollectionMap/DataCollectionMap'
import DataTable from '../DataTable/DataTable'
import { ModalTypes } from '../GlobalConfirmationModal/GlobalConfirmationModal'
import { processLoading } from '../../utils/Loading/processLoading'
import { DCNamespaces } from '../../utils/dataCollection/namespaces.js'
import { useDraftFormStorage } from '../DynamicForm/useDraftFormStorage.js'

const FormContainer = ({
  formMode,
  setSelectedMetricEvent,
  dataCollectionStatementId,
  formKey,
  year,
  resetTab,
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const { setConfirmationModalConfig, setLoadingModalConfig, userRoleSelected, currentFarm } =
    useContext(AppContext)

  const [formDefinition, setFormDefinition] = useState()
  const [paddockIds, setPaddockIds] = useState([])
  const [paddocksGeoJson, setPaddocksGeoJson] = useState([])
  const [selectedPaddockIds, setSelectedPaddockIds] = useState([])
  const [metricEvents, setMetricEvents] = useState([])
  const [paddockProductionEvents, setPaddockProductionEvents] = useState()
  const [metricEventRefresher, setMetricEventRefresher] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [resetFormSignal, toggleResetFormSignal] = useState(false)
  const [disableMap, setDisableMap] = useState(false)
  const [showMetrics, setShowMetrics] = useState(false)

  const { getDrafts, removeFromDrafts } = useDraftFormStorage({
    namespace: formDefinition?.namespace,
    farmId: currentFarm.id,
    year,
  })

  const handleMetricReset = () => {
    setMetricEventRefresher(metricEventRefresher + 1)
    resetTab()
  }

  const getMetricEvents = useCallback(async () => {
    try {
      setShowMetrics(false)
      setLoadingModalConfig({
        open: true,
        dialogMessage: 'Cargando métricas',
      })
      const token = await getAccessTokenSilently()
      const events = await ruutsApi.getApiData(
        endpoints.metricEvents,
        { farmId: currentFarm.id, namespace: formDefinition.namespace, year },
        token,
      )

      let newPaddockProductionEvents = []
      if (formDefinition.namespace === DCNamespaces.grazing) {
        newPaddockProductionEvents = await ruutsApi.getApiData(
          endpoints.metricEvents,
          { farmId: currentFarm.id, namespace: DCNamespaces.paddocks, year },
          token,
        )
      }

      setPaddockProductionEvents(newPaddockProductionEvents)
      const eventsAndDrafts = Array.isArray(events) ? [...events, ...getDrafts()] : getDrafts()
      setMetricEvents(eventsAndDrafts)
      setSelectedIndex(null)
      setLoadingModalConfig({
        open: false,
        successfulMessage: 'Métricas cargadas con éxito',
      })
      setShowMetrics(true)
    } catch (e) {
      console.error(e)
    }
  }, [currentFarm, formDefinition, getAccessTokenSilently, year, setLoadingModalConfig])

  async function handleDeleteMetric(metric, deletedMetricReason) {
    await processLoading({
      getToken: getAccessTokenSilently,
      setLoadingModalConfig,
      loadingMessage: 'Borrando métrica...',
      successfulMessage: 'Datos eliminados correctamente',
      errorMessage: 'Error al eliminar la métrica, por favor intente nuevamente',
      doAction: async ({ token }) => {
        await ruutsApi.deleteMetric(
          metric.id,
          dataCollectionStatementId,
          deletedMetricReason,
          userRoleSelected,
          token,
        )
        await getMetricEvents()
        handleMetricReset()
      },
    })
  }

  function handleDeleteConfirmation(row) {
    const newSelectedIndex = row.selectedRows.data[0].index
    const metricSelected = metricEvents[newSelectedIndex]

    if (metricSelected.isDraft) {
      setConfirmationModalConfig({
        open: true,
        title: 'Borrar borrador',
        message: 'Se borrará el borrador seleccionado. ¿Desea continuar?',
        confirmLabel: 'Borrar',
        modalType: ModalTypes.DeleteModalType,
        confirmAction: () => {
          removeFromDrafts(metricSelected.id)
          handleMetricReset()
        },
      })
    } else {
      const hasFindingToResolve = metricSelected.Findings?.some(
        finding => finding.resolved === false,
      )
      let requiredTextField = null
      let confirmationMessage = 'Se borrará la métrica seleccionada. ¿Desea continuar?'
      if (hasFindingToResolve) {
        confirmationMessage =
          'La métrica seleccionada posee una observación sin resolver por el auditor. Para poder eliminarla es necesario que detalle el motivo ¿Desea continuar?'
        requiredTextField = {
          placeHolder: 'Motivo',
        }
      }
      setSelectedIndex(newSelectedIndex)
      setConfirmationModalConfig({
        open: true,
        title: 'Borrar métrica',
        message: confirmationMessage,
        confirmLabel: 'Borrar',
        modalType: ModalTypes.DeleteModalType,
        confirmAction: input => handleDeleteMetric(metricSelected, input),
        requiredTextField,
      })
    }
  }

  /* Handle reset form when RESET(Limpiar) button clicked inside form component */
  function handleResetForm() {
    // limpiar los selectedPaddocks en el mapa
    toggleResetFormSignal(!resetFormSignal)
    // limpiar el selectedIndex
    setSelectedIndex(null)
  }

  /* Handle select metric event from the AnnualDataTable */
  async function handleSelectedMetric(row) {
    setSelectedIndex(row.selectedRows.data[0].index)
  }

  /* Gets form definition and sets state */
  useEffect(() => {
    async function getFormDefinition(formDefinitionKey) {
      try {
        const form = await lfstore.getItem(formDefinitionKey)
        if (form) setFormDefinition(form)
      } catch (e) {
        console.error(e)
      }
    }
    if (formKey) getFormDefinition(formKey)
  }, [formKey, getAccessTokenSilently])

  /* Get paddocks when receiving year prop */
  useEffect(() => {
    async function getPaddocks() {
      try {
        const token = await getAccessTokenSilently()
        const [farmSubdivision] = await ruutsApi.getApiData(
          endpoints.farmSubdivisions,
          { farmId: currentFarm.id, year },
          token,
        )
        const paddockPromises = farmSubdivision.paddockIds.map(id =>
          ruutsApi.paddocks.getPaddockById({ id, token }).then(([paddock]) => paddock),
        )

        const paddocksList = await Promise.all(paddockPromises)

        const features = helpers.featureCollection(paddocksList.map(paddock => paddock.toGeoJSON))

        const ids = paddocksList.map(paddock => paddock.id)

        setPaddocksGeoJson(features)
        setPaddockIds(ids)
      } catch (e) {
        console.error(e)
      }
    }

    if (year) getPaddocks()
  }, [getAccessTokenSilently, year, currentFarm])

  /* Get metricEvents from DB and update state */
  useEffect(() => {
    if (formDefinition && year) {
      getMetricEvents()
    }
  }, [formDefinition, year, getAccessTokenSilently, metricEventRefresher, getMetricEvents])

  return (
    formMode &&
    formDefinition &&
    paddocksGeoJson?.features?.length > 0 &&
    metricEvents && (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Stack flex direction="row" flexDirection="row" flexGrow={0} height="100%">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: formDefinition?.layout.showMap && !disableMap ? '50vw' : '100vw',
              backgroundColor: '#eceff1',
            }}
          >
            <DynamicForm
              allowMoreRecords={formDefinition.allowMultipleRecords || !metricEvents?.length}
              dataCollectionStatementId={dataCollectionStatementId}
              formDefinition={formDefinition}
              formMode={formMode}
              handleDisableMap={setDisableMap}
              handleMetricReset={handleMetricReset}
              handleResetForm={() => handleResetForm()}
              selectedMetricEvent={selectedIndex !== null ? metricEvents[selectedIndex] : null}
              selectedMetricEventIndex={selectedIndex}
              selectedPaddockIds={selectedPaddockIds}
              year={year}
            />
          </Box>
          {formDefinition.layout.showMap && !disableMap && (
            <Box sx={{ width: '50vw', height: '100%', backgroundColor: '#eceff1' }}>
              <DataCollectionMap
                allPaddockIds={paddockIds}
                center={[-34.5, -58.4]}
                formMode={formMode}
                metricEvents={metricEvents}
                namespace={formDefinition.namespace}
                paddockProductionEvents={paddockProductionEvents}
                paddocksGeoJson={paddocksGeoJson}
                resetFormSignal={resetFormSignal}
                selectedMetricEventIndex={selectedIndex}
                updateSelectedPaddockIds={setSelectedPaddockIds}
              />
            </Box>
          )}
        </Stack>

        {showMetrics && (
          <DataTable
            data={metricEvents}
            formDefinition={formDefinition}
            formMode={formMode}
            handleDelete={row => handleDeleteConfirmation(row)}
            handleMetricReset={handleMetricReset}
            handleSelect={row => handleSelectedMetric(row)}
            paddocks={paddocksGeoJson}
            setSelectedMetricEvent={setSelectedMetricEvent}
          />
        )}
      </Box>
    )
  )
}

export default FormContainer
