import lfStore from '../../lfstore/lfStore'

export default function setRefData(key, payload) {
  try {
    lfStore.setItem(key, payload)
    return 'Success'
  } catch (error) {
    console.error(error)
    throw new Error(`Unable to retrieve reference data for ${key}`)
  }
}
