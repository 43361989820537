import { temporaryDisabled } from './temporaryDisabled.js'
import { unusedRequiredPaddocks, unusedWarningPaddocks } from './unusedPaddocks.js'
import { unresolvedFindings } from './unresolvedFindings.js'

export const namespacesValidations = {
  temporaryDisabled,
  unusedRequiredPaddocks,
  unusedWarningPaddocks,
  unresolvedFindings,
}
