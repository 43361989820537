import endpoints from './endpoints'
import updateApiData from './updateApiData'
import postApiData from './postApiData'

export default async function saveMetrics(payload, token) {
  return postApiData(endpoints.metricEvents, payload, token)
}

export async function saveVerificationMetric(
  { metricEventId, isVerified, dataCollectionStatementId, userRole },
  { token },
) {
  try {
    const response = await updateApiData(
      `${endpoints.metricEvents}/verification/${metricEventId}`,
      {
        userRole,
        dataCollectionStatementId,
        isVerified,
      },
      token,
    )
    return response
  } catch (error) {
    console.error(error)
    throw new Error(`Unable to verified metric: ${error.message}`)
  }
}

export async function saveMetricEventStatus({ metricEventId, metricEventStatusId, token }) {
  return updateApiData(
    `${endpoints.metricEvents}/${metricEventId}/status`,
    {
      metricEventStatusId,
    },
    token,
  )
}
