import endpoints from '../ruutsApi/endpoints'
import postApiData from '../ruutsApi/postApiData'

export const saveOtherSites = async (currentFarm, data, token) => {
  try {
    const otherSiteBody = {
      name: data.properties.name,
      farmId: currentFarm.id,
      plannedLocation: [data.geometry.coordinates[1], data.geometry.coordinates[0]],
      geometry: data.geometry,
      color: 'hsl(300, 76%, 72%)',
    }
    const otherSiteStatus = await postApiData(endpoints.otherSites, otherSiteBody, token)
    return otherSiteStatus
  } catch (error) {
    console.error({ error })
    return null
  }
}
