import endpoints from '../ruutsApi/endpoints'
import getApiData from '../ruutsApi/getApiData'

export const getFrontPaddocks = paddocks =>
  paddocks.map(paddock => ({
    ...paddock,
    geometry: paddock.uncroppedGeometry,
    toGeoJSON: paddock.toUncroppedGeoJSON,
  }))

export const getPaddocks = async ({ farmId, token }) => {
  const originalPaddocks = await getApiData(endpoints.paddocks, { farmId }, token)
  return getFrontPaddocks(originalPaddocks)
}
