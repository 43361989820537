import * as turf from '@turf/turf'
import { getGeom } from '@turf/invariant'
import { isPolyInPoly } from '@turf/boolean-contains'

function isMultiPolyInPoly(polygon, multiPolygon) {
  return multiPolygon.coordinates.every(coords =>
    isPolyInPoly(polygon, { type: 'Polygon', coordinates: coords }),
  )
}

function isMultiPolyInMultiPoly(multiPolygon1, multiPolygon2) {
  return multiPolygon2.coordinates.every(coords2 =>
    multiPolygon1.coordinates.every(coords1 =>
      isPolyInPoly(
        { type: 'Polygon', coordinates: coords1 },
        { type: 'Polygon', coordinates: coords2 },
      ),
    ),
  )
}

export function booleanContains(feature1, feature2) {
  const geom1 = getGeom(feature1)
  const geom2 = getGeom(feature2)
  const type1 = geom1.type
  const type2 = geom2.type

  switch (type1) {
    case 'Polygon': {
      switch (type2) {
        case 'MultiPolygon':
          return isMultiPolyInPoly(geom1, geom2)
        default:
          return turf.booleanContains(feature1, feature2)
      }
    }
    case 'MultiPolygon': {
      switch (type2) {
        case 'MultiPolygon':
          return isMultiPolyInMultiPoly(geom1, geom2)
        default:
          return turf.booleanContains(feature1, feature2)
      }
    }
    default:
      return turf.booleanContains(feature1, feature2)
  }
}
