// React
import { useState, useContext } from 'react'

// Material UI
import { Button, Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

// Custom
import AppContext from '../../context/appContext'
import { getRandomColor } from '../../utils/mapDrawHandle'
import { useProcessLoading } from '../../utils/Loading/useProcessLoading'

// Services
import { GeoJSON } from '../../utils/GeoJSON'
import { stratas } from '../../services/ruutsServices/stratas/index'

export function generateSamplingAreasFromGeoJSON(geojson) {
  return geojson.features.map((feature, index) => {
    const samplingAreaFeature = GeoJSON.geoJSONFromGeometry({ geometry: feature.geometry })
    return {
      ...samplingAreaFeature,
      properties: {
        color: getRandomColor(),
        name: `Estrato ${index + 1}`,
        area: GeoJSON.hectareArea(samplingAreaFeature),
      },
    }
  })
}

const AutomaticClasification = ({
  perimeter,
  previewSampling,
  setPreviewSampling,
  setSamplingAreasFeatures,
  setDrawActivated,
  setVectorized,
}) => {
  const [isValid, setIsValid] = useState(true)
  const { programConfig } = useContext(AppContext)
  const { processLoading } = useProcessLoading()

  const handleGenerateStratification = async () => {
    await processLoading({
      loadingMessage: 'Generando estratificación automática...',
      errorMessage:
        'Error al generar la estratificación automática. Por favor, intenta nuevamente.',
      doAction: async ({ token }) => {
        try {
          const exclusionAreas = []
          const stratasConfig = programConfig.stratasConfigEndpoint

          const result = await stratas.automaticStratificationFarm(
            stratasConfig,
            perimeter,
            exclusionAreas,
            token,
          )
          const stratasFeatures = generateSamplingAreasFromGeoJSON(result.vectorized_stratas)

          setPreviewSampling(stratasFeatures)
          setDrawActivated(false)
          setVectorized(true)
          setIsValid(true)
        } catch (error) {
          setIsValid(false)
          throw error
        }
      },
    })
  }

  const handleConfirmStratification = () => {
    setPreviewSampling(null)
    setDrawActivated(true)
    setVectorized(true)
    setSamplingAreasFeatures(previewSampling)
  }

  const handleClearStratification = () => {
    setPreviewSampling(null)
    setVectorized(false)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '230px',
        border: '1px dashed',
        borderColor: 'primary.main',
        borderRadius: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        {!previewSampling && (
          <Button
            color="primary"
            endIcon={<CheckCircleIcon />}
            variant="contained"
            onClick={handleGenerateStratification}
          >
            Creación automática
          </Button>
        )}

        {!isValid && (
          <>
            <br />
            <br />
            <Typography color="error" variant="p">
              Error al generar la estratificación, por favor vuelva a intentar...
            </Typography>
          </>
        )}

        {previewSampling && isValid && (
          <>
            <Typography variant="p">
              Se cargaron los estratos de forma automática. Desea confirmarlos?
            </Typography>
            <br />
            <br />
            <Button
              color="primary"
              endIcon={<CheckCircleIcon />}
              variant="contained"
              onClick={handleConfirmStratification}
            >
              Confirmar carga
            </Button>

            <br />
            <br />

            <Typography variant="p">¿Desea eliminar la generación automática?</Typography>
            <br />
            <br />
            <Button
              color="primary"
              endIcon={<DeleteIcon />}
              variant="contained"
              onClick={handleClearStratification}
            >
              Eliminar la carga automática
            </Button>
          </>
        )}
      </Box>
    </Box>
  )
}

export default AutomaticClasification
