/* eslint-disable import/no-cycle */
import { Grid, Paper } from '@mui/material'
import DynamicFieldSwitcher from '../DynamicFieldSwitcher'

const BoxWrapper = ({ fieldArray, control, checkDependencies, readOnly }) => {
  return (
    <Grid item {...fieldArray.gridSizing}>
      <Paper
        elevation={0}
        sx={{
          padding: 2,
          marginTop: 2,
          borderRadius: '5px 5px 5px 5px',
          border: '1px solid #dcdcdc',
          backgroundColor: 'inherit',
        }}
      >
        <Grid container spacing={1}>
          {fieldArray.fields.map(formField => (
            <DynamicFieldSwitcher
              key={`${fieldArray.name}.${formField.name}`}
              checkDependencies={checkDependencies}
              control={control}
              formField={{
                ...formField,
                name: `${fieldArray.name}.${formField.name}`,
                dependencies: {
                  display: formField.dependencies.display,
                  disabled: formField.dependencies.disabled,
                },
              }}
              readOnly={readOnly}
            />
          ))}
        </Grid>
      </Paper>
    </Grid>
  )
}

export default BoxWrapper
