const combustible = {
  namespace: 'farm_management_fuels',
  label: 'Combustibles',
  required: true,
  layout: {
    showMap: false,
    showTable: true,
  },
  fields: [
    {
      component_type: 'Title',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Uso de combustibles',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'InfoText',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label:
        'Especifique el uso de combustible empleado al manejo ganadero (labranza, aplicación de fertilizantes, uso de un tractor, etc)',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'dieselConsumptionLts',
      unit: 'L',
      placeholder: 'Diesel',
      label: 'Diesel',
      defaultValue: 0,
      min: 0,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'petrolConsumptionLts',
      unit: 'L',
      placeholder: 'Nafta',
      label: 'Nafta',
      defaultValue: 0,
      min: 0,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'gasConsumptionLts',
      unit: 'm³', // Metros cubicos
      placeholder: 'Gas',
      label: 'Gas',
      defaultValue: 0,
      min: 0,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
  ],
}

export default combustible
