import { perimetersToMultiPolygon } from '../../utils/FarmLayers/perimeter'
import { updateApiPerimeter } from './updatePerimeter'

export const saveManualFarmMapping = async (currentFarm, token, perimeter, center) => {
  // Perimeter
  if (perimeter) {
    const multipolygonPerimeter = perimetersToMultiPolygon(perimeter)
    await updateApiPerimeter(currentFarm, multipolygonPerimeter, token, center)
  }
}
