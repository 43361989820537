import {
  contactSupportReasons,
  displayErrorMessageModes,
} from '../../components/GlobalLoadingModal/GlobalLoadingModal'

export async function processLoading({
  getToken,
  setLoadingModalConfig,
  doAction,
  loadingTitle,
  loadingMessage = 'Guardando información...',
  successfulMessage = 'Se modificó la entidad correctamente',
  contactReasonSupportType = contactSupportReasons.Generic,
  errorMessage = 'Error al guardar la entidad, por favor intente nuevamente',
  displayErrorMessageMode = displayErrorMessageModes.snackbar,
  dryRun = false,
  displayErrorCustomMessage = true, // There are some cases where we want to
  // display contact message error given and others where we want to display a title message error.
  // For example when we have a validation error we want to display the error message given by the errorMessage parameter
  // but when we have an unexpected error we want to display the title error to avoid to show internal errors to the user.
}) {
  try {
    const token = await getToken()

    setLoadingModalConfig({
      open: true,
      title: loadingTitle,
      dialogMessage: loadingMessage,
    })

    await doAction({ token, dryRun })

    setLoadingModalConfig({
      open: false,
      successfulMessage,
      dryRun,
    })
  } catch (error) {
    const newErrorMessage = displayErrorCustomMessage
      ? errorMessage
      : contactReasonSupportType.title

    setLoadingModalConfig({
      open: false,
      title: contactReasonSupportType.title,
      dialogMessage: contactReasonSupportType.detail,
      error,
      errorMessage: newErrorMessage,
      displayErrorMessageMode,
    })
  }
}

export async function processLoadingWithConfirmation({
  getToken,
  setPartialChanges,
  loadingModalConfig,
  doAction,
  confirmationModalConfig,
  displayErrorCustomMessage,
}) {
  await processLoading({
    setLoadingModalConfig: loadingModalConfig.setLoadingModalConfig,
    loadingTitle: loadingModalConfig.loadingTitle,
    loadingMessage: loadingModalConfig.loadingMessage,
    successfulMessage: loadingModalConfig.successfulMessage,
    contactReasonSupportType: loadingModalConfig.contactReasonSupportType,
    errorMessage: loadingModalConfig.errorMessage,
    displayErrorMessageMode: loadingModalConfig.displayErrorMessageMode,
    getToken,
    dryRun: true,
    displayErrorCustomMessage,
    doAction: async ({ token, dryRun }) => {
      await doAction({ token, dryRun })

      confirmationModalConfig.setConfirmationModalConfig({
        title: confirmationModalConfig.title,
        message: confirmationModalConfig.message,
        open: true,
        confirmAction: async () => {
          confirmationModalConfig.setConfirmationModalConfig({ open: false })

          await processLoading({
            setLoadingModalConfig: loadingModalConfig.setLoadingModalConfig,
            loadingTitle: loadingModalConfig.loadingTitle,
            loadingMessage: loadingModalConfig.loadingMessage,
            successfulMessage: loadingModalConfig.successfulMessage,
            contactReasonSupportType: loadingModalConfig.contactReasonSupportType,
            errorMessage: loadingModalConfig.errorMessage,
            displayErrorMessageMode: loadingModalConfig.displayErrorMessageMode,
            getToken,
            doAction,
            dryRun: false,
          })
          setPartialChanges(false)
        },
      })
    },
  })
}
