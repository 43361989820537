// React
import { useState, useEffect, useContext, useCallback } from 'react'

// Material UI
import { Grid } from '@mui/material'

import { useAuth0 } from '@auth0/auth0-react'

// Custom
import StratificationPanel from './StratificationPanel'
import ClasificationMap from '../ClasificationMap'
import ClasificationPreview from '../ClasificationPreview'
import { ruutsApi } from '../../../services/ruutsApi/index'
import { GeoJSON } from '../../../utils/GeoJSON'
import AppContext from '../../../context/appContext'

const StratificationHome = ({
  canEditClasification,
  setTabValue,
  samplingAreas,
  setSamplingAreas,
  setSamplingAreasSaved,
  mapWidth,
  mapHeight,
}) => {
  const { user, getAccessTokenSilently } = useAuth0()

  const [samplingAreasFeatures, setSamplingAreasFeatures] = useState([])
  const [mapAction, setMapAction] = useState(null)
  const [selectedArea, setSelectedArea] = useState()
  const [selectedAreaType, setSelectedAreaType] = useState()
  const [wasSaved, setWasSaved] = useState(false)
  const [georaster, setGeoraster] = useState()
  const [previewSampling, setPreviewSampling] = useState(null)
  const [vectorized, setVectorized] = useState(false)
  const [drawActivated, setDrawActivated] = useState(false)
  const [restratification, setRestratification] = useState(false)
  const [soils, setSoils] = useState()
  const [supportedCreationModes, setSupportedCreationModes] = useState(null)
  const [stratasConfig, setStratasConfig] = useState({})
  const [perimeter, setPerimeter] = useState()
  const { currentFarm, programConfig } = useContext(AppContext)

  useEffect(() => {
    async function getFarm() {
      const token = await getAccessTokenSilently()

      const farm = await ruutsApi.getFarm({ id: currentFarm.id, user, token })
      const newPerimeter = farm.toGeoJSON

      if (!samplingAreasFeatures.length) {
        if (samplingAreas?.length) {
          setSamplingAreasFeatures(() => samplingAreas.map(sA => sA.toGeoJSON))
          setWasSaved(true)
        }
      }

      setStratasConfig(programConfig?.stratasConfigEndpoint || {})
      setSupportedCreationModes(programConfig?.stratificationModesAllowed, [])
      setPerimeter(newPerimeter)
    }

    getFarm()
  }, [
    currentFarm,
    getAccessTokenSilently,
    programConfig,
    samplingAreas,
    samplingAreasFeatures.length,
    user,
  ])

  useEffect(() => {
    // ClasificationPreview only support one input,
    // when one is loaded the rest is cleared
    if (previewSampling) {
      setGeoraster(null)
    }
  }, [previewSampling, setGeoraster])

  useEffect(() => {
    // ClasificationPreview only support one input,
    // when one is loaded the rest is cleared
    if (georaster) {
      setPreviewSampling(null)
    }
  }, [georaster, setPreviewSampling])

  const handleMapBoundsChanged = useCallback(
    mapBounds => {
      async function loadSoils(bounds) {
        const token = await getAccessTokenSilently()
        setSoils(await ruutsApi.getSoilsIntersections(GeoJSON.getGeoJSONFromBounds(bounds), token))
      }
      loadSoils(mapBounds)
    },
    [getAccessTokenSilently],
  )

  const layers = [
    {
      name: 'Suelos',
      features: soils,
    },
  ]

  return (
    <>
      {perimeter && supportedCreationModes && (
        <Grid container>
          <Grid item xs={4}>
            <StratificationPanel
              canEditClasification={canEditClasification}
              drawActivated={drawActivated}
              farm={currentFarm}
              georaster={georaster}
              mapAction={mapAction}
              perimeter={perimeter}
              previewSampling={previewSampling}
              restratification={restratification}
              samplingAreas={samplingAreas}
              samplingAreasFeatures={samplingAreasFeatures}
              selectedArea={selectedArea}
              setDrawActivated={setDrawActivated}
              setGeoraster={setGeoraster}
              setMapAction={setMapAction}
              setPreviewSampling={setPreviewSampling}
              setRestratification={setRestratification}
              setSamplingAreas={setSamplingAreas}
              setSamplingAreasFeatures={setSamplingAreasFeatures}
              setSamplingAreasSaved={setSamplingAreasSaved}
              setSelectedArea={setSelectedArea}
              setSelectedAreaType={setSelectedAreaType}
              setTabValue={setTabValue}
              setVectorized={setVectorized}
              setWasSaved={setWasSaved}
              stratasConfig={stratasConfig}
              supportedCreationModes={supportedCreationModes}
              wasSaved={wasSaved}
            />
          </Grid>
          <Grid item xs={8}>
            {(drawActivated || (!georaster && !vectorized)) && (
              <ClasificationMap
                allowSelfIntersection={drawActivated && vectorized}
                drawActivated={drawActivated}
                handleMapBoundsChanged={handleMapBoundsChanged}
                layers={layers}
                mapAction={mapAction}
                mapHeight={mapHeight}
                mapWidth={mapWidth}
                perimeter={perimeter}
                samplingAreas={samplingAreas}
                samplingAreasFeatures={samplingAreasFeatures}
                selectedArea={selectedArea}
                selectedAreaType={selectedAreaType}
                setDrawActivated={setDrawActivated}
                setMapAction={setMapAction}
                setSamplingAreasFeatures={setSamplingAreasFeatures}
                setSelectedArea={setSelectedArea}
                setSelectedAreaType={setSelectedAreaType}
                vectorized={vectorized}
                wasSaved={wasSaved}
              />
            )}
            {(georaster || previewSampling) && !drawActivated && (
              <ClasificationPreview
                georaster={georaster}
                mapHeight={mapHeight}
                mapWidth={mapWidth}
                perimeter={perimeter}
                samplingAreas={previewSampling}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default StratificationHome
