import { GeomanControls } from 'react-leaflet-geoman-v2'

export const GeomanControl = () => {
  return (
    <GeomanControls
      globalOptions={{
        continueDrawing: false,
        snapDistance: 5,
        allowSelfIntersection: false,
        templineStyle: {},
        hintlineStyle: {
          dashArray: [5, 5],
          weight: 2,
        },
      }}
      lang="es"
      options={{
        position: 'topleft',
        drawCircle: false,
        drawMarker: false,
        drawRectangle: false,
        drawPolyline: false,
        drawCircleMarker: false,
        drawText: false,
        rotateMode: false,
        dragMode: false,
        editMode: false,
        cutPolygon: false,
        drawPolygon: false,
        removalMode: false,
      }}
    />
  )
}
