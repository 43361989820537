import endpoints from './endpoints'
import getApiData from './getApiData'

export default async function getProgram(id, token) {
  try {
    const program = await getApiData(endpoints.programs, { id }, token)
    return program[0]
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get program config.')
  }
}
