/* eslint-disable no-shadow */
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ErrorIcon from '@mui/icons-material/Error'
import { Badge, Box, Tooltip, Typography } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import FindingModalVisualization from '../Finding/FindingModalVisualization'
import { findingsTypes } from '../../utils/constants'

const indicatorTheme = createTheme({
  palette: {
    success: {
      main: '#008000',
    },
    empty: {
      main: '#DDDD00',
    },
    warning: {
      main: '#DDDD00',
    },
    error: {
      main: '#FF8C00',
    },
    severe: {
      main: '#FF0000',
    },
  },
})

export const FindingsRowData = ({ findings, readOnly, handleMetricReset }) => {
  const [totalFindings, setTotalFindings] = useState(0)
  const [totalFindingsResolved, setTotalFindingResolved] = useState(0)
  const [totalFindingsByType, setTotalFindingsByType] = useState({
    observations: 0,
    nonComplaintsToFix: 0,
    nonComplaintsSevere: 0,
  })

  const [openModal, setOpenModal] = useState(false)

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    if (findings) {
      const totalNonResolvedFindingsByType = ({ type, findings }) => {
        return findings.filter(finding => !finding.resolved && finding.type === type).length
      }

      const observations = totalNonResolvedFindingsByType({
        type: findingsTypes.Observation,
        findings,
      })
      const nonComplaintsToFix = totalNonResolvedFindingsByType({
        type: findingsTypes.NonComplaintToFix,
        findings,
      })
      const nonComplaintsSevere = totalNonResolvedFindingsByType({
        type: findingsTypes.NonComplaintSevere,
        findings,
      })
      const totalFindings = findings.length
      const totalFindingsResolved = findings.filter(finding => finding.resolved).length

      setTotalFindings(totalFindings)
      setTotalFindingResolved(totalFindingsResolved)
      setTotalFindingsByType({ observations, nonComplaintsToFix, nonComplaintsSevere })
    }
  }, [findings])

  let content
  if (totalFindings === 0) {
    content = <NonFindingsIndicator />
  } else if (totalFindingsResolved === totalFindings) {
    content = <AllFindingResolvedIndicator totalFindingsResolved={totalFindingsResolved} />
  } else {
    content = <FindingsIndicators totalFindingsByType={totalFindingsByType} />
  }

  return (
    <>
      <Box
        sx={{
          minWidth: '160px',
          display: 'flex',
          justifyContent: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={() => {
          setOpenModal(true)
        }}
      >
        {content}
      </Box>
      <FindingModalVisualization
        closeModal={handleCloseModal}
        findings={findings}
        handleMetricReset={handleMetricReset}
        openModal={openModal}
        readOnly={readOnly}
      />
    </>
  )
}

const NonFindingsIndicator = () => {
  return (
    <FindingIndicator key="non-observations" caption="No hay observaciones">
      <Typography sx={{ color: '#6e6e6e' }}>No hay observaciones</Typography>
    </FindingIndicator>
  )
}

const AllFindingResolvedIndicator = ({ totalFindingsResolved }) => {
  return (
    <FindingIndicator
      key="non-observations"
      caption="Todas las observaciones resueltas"
      total={totalFindingsResolved}
    >
      <DoneAllIcon style={{ color: indicatorTheme.palette.success.main }} />
    </FindingIndicator>
  )
}

const FindingsIndicators = ({ totalFindingsByType }) => {
  return (
    <>
      {totalFindingsByType.observations > 0 && (
        <FindingIndicator
          key={findingsTypes.Observation}
          caption="Observaciones"
          total={totalFindingsByType.observations}
        >
          <ErrorIcon style={{ color: indicatorTheme.palette.warning.main }} sx={{ fontSize: 32 }} />
        </FindingIndicator>
      )}
      {totalFindingsByType.nonComplaintsToFix > 0 && (
        <FindingIndicator
          key={findingsTypes.NonComplaintToFix}
          caption="No conformes a corregir"
          total={totalFindingsByType.nonComplaintsToFix}
        >
          <ErrorIcon style={{ color: indicatorTheme.palette.error.main }} sx={{ fontSize: 32 }} />
        </FindingIndicator>
      )}
      {totalFindingsByType.nonComplaintsSevere > 0 && (
        <FindingIndicator
          key={findingsTypes.NonComplaintSevere}
          caption="No conformes graves"
          total={totalFindingsByType.nonComplaintsSevere}
        >
          <ErrorIcon style={{ color: indicatorTheme.palette.severe.main }} sx={{ fontSize: 32 }} />
        </FindingIndicator>
      )}
    </>
  )
}

const FindingIndicator = ({ total, caption, children }) => {
  return (
    <Box sx={{ display: 'inline-flex', mr: 1 }}>
      <Tooltip title={total === undefined ? `${caption}` : `${caption} (${total})`}>
        <Badge
          badgeContent={total}
          color="primary"
          sx={{
            '& .MuiBadge-badge': {
              right: 2,
              top: 5,
              border: '2px solid white',
              padding: '0 4px',
            },
          }}
        >
          {children}
        </Badge>
      </Tooltip>
    </Box>
  )
}
