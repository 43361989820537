import { grey } from '@mui/material/colors'
import devNavbarTheme from './devNavbarTheme'
import localNavbarTheme from './localNavbarTheme'
import prodNavbarTheme from './prodNavbarTheme'
import stgNavbarTheme from './stgNavbarTheme'
import trainingNavbarTheme from './trainingNavbarTheme'

const getNavbarTheme = () => {
  let navbarTheme = {
    typography: {
      fontFamily: 'Roboto',
      bodyBold: {
        fontFamily: 'Roboto',
        fontWeight: 600,
      },
      captionLight: {
        fontSize: '10px',
        fontFamily: 'Roboto',
        color: grey[600],
      },
      h1: {
        fontFamily: 'Nunito Sans',
      },
      h2: {
        fontFamily: 'Nunito Sans',
      },
      h3: {
        fontFamily: 'Nunito Sans',
      },
      h4: {
        fontFamily: 'Nunito Sans',
      },
      h5: {
        fontFamily: 'Nunito Sans',
      },
      h6: {
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
      },
      label: {
        fontSize: '0.8rem',
        fontWeight: 600,
      },
      body1: {
        fontSize: '0.8rem',
      },
    },
  }

  switch (process.env.REACT_APP_ENV) {
    case 'local':
      navbarTheme = {
        ...navbarTheme,
        ...localNavbarTheme,
      }
      break
    case 'development':
      navbarTheme = {
        ...navbarTheme,
        ...devNavbarTheme,
      }
      break
    case 'staging':
      navbarTheme = {
        ...navbarTheme,
        ...stgNavbarTheme,
      }
      break
    case 'production':
      navbarTheme = {
        ...navbarTheme,
        ...prodNavbarTheme,
      }
      break
    case 'training':
      navbarTheme = {
        ...navbarTheme,
        ...trainingNavbarTheme,
      }
      break
    default:
      navbarTheme = devNavbarTheme
  }

  return navbarTheme
}

export default getNavbarTheme
