/* eslint-disable no-shadow */
import * as turf from '@turf/turf'
import { GeoJSON } from '../../utils/GeoJSON/index'
import { errors } from '../ruutsApi/errors.js'
import { messages } from '../../utils/messages/index.js'

// Check if the overlap between 2 polygons is greater than a minimum percentage
function checkMinimumOverlap({ polygon1, polygon2 }) {
  const intersection = GeoJSON.intersect(polygon1, polygon2)
  if (!intersection) {
    return
  }

  if (GeoJSON.booleanContains(polygon1, polygon2)) {
    console.warn({
      reason: 'polygon_is_contained',
      features: turf.featureCollection([polygon1, polygon2]),
    })
    throw new errors.BusinessEntityError({
      message: messages.getMessage(messages.keys.ERROR_TOTAL_OVERLAP_LAYER_AREA_MESSAGE, [
        polygon1?.properties?.name,
        polygon2?.properties?.name,
      ]),
    })
  }

  const intersectionArea = GeoJSON.area(intersection)
  const averageArea = (GeoJSON.area(polygon1) + GeoJSON.area(polygon2)) / 2
  const intersectionAreaOverAverageAreaPercentage = (intersectionArea / averageArea) * 100
  const minimumOverlapAreaPercentageAllowed = 1
  const isOverlap = intersectionAreaOverAverageAreaPercentage >= minimumOverlapAreaPercentageAllowed
  if (isOverlap) {
    console.warn({
      reason: 'exceded_polygon_overlap',
      minimumOverlapAreaPercentageAllowed,
      intersectionAreaOverAverageAreaPercentage,
      features: turf.featureCollection([intersection, polygon1, polygon2]),
    })

    throw new errors.BusinessEntityError({
      message: messages.getMessage(messages.keys.ERROR_OVERLAP_LAYER_AREA_MESSAGE, [
        polygon1?.properties?.name,
        polygon2?.properties?.name,
      ]),
    })
  }
}

export const checkFeaturesOverlap = features => {
  // Casos a contemplar:
  // 1. No hay features areas -> no hay superposición
  // 2. Hay una features area con multi polígono con polígonos internos que se superponen -> hay superposición
  // 3. Hay varias features areas con varios polígonos/multi polígonos que no se superponen -> no hay superposición
  // 4. Hay varias features areas con varios polígonos/multi polígonos que se superponen -> hay superposición
  // NOTA: Turf booleanOverlap() no funciona con parámetros combinados de polígonos y multi polígonos, deben ser del mismo tipo los 2

  // Si no hay features, no hay superposición
  if (!features || !features.length) {
    // No hay features
    return false
  }

  const featuresLength = features.length
  let featuresActualIndex = 0

  while (featuresActualIndex < featuresLength) {
    const featureActual = features[featuresActualIndex]
    const featureActualIsMultiPolygon = featureActual.geometry.type === 'MultiPolygon'

    // Si es un multi polígono, puede haber superposición interna
    if (featureActualIsMultiPolygon) {
      const featureActualPolygons = turf.getCoords(featureActual)
      const featureActualPolygonsLength = featureActualPolygons.length
      for (let i = 0; i < featureActualPolygonsLength; i++) {
        for (let j = i + 1; j < featureActualPolygonsLength; j++) {
          const polygon1 = turf.polygon(featureActualPolygons[i], {
            name: featureActual?.properties?.name,
          })
          const polygon2 = turf.polygon(featureActualPolygons[j], {
            name: featureActual?.properties?.name,
          })
          checkMinimumOverlap({ polygon1, polygon2 })
        }
      }
    }

    // Se debe evaluar el actual indice con todos los indices mayores a el para no repetir comparaciones
    for (let i = featuresActualIndex + 1; i < featuresLength; i++) {
      const featureToCompare = features[i]
      const featureToCompareIsMultiPolygon = featureToCompare.geometry.type === 'MultiPolygon'

      // Turf booleanOverlap() no funciona con parámetros combinados de polígonos y multi polígonos, deben ser del mismo tipo los 2
      if (featureActualIsMultiPolygon) {
        if (featureToCompareIsMultiPolygon) {
          // Check por MultiPolygon con MultiPolygon
          checkMinimumOverlap({ polygon1: featureActual, polygon2: featureToCompare })
        } else {
          // Check por MultiPolygon con Polygon
          const featureActualPolygons = turf.getCoords(featureActual)
          const featureActualPolygonsLength = featureActualPolygons.length
          for (let i = 0; i < featureActualPolygonsLength; i++) {
            const polygon1 = turf.polygon(featureActualPolygons[i], {
              name: featureActual?.properties?.name,
            })
            checkMinimumOverlap({ polygon1, polygon2: featureToCompare })
          }
        }
      } else if (featureToCompareIsMultiPolygon) {
        // Check por Polygon con MultiPolygon
        const featureToComparePolygons = turf.getCoords(featureToCompare)
        const featureToComparePolygonsLength = featureToComparePolygons.length
        for (let i = 0; i < featureToComparePolygonsLength; i++) {
          const polygon1 = turf.polygon(featureToComparePolygons[i], {
            name: featureToCompare.properties?.name,
          })
          checkMinimumOverlap({ polygon1, polygon2: featureActual })
        }
      } else {
        // Check por Polygon con Polygon
        checkMinimumOverlap({ polygon1: featureActual, polygon2: featureToCompare })
      }
    }

    featuresActualIndex++
  }

  return false
}
