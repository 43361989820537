import endpoints from './endpoints.js'
import postApiData from '../../ruutsApi/postApiData.js'

export default async function automaticStratificationFarm(
  stratasConfig,
  perimeter,
  exclusionAreas,
  token,
) {
  const body = { ...stratasConfig }
  body.perimeter = {
    type: 'FeatureCollection',
    features: [perimeter],
  }
  if (exclusionAreas?.length) {
    body.exclusion = {
      type: 'FeatureCollection',
      features: exclusionAreas,
    }
  }

  return postApiData(endpoints.stratify, body, token)
}
