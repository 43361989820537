import axios from 'axios'
import { catchHTTPErrors } from './postApiData'

export default async function updateApiData(endpoint, payload, token) {
  try {
    const options = {
      url: process.env.REACT_APP_RUUTS_API_URL + endpoint,
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.request(options)
    return response.data.payload
  } catch (error) {
    catchHTTPErrors({ endpoint, request: payload, error })
    return null
  }
}
