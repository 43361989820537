import dayjs from 'dayjs'

const CUSTOM_YEAR = 'LP'

export const dateValidations = {
  currentYear: (value, fieldsValues) => {
    const year = fieldsValues.metricYear.value
    const realYear = year === CUSTOM_YEAR ? fieldsValues.lastFarmSubdivisionYear?.value : year
    if (year === CUSTOM_YEAR) {
      return true
    }
    const selected = dayjs(value).toDate()
    const min = new Date(dayjs(`${realYear}-01-01`))
    const max = new Date(dayjs(`${realYear}-12-31`))
    if (selected < min || selected > max) {
      return `La fecha debe estar entre ${min.toLocaleDateString()} y ${max.toLocaleDateString()}`
    }
    return true
  },
  plusOneYear: (value, fieldsValues) => {
    const year = fieldsValues.metricYear.value
    const realYear = year === CUSTOM_YEAR ? fieldsValues.lastFarmSubdivisionYear.value : year
    if (year === CUSTOM_YEAR) {
      return true
    }
    const newYear = parseInt(realYear, 10) + 1
    const selected = dayjs(value).toDate()
    const min = new Date(dayjs(`${realYear}-01-01`))
    const max = new Date(dayjs(`${newYear}-12-31`))
    if (selected < min || selected > max) {
      return `La fecha debe estar entre ${min.toLocaleDateString()} y ${max.toLocaleDateString()}`
    }
    return true
  },
  formFieldsRange: (value, fieldsValues) => {
    const startDateValue = fieldsValues.startDate.value
    const endDateValue = fieldsValues.endDate.value
    if (!startDateValue) return 'Faltan cargar fecha de inicio en la validación de rango de fechas'
    if (!endDateValue) return 'Faltan cargar fecha de find en la validación de rango de fechas'

    const selected = dayjs(value).toDate()
    const min = new Date(dayjs(startDateValue))
    const max = new Date(dayjs(endDateValue).add(1, 'day'))
    if (selected < min || selected > max) {
      return 'Debe estar dentro del rango del plan'
    }

    return true
  },
  formFieldsMinMax: (value, fieldsValues) => {
    const lowerThanDateField = fieldsValues.lowerThan
    const greaterThanDateField = fieldsValues.greaterThan
    const selected = dayjs(value).toDate()

    if (lowerThanDateField) {
      const lowerThanDate = new Date(dayjs(lowerThanDateField.value))
      if (lowerThanDate < selected) {
        return `Debe ser menor a la fecha de ${lowerThanDateField.name}`
      }
    }

    if (greaterThanDateField) {
      const greaterThanDate = new Date(dayjs(greaterThanDateField.value))
      if (greaterThanDate > selected) {
        return `Debe ser mayor a la fecha de ${greaterThanDateField.name}`
      }
    }

    return true
  },
}
