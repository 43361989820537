const fertilizantesSinteticos = {
  namespace: 'paddock_synthetic_fertilizers',
  label: 'Fertilizantes sintéticos',
  required: false,
  requiredMessage: 'No aplica: declaro no haber utilizado fertilizantes sintéticos este año',
  layout: {
    showMap: false,
    showTable: true,
  },
  fields: [
    {
      component_type: 'Title',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Uso de fertilizantes sintéticos',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'InfoText',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Datos de consumo de fertilizantes sintéticos para todo el establecimiento.',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'FieldArray',
      required: false,
      name: 'mineralFertilizers',
      label: 'Fertilizantes minerales',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      dependencies: {
        disabled: [],
        display: [],
      },
      fields: [
        {
          component_type: 'Select',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
          name: 'mineralFertilizerTypeId',
          placeholder: 'Tipo de fertilizante mineral',
          label: 'Tipo de fertilizante mineral',
          variant: 'outlined',
          fullWidth: true,
          required: true,
          direction: 'row',
          defaultValue: 0,
          dependencies: {
            disabled: [],
            display: [],
          },
          refDataKey: 'RefMineralFertilizerType',
        },
        {
          component_type: 'TextField',
          type: 'number',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
          name: 'fertilizerMineralByHectare',
          unit: 'Kg',
          placeholder: 'Cantidad',
          label: 'Cantidad',
          variant: 'standard',
          defaultValue: 0,
          min: 0,
          fullWidth: true,
          required: true,
          dependencies: {
            disabled: [],
            display: [],
          },
        },
      ],
    },
    {
      component_type: 'FieldArray',
      required: false,
      name: 'ammendmend',
      label: 'Enmienda',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      dependencies: {
        disabled: [],
        display: [],
      },
      fields: [
        {
          component_type: 'Select',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
          name: 'ammendemTypeId',
          placeholder: 'Tipo de enmienda',
          label: 'Tipo de enmienda',
          variant: 'outlined',
          fullWidth: true,
          required: true,
          direction: 'row',
          defaultValue: 1,
          dependencies: {
            disabled: [],
            display: [],
          },
          refDataKey: 'RefAmmendmendType',
        },
        {
          component_type: 'TextField',
          type: 'number',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
          name: 'ammendmendDose',
          unit: 'Kg',
          placeholder: 'Cantidad',
          label: 'Cantidad',
          variant: 'standard',
          defaultValue: 0,
          min: 0,
          fullWidth: true,
          required: true,
          dependencies: {
            disabled: [],
            display: [],
          },
        },
        {
          component_type: 'TextField',
          type: '',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
          name: 'ammendmendTypeOther',
          placeholder: 'Aclarar tipo de enmienda',
          label: 'Aclarar tipo de enmienda',
          variant: 'standard',
          defaultValue: '',
          fullWidth: true,
          required: true,
          dependencies: {
            disabled: [],
            display: [{ limeDoseType: { eq: 0 } }],
          },
        },
      ],
    },
  ],
}

export default fertilizantesSinteticos
