import endpoints from '../ruutsApi/endpoints'
import getApiData from '../ruutsApi/getApiData'

export default async function getExclusionAreas(farm, token) {
  try {
    const exclusionAreas = await getApiData(
      endpoints.exclusionAreas,
      {
        farmId: farm.id,
      },
      token,
    )
    return exclusionAreas
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get exclusion areas')
  }
}
