import * as turf from '@turf/turf'
import { truncate, lessPrecision, minimumPrecisionAllowed, defaultOptions } from './truncate.js'

export function distance(from, to, options = defaultOptions, depth = 0) {
  try {
    return (
      turf.distance(truncate(from, options), truncate(to, options), { units: 'kilometers' }) * 1000
    )
  } catch (e) {
    const newOptions = lessPrecision(options)
    if (newOptions.precision < minimumPrecisionAllowed || depth > 5) {
      console.error('Could not calculate distance between polygons', e)
      throw e
    }
    return distance(from, to, newOptions, depth + 1)
  }
}
