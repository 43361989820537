import endpoints from './endpoints'
import getApiData from './getApiData'

export default async function getProgramConfig(farm, token) {
  try {
    const programConfig = await getApiData(endpoints.programConfig, { farmId: farm.id }, token)
    return programConfig
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get program config.')
  }
}
