import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'

const platformVersion = () => {
  const date = new Date(process.env.REACT_APP_PLATFORM_VERSION_DATE)
  if (Number.isNaN(date.getTime())) return 'Error cargando versión'

  return dayjs(date).format('DD-MM-YYYY')
}

const VersionLabel = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Typography fontWeight={600} p={2} variant="caption">
        Versión {platformVersion()}
      </Typography>
    </Box>
  )
}

export default VersionLabel
