import endpoints from '../ruutsApi/endpoints'
import postApiData, { postApiDataWithPayloadResponse } from '../ruutsApi/postApiData'
import updateApiData from '../ruutsApi/updateApiData'
import deleteApiData from '../ruutsApi/deleteApiData'
import { getPaddocks } from './getPaddocks.js'
import { getPaddockById, getCroppedPaddockById } from './getPaddockById.js'

const savePaddocksFromKML = async ({ farm, paddocks, token }) => {
  if (!paddocks?.length) return []

  const paddocksToSave = paddocks.map(paddock => ({
    name: paddock.properties.name,
    farmId: farm.id,
    geometry: paddock.geometry,
    color: 'hsl(305, 63%, 25%)',
  }))

  const createdPaddocks = await postApiDataWithPayloadResponse(
    endpoints.paddocks,
    paddocksToSave,
    token,
  )
  return createdPaddocks.map(createPaddock => createPaddock.id)
}

const createPaddocks = async ({ farm, years, paddocks, token }) => {
  if (!paddocks?.length) return []

  return Promise.all(
    years.map(async year => {
      const createdPaddocks = await postApiData(endpoints.paddocks, paddocks, token)
      return {
        year,
        farmId: farm.id,
        paddockIds: createdPaddocks.payload.map(p => p.id),
      }
    }),
  )
}

const updatePaddocksFeatures = async ({ paddocks, farmId, token }) => {
  const paddocksToUpdate = paddocks.map(paddock => ({
    name: paddock.properties.name,
    geometry: paddock.geometry,
    farmId,
    id: paddock.properties.id,
  }))

  return updateApiData(endpoints.paddocks, paddocksToUpdate, token)
}

const deletePaddocks = async ({ id, userRole, token }) =>
  deleteApiData(`${endpoints.paddocks}/${id}?userRole=${userRole}`, {}, token)

export const paddocks = {
  getPaddocks,
  getPaddockById,
  createPaddocks,
  updatePaddocksFeatures,
  deletePaddocks,
  savePaddocksFromKML,
  getCroppedPaddockById,
}
