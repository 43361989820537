import * as turf from '@turf/turf'
import L from 'leaflet'
import React, { useEffect, useState } from 'react'
import { GeoJSON, LayersControl, MapContainer } from 'react-leaflet'
import BaseLayers from '../map/BaseLayers'
import { GeomanControl } from './GeomanControl'

const mapStyle = { height: '100vh', width: '100%', padding: 0 }

export const mapActionsTypes = {
  editStart: 0,
  editEnd: 1,
}

const VALID_GEOMETRY_TYPES = ['Polygon', 'MultiPolygon']

const PerimeterMap = ({
  mapWidth,
  mapHeight,
  features,
  setFeaturesUpdated,
  mapActions,
  setMapReady,
}) => {
  const [map, setMap] = useState(null)
  const [perimeter, setPerimeter] = useState(null)
  const [center, setCenter] = useState(undefined)

  useEffect(() => {
    if (!features || center) return

    const newPerimeter =
      features && features.find(feature => feature.properties.type === 'perimeter')
    if (!newPerimeter || center || !newPerimeter.geometry) return

    const [longitude, latitude] = turf.center(newPerimeter).geometry.coordinates // Swap coordinates to work with Leaflet
    const newCenter = [latitude, longitude]
    setPerimeter(newPerimeter)
    setCenter(newCenter)
  }, [features])

  useEffect(() => {
    if (!map || !perimeter) return

    map.fitBounds(L.geoJSON(perimeter).getBounds())
  }, [map, perimeter])

  const filterLayersByProperty = propertiesValues => {
    const layers = []

    map.eachLayer(layer => {
      if (layer instanceof L.GeoJSON) {
        // Access the GeoJSON feature's properties
        const featureCollection = layer.toGeoJSON()

        const filteredFeatures = featureCollection.features.filter(feature => {
          return propertiesValues.some(propertyValue => {
            return Object.keys(propertyValue).some(key => {
              return feature.properties[key] === propertyValue[key]
            })
          })
        })

        if (filteredFeatures.length > 0) {
          layers.push(layer)
        }
      }
    })

    return layers
  }

  useEffect(() => {
    if (!map || !mapActions || mapActions.length === 0) return

    mapActions.forEach(action => {
      const selectedLayer = filterLayersByProperty(action.propertiesFeature)
      switch (action.id) {
        case mapActionsTypes.editStart: {
          selectedLayer?.forEach(layer => {
            if (!layer.pm.enabled()) {
              layer.pm.enable({
                allowSelfIntersection: false,
                snappable: true,
              })
            }
          })
          break
        }
        case mapActionsTypes.editEnd: {
          selectedLayer?.forEach(layer => {
            if (layer.pm.enabled()) {
              layer.pm.disable()
            }
          })
          break
        }
        default:
          console.warn(`Unknown map action: ${mapActions.id}`)
      }
    })
  }, [mapActions, map])

  const pointToLayer = (feature, latlng) => {
    const marker = L.circleMarker(latlng, {
      radius: 15,
      color: feature?.style?.color,
    }).bindTooltip(feature.properties.name)
    return marker
  }

  const handleStyle = feature => {
    return feature.style
  }

  const replaceFeature = (id, newFeature) => {
    const index = features.findIndex(feature => feature.properties.id === id)

    if (index !== -1) {
      const updatedFeatures = [
        ...features.slice(0, index),
        newFeature,
        ...features.slice(index + 1),
      ]

      setFeaturesUpdated(updatedFeatures)
    }
  }

  const handleOnEachPolygonFeature = (_, layer) => {
    layer.on('pm:change', e => {
      const newFeature = e.layer.toGeoJSON()
      replaceFeature(newFeature.properties.id, newFeature)
    })
  }

  return (
    <>
      {' '}
      {center && (
        <MapContainer
          ref={setMap}
          scrollWheelZoom
          zoomControl
          center={center}
          mapHeight={mapHeight - 1000}
          mapWidth={mapWidth}
          style={{ ...mapStyle, height: mapHeight, width: mapWidth }}
          wheelPxPerZoomLevel={60}
          whenReady={() => {
            setMapReady(true)
          }}
          zoom={8}
          zoomDelta={1}
          zoomSnap={0.8}
        >
          <GeomanControl />

          <LayersControl>
            <BaseLayers />
          </LayersControl>

          {features &&
            features.map(feature => {
              if (feature.geometry.type === 'Point') {
                return (
                  <GeoJSON
                    key={feature.properties.id}
                    data={feature}
                    pointToLayer={pointToLayer}
                    style={handleStyle(feature)}
                  />
                )
              }
              if (VALID_GEOMETRY_TYPES.includes(feature.geometry.type)) {
                return (
                  <GeoJSON
                    key={feature.properties.id}
                    data={feature}
                    style={handleStyle(feature)}
                    onEachFeature={(_, layer) => handleOnEachPolygonFeature(feature, layer)}
                  />
                )
              }
              return null
            })}
        </MapContainer>
      )}
    </>
  )
}

export default PerimeterMap
