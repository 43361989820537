const labranza = {
  namespace: 'paddock_tillage',
  label: 'Labranza',
  required: false,
  requiredMessage: 'No aplica: declaro no haber labrado en todo el año',
  layout: {
    showMap: true,
    showTable: true,
  },
  fields: [
    {
      component_type: 'Title',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Labranza',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'InfoText',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Los datos deben ser cargados por lote o conjunto de lotes con los mismos valores.',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'Select',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'tillageTypeId',
      placeholder: 'Tipo de labranza',
      label: 'Tipo de labranza',
      defaultValue: 0,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [],
      },
      refDataKey: 'RefTillageType',
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'tillageDepth',
      unit: 'cm',
      placeholder: 'Profundidad',
      label: 'Profundidad',
      defaultValue: 0,
      min: 0,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'RadioGroup',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'tillageFrequency',
      placeholder: 'Eventos por año',
      label: 'Eventos por año',
      defaultValue: null,
      fullWidth: true,
      required: true,
      defaultOptions: [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
      ],
      direction: 'row',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'removedResiduesPct',
      unit: '%',
      placeholder: 'Residuos de cultivos retirados',
      label: 'Residuos de cultivos retirados',
      defaultValue: 0,
      min: 0,
      max: 100,
      fullWidth: false,
      required: true,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'RadioGroup',
      type: 'string',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'impactedSoil',
      placeholder: 'Superficie de suelo perturbada',
      label: 'Superficie de suelo perturbada',
      defaultValue: null,
      fullWidth: true,
      required: true,
      defaultOptions: [
        { value: 'Total', label: 'Total' },
        { value: 'Parcial', label: 'Parcial' },
      ],
      direction: 'row',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'impactedSoilPct',
      unit: 'ha',
      placeholder: 'Superficie parcial del suelo perturbada',
      label: 'Superficie parcial del suelo perturbada',
      defaultValue: 0,
      min: 0,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [{ impactedSoil: { eq: 'Parcial' } }],
      },
    },
  ],
}

export default labranza
