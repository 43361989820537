// Material UI
import { Box, Typography } from '@mui/material'

const TitleWrapper = ({ formField }) => {
  return (
    <Box>
      <Typography variant="h6">{formField.label}</Typography>
    </Box>
  )
}

export default TitleWrapper
