import { proj } from 'utm-zone'
import proj4 from 'proj4'
import GeoJSON from 'ol/format/GeoJSON.js'

// NOTE: This file is duplicated on RPD y API projects. Any modification MUST be replicated to both.

function projGeometry(geometry) {
  // Define the source and target CRS using Proj4js
  const sourceCRS = new proj4.Proj('EPSG:4326') // Source CRS: WGS84
  const targetCRS = new proj4.Proj(proj(geometry)) // Target CRS: UTM

  // Project coordinate
  function projectCoordinate(coordinate) {
    const projection = proj4.transform(sourceCRS, targetCRS, coordinate)
    return [projection.x, projection.y]
  }

  // Define how to project a geometry type
  const geometriesProjectionByType = {
    Polygon: geo => ({
      ...geo,
      coordinates: geo.coordinates.map(coordinateEntry =>
        coordinateEntry.map(coordinate => projectCoordinate(coordinate)),
      ),
    }),
    MultiPolygon: geo => ({
      ...geo,
      coordinates: geo.coordinates.map(polygon =>
        polygon.map(coordinateEntry =>
          coordinateEntry.map(coordinate => projectCoordinate(coordinate)),
        ),
      ),
    }),
    GeometryCollection: geo => ({
      ...geo,
      geometries: geo.geometries.map(geometryItem => projGeometry(geometryItem)),
    }),
  }

  // Validate if the geometry type is unknown
  if (!geometriesProjectionByType[geometry.type]) {
    throw new Error('Geometry type unknown:', geometry)
  }

  // Execute the associated geometry type projection
  return geometriesProjectionByType[geometry.type](geometry)
}

// Returns the GeoJSONFeature's area in meter using its associated UTM Zone
export function area(geoJSONFeature) {
  try {
    // Project the geometries using its associated UTM Zone
    const geometryProj = projGeometry(geoJSONFeature.geometry)

    // Rebuild the GeoJSONFeature with the geometries projected
    const geoJSONGeometryUTMProj = {
      ...geoJSONFeature,
      geometry: geometryProj,
    }

    // Load new geoJSONGeometryUTM projected to calculate the area projected
    const GeoJSONProjectedFeatures = new GeoJSON().readFeatures(geoJSONGeometryUTMProj)

    // Summarize the area from each feature
    return GeoJSONProjectedFeatures.reduce((featureAreaAcc, GeoJSONFeature) => {
      const geometry = GeoJSONFeature.getGeometry()

      // If the geometry has only one element (Polygon type) the value is its area
      // otherwise summarize each geometry area
      const geometryArea =
        geometry.getType() !== 'GeometryCollection'
          ? geometry.getArea()
          : geometry.getGeometries().reduce((geometryAreaAcc, geo) => {
              return geometryAreaAcc + geo.getArea()
            }, 0)

      return featureAreaAcc + geometryArea
    }, 0)
  } catch (error) {
    console.error(error, geoJSONFeature)
    return null
  }
}

// Returns the GeoJSONFeature's area in hectare using its associated UTM Zone
export function hectareArea(geoJSONFeature) {
  if (!geoJSONFeature?.geometry?.coordinates?.length) return 0

  return area(geoJSONFeature) / 10000
}
