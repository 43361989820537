import { validatePerimeter } from './updatePerimeter'

export const updateFarmValidation = async ({
  id,
  geometry,
  originalGeometry,
  center,
  dryRun,
  overrideEdition,
  token,
}) => {
  await validatePerimeter({
    id,
    geometry,
    originalGeometry,
    center,
    dryRun,
    overrideEdition,
    token,
  })
}
