import { Outlet } from 'react-router'
import DataCollectionForm from '../components/DataCollectionForm/DataCollectionForm'
import FarmData from '../components/FarmData/FarmData'
import HomePage from '../components/HomePage/HomePage'
import ManagementHome from '../components/ManagementHome/ManagementHome'
import MonitoringHome from '../components/MonitoringHome/MonitoringHome'
import TableauView from '../components/TableauView/TableauView'

const MainRoutes = {
  path: '/',
  element: <Outlet />,
  children: [
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '/farms',
      element: <FarmData />,
    },
    {
      path: '/management',
      element: <ManagementHome />,
    },
    {
      path: '/management/data/:year',
      element: <DataCollectionForm />,
    },
    {
      path: '/monitoring',
      element: <MonitoringHome />,
    },
    {
      path: '/tableau',
      element: <TableauView />,
    },
  ],
}

export default MainRoutes
