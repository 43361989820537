import endpoints from '../ruutsApi/endpoints'
import postApiData from '../ruutsApi/postApiData'

export const saveMonitoringSites = async (currentFarm, data, token) => {
  try {
    const monitoringSiteBody = {
      farmId: currentFarm.id,
      name: data.properties.name,
      samplingAreaId: data.properties.samplingAreaId || null,
      plannedLocation: [data.geometry.coordinates[1], data.geometry.coordinates[0]],
      isRandomSite: data.properties.isRandomSite || false,
    }
    await postApiData(endpoints.sites, monitoringSiteBody, token)
  } catch (error) {
    console.error({ error })
  }
}
