import ConfirmationModal from './ConfirmationModal'

const PerimeterConfirm = ({ open, handleClose, handleConfirm, buttonValue }) => {
  return (
    <ConfirmationModal
      buttonValue={buttonValue}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      message="Una vez confirmado, no podrá modificar el perímetro."
      open={open}
      title="Confirmar Perímetro"
    />
  )
}

export default PerimeterConfirm
