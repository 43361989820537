import { findingsServices } from '../../../services/ruutsApi/findings/index'

export async function unresolvedFindings(dataCollectionStatementId, namespace, token) {
  try {
    const findingsToResolve = await findingsServices.getByDCSNamespace({
      dataCollectionStatementId,
      namespace,
      resolved: false,
      token,
    })
    const findingsToResolveCount = findingsToResolve?.length

    return {
      disabled: false,
      totalError: findingsToResolveCount,
      error: findingsToResolveCount ? 'Existen observaciones a resolver' : '',
    }
  } catch (error) {
    // The finding flag is not required, so if there is an error loading it, we ignore to load form data
    return {
      disabled: false,
      totalError: 0,
      error: '',
    }
  }
}
