import { useCallback, useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import AppContext from '../../context/appContext'
import { processLoadingWithConfirmation } from './processLoading'
import {
  contactSupportReasons,
  displayErrorMessageModes,
} from '../../components/GlobalLoadingModal/GlobalLoadingModal'

export function useProcessLoadingWithConfirmation() {
  const { getAccessTokenSilently } = useAuth0()
  const { setPartialChanges, setLoadingModalConfig, setConfirmationModalConfig } =
    useContext(AppContext)

  const handleProcessLoadingWithConfirmation = useCallback(
    async ({
      doAction,
      confirmationTitle,
      confirmationMessage,
      loadingMessage,
      errorMessage,
      successfulMessage = '',
    }) => {
      return processLoadingWithConfirmation({
        getToken: getAccessTokenSilently,
        setPartialChanges,
        loadingModalConfig: {
          setLoadingModalConfig,
          loadingMessage,
          contactReasonSupportType: contactSupportReasons.Generic,
          errorMessage,
          displayErrorMessageMode: displayErrorMessageModes.dialog,
          successfulMessage,
        },
        confirmationModalConfig: {
          setConfirmationModalConfig,
          title: confirmationTitle,
          message: confirmationMessage,
        },
        doAction,
      })
    },
    [getAccessTokenSilently, setConfirmationModalConfig, setLoadingModalConfig, setPartialChanges],
  )

  return { processLoadingWithConfirmation: handleProcessLoadingWithConfirmation }
}
