// Material UI
import { Tab as MuiTab } from '@mui/material'
import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'

const Tab = ({ key, label, tooltipError, totalError, typeError, ...rest }) => {
  return (
    <Tooltip arrow title={tooltipError}>
      <div>
        {/* This div is needed because a child of a Material-UI Tooltip must be able to hold a ref. Otherwise the tooltip is not showed */}
        <MuiTab
          {...rest}
          key={key}
          label={
            <Badge badgeContent={totalError} color={typeError}>
              {label}
            </Badge>
          }
          sx={{ textTransform: 'none' }}
        />
      </div>
    </Tooltip>
  )
}

export default Tab
