import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export const BooleanRowData = ({ value }) => {
  return (
    <>
      {value ? (
        <CheckCircleIcon style={{ color: 'green' }} />
      ) : (
        <CloseIcon style={{ color: 'red' }} />
      )}
    </>
  )
}
