import endpoints from '../ruutsApi/endpoints'
import { postApiDataWithPayloadResponse } from '../ruutsApi/postApiData'

function getExclusionAreaName(exclusionArea) {
  if (!exclusionArea?.properties) return null

  const { exclusionAreaTypeId, otherName, name } = exclusionArea.properties
  return exclusionAreaTypeId === 99 ? otherName || name : null
}

export const saveExclusionAreas = async (currentFarm, exclusionAreas, token) => {
  const exclusionAreasBody = exclusionAreas.map(exclusionArea => ({
    name: exclusionArea.properties.name,
    otherName: getExclusionAreaName(exclusionArea),
    farmId: currentFarm.id,
    geometry: exclusionArea.geometry,
    exclusionAreaTypeId: exclusionArea?.properties?.exclusionAreaTypeId,
    hasGrazingManagement: exclusionArea.properties.hasGrazingManagement,
    color: 'hsl(40, 60%, 0%)',
  }))
  return postApiDataWithPayloadResponse(endpoints.exclusionAreas, exclusionAreasBody, token)
}
