import deleteApiData from '../ruutsApi/deleteApiData'
import endpoints from '../ruutsApi/endpoints'
import getApiData from '../ruutsApi/getApiData'
import postApiData from '../ruutsApi/postApiData'
import updateApiData from '../ruutsApi/updateApiData'

async function getByEntityAndDocumentsTypes({ entityId, entityTypeId, documentsTypes, token }) {
  const documents = await getApiData(
    endpoints.documents,
    {
      entityId,
      entityTypeId,
      documentsTypes,
    },
    token,
  )
  return documents
}

async function create({ document, token }) {
  const response = await postApiData(endpoints.documents, document, token)
  return response
}

async function update({ document, token }) {
  const response = await updateApiData(endpoints.documents, document, token)
  return response
}

async function remove({ documents, token }) {
  const response = await deleteApiData(
    endpoints.documents,
    documents.map(document => document.id),
    token,
  )
  return response
}

export const documents = {
  getByEntityAndDocumentsTypes,
  create,
  remove,
  update,
}
