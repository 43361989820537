import * as turf from '@turf/turf'
import { truncate, lessPrecision, minimumPrecisionAllowed, defaultOptions } from './truncate.js'

export function difference(poly1, poly2, options = defaultOptions, depth = 0) {
  try {
    return turf.difference(truncate(poly1, options), truncate(poly2, options))
  } catch (e) {
    const newOptions = lessPrecision(options)
    if (newOptions.precision < minimumPrecisionAllowed || depth > 5) {
      console.error('Could not calculate difference between polygons', e)
      throw e
    }
    return difference(poly1, poly2, newOptions, depth + 1)
  }
}
