import Tooltip from '@mui/material/Tooltip'
import DoneIcon from '@mui/icons-material/Done'
import { IconButton, Stack } from '@mui/material'

const TableApproveButton = ({ dataCollectionStatement, allowApprove, handleApprove }) => {
  return (
    <Stack alignItems="center" direction="row" display="flex" justifyContent="center">
      {allowApprove && (
        <Tooltip title="Aprobar">
          <IconButton size="small" onClick={() => handleApprove(dataCollectionStatement)}>
            <DoneIcon sx={{ height: '18px', width: '18px' }} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )
}

export default TableApproveButton
