import endpoints from './endpoints'
import deleteApiData from './deleteApiData'

export default async function deleteMetric(
  metricId,
  dataCollectionStatementId,
  reasonComment,
  userRole,
  token,
) {
  return deleteApiData(
    `${endpoints.metricEvents}/${metricId}?userRole=${userRole}`,
    { dataCollectionStatementId, reasonComment },
    token,
  )
}
