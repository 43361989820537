import { CircularProgress, Container, Stack, Typography } from '@mui/material'

const Authorizing = () => {
  return (
    <Container maxWidth={false} sx={{ height: '100vh', display: 'fixed' }}>
      <Stack
        alignItems="center"
        direction="column"
        display="flex"
        height="100vh"
        justifyContent="center"
        width="100vw"
      >
        <Typography variant="h6">Autorizando...</Typography>
        <CircularProgress />
      </Stack>
    </Container>
  )
}

export default Authorizing
