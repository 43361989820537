// Material UI
import Box from '@mui/material/Box'

const TabPanel = props => {
  const { children, value, index, sx, ...other } = props

  return (
    <Box
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  )
}

export default TabPanel
