import { InfoOutlined } from '@mui/icons-material'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import AppContext from '../../context/appContext'

const DataCollectionHomeInfo = () => {
  const { userRoleSelected } = useContext(AppContext)

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Stack
          alignItems="left"
          direction="column"
          display="flex"
          flexGrow={1}
          justifyContent="space-between"
          spacing={1}
        >
          <Stack alignItems="center" direction="row" justifyContent="start">
            <Box>
              <InfoOutlined />
            </Box>
            <Typography pl={1} textTransform="uppercase" variant="title">
              <b>A tener en cuenta para la carga de datos:</b>
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography pr={1} variant="body2">
              <b>Datos cualitativos:</b>
            </Typography>
            <Typography variant="caption">
              Cargar el dato predominante (el que mas tiempo ocupa en el año) cuando la plataforma
              no da mas que una opción.
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography pr={1} variant="body2">
              <b>Datos cuantitativos:</b>
            </Typography>
            <Typography variant="caption">
              Calcular el promedio simple/ponderado para representar el año. Si se posee dato por
              campaña, realizar el promedio entre las dos que entran en el año.
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      {userRoleSelected === 'Auditor' ? (
        <Grid item alignItems="center" display="flex" xs={2}>
          <Button component={Link} target="_blank" to="/tableau">
            explorar datos
          </Button>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default DataCollectionHomeInfo
