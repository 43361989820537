/* eslint-disable no-await-in-loop */
import dayjs from 'dayjs'
import { localRefData } from '../../services/localRefData/index'

export const fieldArraysValidations = {
  grazingEventsAnimalsOccupation: async fieldsValues => {
    const animals = fieldsValues.cellAnimals
    const grazingEvents = fieldsValues.value
    const grazingActualStartDateValue = fieldsValues.grazingActualStartDate
    const grazingActualEndDateValue = fieldsValues.grazingActualEndDate
    if (!animals.length) return 'Faltan cargar los animales del rodeo'
    if (!grazingEvents.length) return 'Faltan cargar los movimientos de animales'
    if (!grazingActualStartDateValue) return 'Faltan cargar fecha de inicio del plan'
    if (!grazingActualEndDateValue) return 'Faltan cargar fecha de find del plan'

    const getCattleSubClassName = async ({ cattleSubClassId }) => {
      const cattleSubClass = await localRefData.getDataById(
        localRefData.keys.cattleSubClass,
        parseInt(cattleSubClassId, 10),
      )
      return cattleSubClass ? cattleSubClass.es_AR : null
    }

    const headCountByCattleSubClasses = {}
    animals.forEach(animal => {
      const { headcount, cattleSubClass, temporalAnimals } = animal
      if (headcount !== '' && cattleSubClass !== '') {
        // Animals without all the required values are not considered
        if (!headCountByCattleSubClasses[cattleSubClass]) {
          headCountByCattleSubClasses[cattleSubClass] = { false: 0, true: 0 }
        }
        headCountByCattleSubClasses[cattleSubClass][temporalAnimals] += parseInt(headcount, 10)
      }
    })

    const occupationByCattleSubClasses = grazingEvents.reduce((acc, event) => {
      const startDate = new Date(event.grazedByPaddockStartDate)
      const endDate = new Date(event.grazedByPaddockEndDate)

      const currentDate = new Date(startDate)
      while (currentDate < endDate) {
        const formattedDate = currentDate.toISOString().split('T')[0]
        const key = `${event.cattleSubClass}`

        // Initialize object for cattleSubClass if not already present
        acc[key] = acc[key] || {}

        // Initialize headcount for date if not already present
        const formattedDateValue = `${formattedDate}-${event.temporalAnimals}`
        acc[key][formattedDateValue] =
          (acc[key][formattedDateValue] || 0) + parseInt(event.headcount, 10)

        currentDate.setDate(currentDate.getDate() + 1)
      }

      return acc
    }, {})

    let errorMessage = null
    let grazingPlanCheckDate = dayjs(grazingActualStartDateValue)
    const grazingActualEndDate = dayjs(grazingActualEndDateValue)

    // This object will be used to check if all the occupation counts are consistent with the cell headcount
    // If there is a date with occupation count that is present in the cell headcount, it will be removed from this object
    // At the end of the loop, if there are any dates left in this object, it means that the occupation count is missing for those dates
    const occupationByCattleSubClassesChecked = { ...occupationByCattleSubClasses }
    while (!errorMessage && grazingPlanCheckDate <= grazingActualEndDate) {
      const cattleSubClassesKeys = Object.keys(headCountByCattleSubClasses)
      const grazingPlanCheckDateValue = grazingPlanCheckDate.format('YYYY-MM-DD')

      for (let i = 0; i < cattleSubClassesKeys.length; i++) {
        const cattleSubClassId = cattleSubClassesKeys[i]
        const headcountFarmCattleSubClass = headCountByCattleSubClasses[cattleSubClassId].false || 0
        const headcountTemporaryCattleSubClass =
          headCountByCattleSubClasses[cattleSubClassId].true || 0

        const occupationByCattleSubClassDates = occupationByCattleSubClasses[cattleSubClassId]
        const occupationFarmCountByCattleSubClassOnCheckDate =
          occupationByCattleSubClassDates &&
          occupationByCattleSubClassDates[`${grazingPlanCheckDateValue}-${false}`]
            ? occupationByCattleSubClassDates[`${grazingPlanCheckDateValue}-${false}`]
            : 0
        const occupationTemporaryCountByCattleSubClassOnCheckDate =
          occupationByCattleSubClassDates &&
          occupationByCattleSubClassDates[`${grazingPlanCheckDateValue}-${true}`]
            ? occupationByCattleSubClassDates[`${grazingPlanCheckDateValue}-${true}`]
            : 0

        if (headcountFarmCattleSubClass !== occupationFarmCountByCattleSubClassOnCheckDate) {
          errorMessage =
            `Cantidad declarada del animal ${await getCattleSubClassName({ cattleSubClassId })} del establecimiento en el rodeo no corresponde a la cantidad declara en el movimiento en el día ${grazingPlanCheckDate.format('DD/MM/YYYY')}. ` +
            `Rodeo: ${headcountFarmCattleSubClass} - Movimiento: ${occupationFarmCountByCattleSubClassOnCheckDate}`
          break
        }

        // Occupation temporary animals are optional for the whole plan range.
        // So if there are no temporary animals for a date we don't need to check if the values are consistent
        if (
          occupationTemporaryCountByCattleSubClassOnCheckDate !== 0 &&
          headcountTemporaryCattleSubClass !== occupationTemporaryCountByCattleSubClassOnCheckDate
        ) {
          errorMessage =
            `No coincide la cantidad de animales temporales declarados en el rodeo (${await getCattleSubClassName({ cattleSubClassId })}). ` +
            `Rodeo: ${headcountTemporaryCattleSubClass} - Movimiento: ${occupationTemporaryCountByCattleSubClassOnCheckDate}`
          break
        }

        if (occupationByCattleSubClassesChecked[cattleSubClassId]) {
          delete occupationByCattleSubClassesChecked[cattleSubClassId][
            `${grazingPlanCheckDateValue}-${true}`
          ]
          delete occupationByCattleSubClassesChecked[cattleSubClassId][
            `${grazingPlanCheckDateValue}-${false}`
          ]
        }
      }

      grazingPlanCheckDate = dayjs(grazingPlanCheckDate).add(1, 'day')
    }

    // If there are no errors, check if there are any cattleSubClasses that are not included in the cell animals
    if (!errorMessage) {
      // Filter out the cattleSubClasses that are not included in the cell animals, the remaining ones are the ones that are not consistent
      const occupationsByCattleSubClassesNotIncludedInTheCellAnimals = Object.entries(
        occupationByCattleSubClassesChecked,
      )
        .filter(([_, value]) => Object.keys(value).length !== 0)
        .map(([key, value]) => ({ [key]: value }))

      // If there are any inconsistencies, the first one will be returned as the error message
      if (occupationsByCattleSubClassesNotIncludedInTheCellAnimals?.length) {
        const firstInconsistency = occupationsByCattleSubClassesNotIncludedInTheCellAnimals[0]
        const cattleSubClassId = Object.keys(firstInconsistency)[0]
        errorMessage = `El animal ${await getCattleSubClassName({ cattleSubClassId })} esta el movimiento del rodeo, pero no esta cargado en la definición del rodeo.`
      }
    }

    return errorMessage
  },
}
