import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'

const YearSelector = ({ years, handleYears }) => {
  const handleYearChoosed = e => {
    const newYears = years.map(year => {
      const newYear = { ...year }
      if (year.value === e.target.value) {
        newYear.selected = !year.selected
      }
      return newYear
    })
    handleYears(newYears)
  }

  return (
    <Box
      sx={{
        borderRadius: 4,
        backgroundColor: '#f3f7f8',
        padding: 2,
        marginX: 2,
        textAlign: 'center',
        marginBottom: 4,
      }}
    >
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 600,
          margin: 2,
        }}
      >
        Año/s en que se aplicó este loteo
      </Typography>
      {years.length > 0 ? (
        <Grid container spacing={1}>
          {years.map(year => {
            return (
              <Grid key={year.value} item xs={3}>
                <Button
                  key={year.value}
                  sx={{
                    width: 50,
                    height: 30,
                    borderRadius: 10,
                  }}
                  value={year.value}
                  variant={year.selected ? 'contained' : 'outlined'}
                  onClick={handleYearChoosed}
                >
                  {year.value}
                </Button>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <CircularProgress sx={{ margin: 2 }} />
      )}
    </Box>
  )
}

export default YearSelector
