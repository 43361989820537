import localforage from 'localforage'

localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'ruuts-rpd',
  version: 1.0,
  storeName: 'app-data',
  description: 'App cache',
})

const lfStore = {
  async getItem(key) {
    try {
      const value = await localforage.getItem(key)
      return value
    } catch (e) {
      console.error(e)
      return null
    }
  },
  async setItem(key, value) {
    try {
      await localforage.setItem(key, value)
    } catch (e) {
      console.error(e)
    }
  },
  async removeItem(key) {
    await localforage.removeItem(key)
  },
  async keys() {
    try {
      const value = await localforage.keys()
      return value
    } catch (e) {
      console.error(e)
      return null
    }
  },
}

export default lfStore
