import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Typography } from '@mui/material'
import { Fragment, useContext, useState } from 'react'
import AppContext from '../../../context/appContext'
import lfStore from '../../../lfstore/lfStore'
import { saveManualFarmMapping } from '../../../services/farmMapping/saveManualFarmMapping'
import { ruutsApi } from '../../../services/ruutsApi'
import DrawingPencil from '../../Icons/DrawingPencil'
import PerimeterConfirm from '../modals/PerimeterConfirm'
import { processLoading } from '../../../utils/Loading/processLoading'

const getPerimeterAreaTotal = perimeter => {
  let perimeterAreaTotal = 0
  perimeter?.forEach(feature => {
    perimeterAreaTotal += parseFloat(feature.properties.area)
  })
  return `${perimeterAreaTotal.toFixed(2)} ha`
}

const Manual = ({ handleDrawingType, etapa, handleEtapa, perimeter, handlePerimeter, center }) => {
  const { currentFarm, setCurrentFarm, setPartialChanges, setLoadingModalConfig } =
    useContext(AppContext)

  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false)

  const { user, getAccessTokenSilently } = useAuth0()

  const handleOpen = () => {
    setModalConfirmationOpen(true)
  }
  const handleClose = () => {
    setModalConfirmationOpen(false)
  }

  const setManualSelection = () => {
    handleEtapa('drawingStarted')
    handleDrawingType('perimeter')
  }

  const cancelManualSelection = () => {
    handleEtapa('inicial')
  }

  const handleUpdateCurrentFarm = updatedFarm => {
    setCurrentFarm(updatedFarm)
    lfStore.setItem('currentFarm', updatedFarm)
  }

  const handleSavePerimeter = async () => {
    await processLoading({
      getToken: getAccessTokenSilently,
      setLoadingModalConfig,
      loadingMessage: 'Guardando perímetro...',
      doAction: async ({ token }) => {
        await saveManualFarmMapping(currentFarm, token, perimeter, center)
        const updatedFarm = await ruutsApi.getFarm({ id: currentFarm.id, user, token })
        handleUpdateCurrentFarm(updatedFarm)

        handleEtapa('showingFoldersManual')
        setPartialChanges(false)
      },
    })
    handleClose()
  }

  const clearMap = () => {
    handlePerimeter(null)
    setPartialChanges(false)
  }

  return (
    <Box>
      <Typography
        sx={{
          fontSize: 14,
          margin: 2,
          marginTop: 4,
        }}
      >
        Dibuje el perímetro del establecimiento marcando los puntos que lo definen.
      </Typography>
      {etapa === 'inicialManual' ? (
        <Box
          sx={{
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 2,
          }}
        >
          <DrawingPencil />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mt: 1,
            }}
          >
            <Button
              sx={{
                backgroundColor: '#1976d2',
                color: 'white',
                borederRadius: 4,
                margin: 2,
                px: 2,
                fontWeight: 600,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#1976d2',
                  color: 'white',
                  opacity: 0.8,
                },
              }}
              onClick={setManualSelection}
            >
              Dibujar perímetro
            </Button>
            <Button
              sx={{
                color: '#1976d2',
                backgroundColor: 'white',
                margin: 2,
                px: 2,
                fontWeight: 600,
                textTransform: 'none',
              }}
              onClick={cancelManualSelection}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      ) : null}
      {etapa === 'drawingStarted' || etapa === 'drawingFinished' ? (
        <Box
          sx={{
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 2,
          }}
        >
          {perimeter ? (
            <>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                  margin: 2,
                }}
              >
                {perimeter?.length > 0 ? getPerimeterAreaTotal(perimeter) : null}
              </Typography>
              {etapa !== 'drawingFinished' ? (
                <div>
                  <Button
                    sx={{
                      backgroundColor: '#1976d2',
                      color: 'white',
                      borederRadius: 4,
                      margin: 2,
                      px: 2,
                      fontWeight: 600,
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#1976d2',
                        color: 'white',
                        opacity: 0.8,
                      },
                    }}
                    onClick={handleOpen}
                  >
                    Confirmar perímetro
                  </Button>
                  <PerimeterConfirm
                    buttonValue="showingFoldersManual"
                    handleClose={handleClose}
                    handleConfirm={handleSavePerimeter}
                    open={modalConfirmationOpen}
                  />

                  <Button
                    sx={{
                      backgroundColor: 'white',
                      color: '#1976d2',
                      borederRadius: 4,
                      margin: 2,
                      px: 2,
                      fontWeight: 600,
                      textTransform: 'none',
                      border: '1px solid #1976d2',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#1976d2',
                        opacity: 0.8,
                      },
                    }}
                    onClick={clearMap}
                  >
                    Limpiar mapa
                  </Button>
                </div>
              ) : null}
            </>
          ) : null}
        </Box>
      ) : null}
    </Box>
  )
}

export default Manual
