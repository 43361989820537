import AgricultureIcon from '@mui/icons-material/Agriculture'
import ClearIcon from '@mui/icons-material/Clear'
import EmailIcon from '@mui/icons-material/Email'
import MapIcon from '@mui/icons-material/Map'
import PhoneIcon from '@mui/icons-material/Phone'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const ViewEventModal = ({ farm, event, open, setOpenModal }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  const googleMapsLink = coordinates => {
    return `https://www.google.com/maps/search/?api=1&query=${coordinates[1]},${coordinates[0]}`
  }

  return (
    <Modal
      aria-describedby="modal-modal-description"
      aria-labelledby="modal-modal-title"
      open={open}
      onClose={() => setOpenModal(false)}
    >
      {farm && event && (
        <Box sx={style}>
          <IconButton
            sx={{ float: 'right', cursor: 'pointer' }}
            onClick={() => setOpenModal(false)}
          >
            <ClearIcon />
          </IconButton>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-start"
            spacing={2}
            sx={{ marginBottom: '20px' }}
          >
            <AgricultureIcon sx={{ fontSize: 30 }} />
            <Typography component="h2" id="modal-modal-title" variant="h6">
              <b>{farm.name}</b>
            </Typography>
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-start"
            spacing={2}
            sx={{ marginBottom: '20px' }}
          >
            <MapIcon sx={{ fontSize: 30 }} />
            <Box>
              <Typography variant="p">
                {farm.address} - {farm.city}, {farm.province}, {farm.country}
              </Typography>
              <br />
              {farm.toGeoJSON && (
                <Link
                  href={
                    farm.toGeoJSON
                      ? googleMapsLink(
                          farm.geolocation || farm.toGeoJSON.geometry.coordinates[0][0],
                        )
                      : ''
                  }
                  target="_blank"
                >
                  <b>¿Cómo llegar?</b>
                </Link>
              )}
            </Box>
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-start"
            spacing={2}
            sx={{ marginBottom: '20px' }}
          >
            <PhoneIcon sx={{ fontSize: 30 }} />
            <Link href={`tel:${farm.phoneNumber}`} variant="h6">
              {farm.phoneNumber}
            </Link>
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-start"
            spacing={2}
            sx={{ marginBottom: '20px' }}
          >
            <EmailIcon sx={{ fontSize: 30 }} />
            <Link href={`mailto:${farm.email}`} variant="h6">
              {farm.email}
            </Link>
          </Stack>
        </Box>
      )}
    </Modal>
  )
}

export default ViewEventModal
