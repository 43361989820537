import endpoints from './endpoints.js'
import postApiData from '../../ruutsApi/postApiData.js'

export default async function vectorize(file, name, perimeter, area, threshold, token) {
  const perimeterFeatureCollection = {
    type: 'FeatureCollection',
    features: [perimeter],
  }

  const formData = new FormData()
  formData.append('file', file, name)
  formData.append('perimeter', JSON.stringify(perimeterFeatureCollection))
  formData.append('area', area)
  formData.append('threshold', threshold)

  return postApiData(endpoints.vectorize, formData, token)
}
