export const cleanUpMap = (mapeo, mapeoType) => {
  const data = {}
  if (mapeoType === 'showingFoldersArchivo') {
    const mapKeys = Object.keys(mapeo)
    mapKeys.forEach(key => {
      if (key !== 'unassigned') {
        const featureGroup = mapeo[key]
        if (featureGroup.features.length > 0) {
          data[key] = featureGroup
        }
      }
    })
  } else {
    mapeo.forEach(featureGroup => {
      if (featureGroup?.features?.length > 0) {
        data.perimeter = {
          features: featureGroup.features,
        }
      }
    })
  }
  return data
}

export const getSelectedYears = (years, mapeoType) => {
  const data = []
  if (mapeoType === 'showingFoldersArchivo') {
    years.forEach(year => {
      if (year.selected) {
        data.push(year.value)
      }
    })
  } else {
    data.push(new Date().getFullYear())
  }
  return data
}

export const invertKMLCoords = (data, type) => {
  const areasFeatures = data.map(feature => {
    if (feature.geometry.type === 'MultiPolygon') {
      return feature
    }
    return {
      type: 'Feature',
      properties: feature.properties,
      geometry: {
        type,
        coordinates:
          type !== 'Point'
            ? [
                // INVCOORDS UNIQUE
                feature.geometry.coordinates.map(coord => [coord[1], coord[0], 0]),
              ]
            : feature.geometry.coordinates,
      },
    }
  })

  return areasFeatures
}

export const sortKMLFeaturesByArea = data => {
  const newDataHa = []
  const newDataM2 = []

  data.forEach(feature => {
    const unit = feature.properties.area.split(' ')[1]
    if (unit === 'ha') {
      newDataHa.push(feature)
    }
    if (unit === 'm²') {
      newDataM2.push(feature)
    }
  })

  newDataHa.sort((a, b) => {
    const aArea = parseFloat(a.properties.area.split(' ')[0])
    const bArea = parseFloat(b.properties.area.split(' ')[0])
    return bArea - aArea
  })

  newDataM2.sort((a, b) => {
    const aArea = parseFloat(a.properties.area.split(' ')[0])
    const bArea = parseFloat(b.properties.area.split(' ')[0])
    return bArea - aArea
  })

  const newData = newDataHa.concat(newDataM2)

  return newData
}
