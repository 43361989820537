// Material UI
import { Link } from '@mui/material'

const LinkWrapper = ({ formField }) => {
  return (
    <Link
      fontSize={formField?.fontSize ? formField?.fontSize : 'default'}
      href={formField.url}
      target="_blank"
      variant="body2"
    >
      {formField.label}
    </Link>
  )
}

export default LinkWrapper
