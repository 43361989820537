import ConfirmationModal from './ConfirmationModal'

const DirtyForm = ({ open, handleClose, handleConfirm, buttonValue }) => {
  return (
    <ConfirmationModal
      buttonValue={buttonValue}
      cancelLabel="Cancelar"
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      message="Si se sale del formulario perderá los cambios parciales realizados. ¿Desea continuar?"
      open={open}
      saveLabel="Continuar"
      title="Cambios parciales"
    />
  )
}

export default DirtyForm
