// Proyectar un config file
export const dataLayersAreas = [
  {
    label: 'Estratos',
    farmLayerType: 'estratas',
    color: 'green',
  },
  {
    label: 'Perímetro',
    farmLayerType: 'perimeter',
    color: 'yellow',
  },
  {
    label: 'Lotes',
    farmLayerType: 'paddocks',
    color: 'purple',
  },
  {
    label: 'Zonas de exclusión',
    farmLayerType: 'exclusionAreas',
    color: 'black',
  },
  {
    label: 'Otras áreas',
    farmLayerType: 'otherPolygons',
    color: 'pink',
  },
  {
    label: 'Areas sin asignar',
    farmLayerType: 'unassigned',
    color: 'red',
  },
]

// Proyectar un config file
export const dataLayersPoints = [
  {
    label: 'Sitios de monitoreo',
    farmLayerType: 'monitoringSites',
    color: 'white',
  },
  {
    label: 'Otros sitios',
    farmLayerType: 'otherSites',
    color: 'orange',
  },
  {
    label: 'Puntos sin asignar',
    farmLayerType: 'unassignedPoints',
    color: 'red',
  },
]
