import { useState, useContext } from 'react'

import { Box, Button, Grid, LinearProgress, Modal, Typography, TextField } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import RateReviewIcon from '@mui/icons-material/RateReview'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import ErrorIcon from '@mui/icons-material/Error'

import AppContext from '../../context/appContext'
import { openSupportForm } from '../../utils/Errors/errors'
import { messages } from '../../utils/messages/index'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}

const modalIconStyle = {
  position: 'absolute',
  top: -30,
  left: '50%',
  transform: 'translateX(-50%)',
  fontSize: 60,
  backgroundColor: 'white',
  color: '#ff9800',
  padding: 1,
  borderRadius: '50%',
}

const waringColor = '#d32f2f'
export const helpColor = '#ff9800'

export const ModalTypes = {
  QuestionModalType: 0,
  DeleteModalType: 1,
  ContactSupportModalType: 2,
  ErrorModelType: 3,
}

const QuestionConfirmationModal = ({
  title,
  message,
  open,
  handleClose,
  handleConfirm,
  buttonValue,
  confirmLabel,
  cancelLabel,
  loading = false,
}) => {
  async function handleConfirmation() {
    await handleConfirm()
    handleClose()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <RateReviewIcon sx={modalIconStyle} />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            margin: 2,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            margin: 2,
          }}
        >
          {message}
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <Button
              sx={{
                backgroundColor: '#1976d2',
                color: 'white',
                borderRadius: 4,
                margin: 2,
                px: 2,
                fontWeight: 600,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#1976d2',
                  color: 'white',
                  opacity: 0.8,
                },
              }}
              value={buttonValue}
              onClick={() => handleConfirmation()}
            >
              {confirmLabel ?? 'Confirmar'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{
                color: '#1976d2',
                backgroundColor: 'white',
                margin: 2,
                px: 2,
                fontWeight: 600,
                textTransform: 'none',
              }}
              onClick={handleClose}
            >
              {cancelLabel ?? 'Cancelar'}
            </Button>
          </Grid>
        </Grid>
        <LinearProgress
          sx={{
            display: loading ? 'block' : 'none',
          }}
        />
      </Box>
    </Modal>
  )
}

const DeleteConfirmationModal = ({
  title,
  message,
  open,
  handleClose,
  handleConfirm,
  buttonValue,
  cancelLabel,
  confirmLabel,
  loading = false,
  requiredTextField = null,
  children,
}) => {
  const [inputValue, setInputValue] = useState(null)
  const [confirmDisabled, setConfirmDisabled] = useState(requiredTextField !== null)

  async function handleConfirmation() {
    await handleConfirm(inputValue)
    handleClose()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <WarningIcon sx={modalIconStyle} />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            margin: 2,
            color: waringColor,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            margin: 2,
          }}
        >
          {message}
        </Typography>
        <Grid container>
          {children && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
          {requiredTextField && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                required
                placeholder={requiredTextField.placeHolder}
                rows={2}
                onChange={e => {
                  setInputValue(e.target.value)
                  setConfirmDisabled(e.target.value.length < 1)
                }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Button
              disabled={confirmDisabled}
              sx={{
                backgroundColor: waringColor,
                color: 'white',
                borederRadius: 4,
                margin: 2,
                px: 2,
                fontWeight: 600,
                opacity: 0.6,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: waringColor,
                  color: 'white',
                  opacity: 1,
                },
              }}
              value={buttonValue}
              onClick={() => handleConfirmation()}
            >
              {confirmLabel ?? 'Borrar'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{
                color: '#1976d2',
                backgroundColor: 'white',
                margin: 2,
                px: 2,
                fontWeight: 600,
                textTransform: 'none',
              }}
              onClick={handleClose}
            >
              {cancelLabel ?? 'Cancelar'}
            </Button>
          </Grid>
        </Grid>
        <LinearProgress
          sx={{
            display: loading ? 'block' : 'none',
          }}
        />
      </Box>
    </Modal>
  )
}

const ContactSupportModal = ({
  title,
  detail,
  error,
  message,
  open,
  handleClose,
  buttonValue,
  loading = false,
}) => {
  const { user, currentFarm } = useContext(AppContext)

  async function handleConfirmation() {
    openSupportForm({ user, farm: currentFarm, title, reason: message, error })
    handleClose()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <HelpCenterIcon sx={modalIconStyle} />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            margin: 2,
          }}
        >
          {title}
        </Typography>
        {error && !error.unexpected && (
          <Typography
            sx={{
              fontSize: 16,
              margin: 2,
            }}
          >
            {message}
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: 16,
          }}
        >
          {detail ?? messages.getMessage(messages.keys.ERROR_CONTACT_SUPPORT_DETAIL)}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Button
              sx={{
                backgroundColor: helpColor,
                color: 'white',
                borderRadius: 4,
                margin: 2,
                px: 2,
                fontWeight: 600,
                opacity: 0.6,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: helpColor,
                  color: 'white',
                  opacity: 1,
                },
              }}
              value={buttonValue}
              onClick={() => handleConfirmation()}
            >
              {messages.getMessage(messages.keys.FILL_FORM_SUPPORT_CONTACT_BUTTON)}
            </Button>
          </Grid>
        </Grid>
        <LinearProgress
          sx={{
            display: loading ? 'block' : 'none',
          }}
        />
      </Box>
    </Modal>
  )
}

const ErrorModalTypeModal = ({
  title,
  error,
  message,
  open,
  handleClose,
  buttonValue,
  loading = false,
}) => {
  const { user, currentFarm } = useContext(AppContext)

  async function handleConfirmation() {
    openSupportForm({ user, farm: currentFarm, title, reason: message, error })
    handleClose()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <ErrorIcon sx={modalIconStyle} />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            margin: 2,
          }}
        >
          {title}
        </Typography>
        {error && (
          <Typography
            sx={{
              fontSize: 16,
              margin: 2,
            }}
          >
            {error.unexpected ? error.message : message}
          </Typography>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Button
              sx={{
                backgroundColor: helpColor,
                color: 'white',
                borederRadius: 4,
                margin: 2,
                px: 2,
                fontWeight: 600,
                opacity: 0.6,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: helpColor,
                  color: 'white',
                  opacity: 1,
                },
              }}
              value={buttonValue}
              onClick={() => handleConfirmation()}
            >
              {messages.getMessage(messages.keys.ERROR_FORM_BUTTON)}
            </Button>
          </Grid>
        </Grid>
        <LinearProgress
          sx={{
            display: loading ? 'block' : 'none',
          }}
        />
      </Box>
    </Modal>
  )
}

const GlobalConfirmationModal = ({
  title,
  detail,
  error,
  message,
  open,
  handleClose,
  handleConfirm,
  buttonValue,
  confirmLabel,
  cancelLabel,
  loading = false,
  modalType = ModalTypes.QuestionModalType,
  requiredTextField = null,
  children,
}) => {
  switch (modalType) {
    case ModalTypes.ContactSupportModalType:
      return ContactSupportModal({
        title,
        detail,
        message,
        error,
        open,
        handleClose,
        handleConfirm,
        buttonValue,
        cancelLabel,
        loading,
      })
    case ModalTypes.DeleteModalType:
      return DeleteConfirmationModal({
        title,
        message,
        open,
        handleClose,
        handleConfirm,
        buttonValue,
        confirmLabel,
        cancelLabel,
        loading,
        requiredTextField,
        children,
      })
    case ModalTypes.ErrorModelType:
      return ErrorModalTypeModal({
        title,
        detail,
        message,
        error,
        open,
        handleClose,
        handleConfirm,
        buttonValue,
        cancelLabel,
        loading,
      })
    case ModalTypes.QuestionModalType:
    default:
      return QuestionConfirmationModal({
        title,
        message,
        open,
        handleClose,
        handleConfirm,
        buttonValue,
        confirmLabel,
        cancelLabel,
        loading,
      })
  }
}

export default GlobalConfirmationModal
