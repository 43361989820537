import { Grid, Typography, Box, Modal } from '@mui/material'
import { Close } from '@mui/icons-material'
import FindingAccordions from './FindingAccordions'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  textAlign: 'center',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 1,
}

const FindingModalVisualization = ({
  findings,
  openModal,
  closeModal,
  readOnly,
  handleMetricReset,
}) => {
  return (
    <Modal height="50%" open={openModal} width="50%" onClose={closeModal}>
      <Box sx={modalStyle}>
        <Grid container pt={0.5}>
          <Grid item xs={10}>
            <Typography mb={0.5} pl={8} variant="h6">
              Observaciones
            </Typography>
          </Grid>
          <Grid item px={1} xs={2}>
            <Close
              sx={{
                float: 'right',
                cursor: 'pointer',
                '&:hover': {
                  color: 'red',
                },
              }}
              onClick={closeModal}
            />
          </Grid>
        </Grid>
        {findings.length > 0 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              overflow: 'scroll',
              height: '100%',
            }}
          >
            <FindingAccordions
              findings={findings}
              handleMetricReset={handleMetricReset}
              readOnly={readOnly}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              flexDirection: 'column',
              padding: 1,
              margin: 1,
            }}
          >
            <Typography color="gray" my={2} variant="h4">
              No hay observaciones
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default FindingModalVisualization
