import BlockIcon from '@mui/icons-material/Block'
import EditIcon from '@mui/icons-material/Edit'
import { Tooltip } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext.js'
import { localRefData } from '../../services/localRefData/index'
import { errors } from '../../services/ruutsApi/errors.js'
import { ruutsApi } from '../../services/ruutsApi/index.js'
import { metricEventStatus } from '../../utils/constants.js'
import { useProcessLoadingWithConfirmation } from '../../utils/Loading/useProcessLoadingWithConfirmation.js'
import { messages } from '../../utils/messages/index.js'
import { userRoles } from '../../utils/userRoles.js'

const statusesTransition = {
  [metricEventStatus.Open]: metricEventStatus.Close,
  [metricEventStatus.Close]: metricEventStatus.Open,
}

export const MetricEventStatusRowData = ({ metricId, statusId, handleMetricReset }) => {
  const { userRoleSelected } = useContext(AppContext)
  const [statuses, setStatuses] = useState(null)
  const [tooltipTitle, setTooltipTitle] = useState(null)
  const { processLoadingWithConfirmation } = useProcessLoadingWithConfirmation()

  useEffect(() => {
    async function fetchStatus() {
      const newStatuses = await localRefData.getDataByKey(localRefData.keys.metricEventStatus)
      setStatuses(newStatuses)
    }
    fetchStatus()
  }, [])

  useEffect(() => {
    if (statusId === null || !statuses) return

    const status = statuses.find(s => s.id === statusId)
    setTooltipTitle(status?.[messages.getLangKey()] || '')
  }, [statuses, statusId])

  const { cursor, handleClick } =
    userRoleSelected === userRoles.Admin
      ? {
          cursor: 'pointer',
          handleClick: async id => {
            await processLoadingWithConfirmation({
              confirmationTitle: 'Confirmar cambio de estado',
              confirmationMessage: `¿Está seguro que desea ${statusId === metricEventStatus.Open ? 'cerrar' : 'abrir'} la métrica?`,
              loadingMessage: 'Cambiando estado...',
              errorMessage: 'Error al cambiar el estado de la métrica.',
              doAction: async ({ token, dryRun }) => {
                if (dryRun) return

                const newMetricEventStatus = statusesTransition[statusId]
                if (newMetricEventStatus === undefined) {
                  throw new errors.BusinessEntityError({
                    message: 'Estado de métrica desconocida',
                    request: {
                      statusId,
                      metricId,
                    },
                  })
                }

                await ruutsApi.saveMetricEventStatus({
                  metricEventId: id,
                  metricEventStatusId: newMetricEventStatus,
                  token,
                })
                handleMetricReset()
              },
            })
          },
        }
      : { cursor: 'default', handleClick: () => {} }

  return (
    <Tooltip title={tooltipTitle}>
      {statusId === metricEventStatus.Open ? (
        <EditIcon style={{ color: 'green', cursor }} onClick={() => handleClick(metricId)} />
      ) : (
        <BlockIcon style={{ color: 'red', cursor }} onClick={() => handleClick(metricId)} />
      )}
    </Tooltip>
  )
}
