import { LayersControl, TileLayer } from 'react-leaflet'

const BaseLayers = () => {
  return (
    <>
      <LayersControl.BaseLayer checked name="Híbrido">
        <TileLayer
          maxNativeZoom={19}
          maxZoom={22}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          url="http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Satélite">
        <TileLayer
          maxNativeZoom={19}
          maxZoom={22}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Elevación">
        <TileLayer
          maxNativeZoom={19}
          maxZoom={22}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="OSM">
        <TileLayer
          maxNativeZoom={19}
          maxZoom={22}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Mapbox Satellite">
        <TileLayer
          maxNativeZoom={19}
          maxZoom={22}
          url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoicnV1dHMtbGEiLCJhIjoiY2xjdGR2a3ZqMHBocDN2cHNmbHN2am9nbSJ9.O-_YjVHUBZboonboQd19zA"
        />
      </LayersControl.BaseLayer>
    </>
  )
}

export default BaseLayers
