/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'

const DrawingPencil = props => {
  return (
    <SvgIcon
      fill="none"
      height="116"
      viewBox="0 0 90 116"
      width="90"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{
        width: 90,
        height: 116,
        '& .MuiSvgIcon-root': {
          width: '90px',
          height: '116px',
        },
      }}
    >
      <g clipPath="url(#clip0_542_48621)">
        <ellipse
          cx="10.5256"
          cy="29.9557"
          fill="#8ED3D8"
          fillOpacity="0.63"
          rx="6.68383"
          ry="6.65683"
        />
        <ellipse
          cx="79.2737"
          cy="30.9068"
          fill="#8ED3D8"
          fillOpacity="0.63"
          rx="6.68383"
          ry="6.65683"
        />
        <ellipse
          cx="81.6604"
          cy="101.279"
          fill="#8ED3D8"
          fillOpacity="0.63"
          rx="6.68383"
          ry="6.65683"
        />
        <ellipse
          cx="8.13891"
          cy="102.23"
          fill="#8ED3D8"
          fillOpacity="0.63"
          rx="6.68383"
          ry="6.65683"
        />
        <rect fill="#83B1DC" height="63.7154" rx="3" width="20.5289" x="37.2617" y="29.0049" />
        <path
          d="M81.4858 101.526H78.5401C78.2793 101.526 78.0291 101.423 77.8447 101.239C77.6603 101.055 77.5566 100.806 77.5566 100.547C77.5566 100.287 77.6603 100.038 77.8447 99.8539C78.0291 99.6703 78.2793 99.5671 78.5401 99.5671H80.5023V97.6128C80.5023 97.353 80.6059 97.1039 80.7904 96.9202C80.9748 96.7365 81.2249 96.6333 81.4858 96.6333C81.7466 96.6333 81.9968 96.7365 82.1812 96.9202C82.3656 97.1039 82.4693 97.353 82.4693 97.6128V100.547C82.4693 100.675 82.4438 100.803 82.3944 100.921C82.345 101.04 82.2725 101.148 82.1812 101.239C82.0899 101.33 81.9815 101.402 81.8621 101.452C81.7428 101.501 81.6149 101.526 81.4858 101.526Z"
          fill="black"
        />
        <path
          d="M66.4898 101.526H60.4696C60.2088 101.526 59.9586 101.423 59.7742 101.239C59.5898 101.056 59.4861 100.807 59.4861 100.547C59.4861 100.287 59.5898 100.038 59.7742 99.8543C59.9586 99.6706 60.2088 99.5674 60.4696 99.5674H66.4898C66.7507 99.5674 67.0008 99.6706 67.1853 99.8543C67.3697 100.038 67.4733 100.287 67.4733 100.547C67.4733 100.807 67.3697 101.056 67.1853 101.239C67.0008 101.423 66.7507 101.526 66.4898 101.526ZM48.4244 101.526H42.4042C42.1433 101.526 41.8932 101.423 41.7088 101.239C41.5243 101.056 41.4207 100.807 41.4207 100.547C41.4207 100.287 41.5243 100.038 41.7088 99.8543C41.8932 99.6706 42.1433 99.5674 42.4042 99.5674H48.4292C48.69 99.5674 48.9402 99.6706 49.1246 99.8543C49.309 100.038 49.4126 100.287 49.4126 100.547C49.4126 100.807 49.309 101.056 49.1246 101.239C48.9402 101.423 48.69 101.526 48.4292 101.526H48.4244ZM30.3542 101.526H24.3292C24.0683 101.526 23.8182 101.423 23.6338 101.239C23.4493 101.056 23.3457 100.807 23.3457 100.547C23.3457 100.287 23.4493 100.038 23.6338 99.8543C23.8182 99.6706 24.0683 99.5674 24.3292 99.5674H30.3542C30.615 99.5674 30.8652 99.6706 31.0496 99.8543C31.234 100.038 31.3377 100.287 31.3377 100.547C31.3377 100.807 31.234 101.056 31.0496 101.239C30.8652 101.423 30.615 101.526 30.3542 101.526Z"
          fill="black"
        />
        <path
          d="M12.2842 101.526H9.34329C9.08245 101.526 8.8323 101.423 8.64786 101.24C8.46342 101.056 8.35981 100.807 8.35981 100.547V97.6131C8.35041 97.4791 8.36881 97.3446 8.41386 97.2179C8.45891 97.0912 8.52965 96.9751 8.62167 96.8769C8.71369 96.7786 8.82503 96.7002 8.94877 96.6467C9.0725 96.5931 9.20598 96.5654 9.3409 96.5654C9.47582 96.5654 9.6093 96.5931 9.73303 96.6467C9.85676 96.7002 9.96811 96.7786 10.0601 96.8769C10.1522 96.9751 10.2229 97.0912 10.2679 97.2179C10.313 97.3446 10.3314 97.4791 10.322 97.6131V99.5674H12.2842C12.545 99.5674 12.7952 99.6706 12.9796 99.8543C13.164 100.038 13.2677 100.287 13.2677 100.547C13.2677 100.807 13.164 101.056 12.9796 101.24C12.7952 101.423 12.545 101.526 12.2842 101.526Z"
          fill="black"
        />
        <path
          d="M9.34329 86.5909C9.21347 86.5948 9.08422 86.5723 8.96343 86.5248C8.84264 86.4773 8.73286 86.4057 8.64081 86.3144C8.54875 86.2232 8.47635 86.1142 8.42803 85.9942C8.37972 85.8741 8.3565 85.7455 8.35981 85.6162V79.6155C8.37715 79.3681 8.48804 79.1365 8.67012 78.9674C8.85221 78.7983 9.09192 78.7042 9.3409 78.7042C9.58988 78.7042 9.82959 78.7983 10.0117 78.9674C10.1938 79.1365 10.3047 79.3681 10.322 79.6155V85.6162C10.3253 85.7451 10.3022 85.8733 10.2542 85.993C10.2061 86.1127 10.1342 86.2215 10.0426 86.3127C9.95109 86.4038 9.84189 86.4755 9.72166 86.5234C9.60144 86.5712 9.47271 86.5942 9.34329 86.5909ZM9.34329 68.5984C9.08245 68.5984 8.8323 68.4953 8.64786 68.3116C8.46342 68.1279 8.35981 67.8787 8.35981 67.6189V61.6183C8.37715 61.3709 8.48804 61.1393 8.67012 60.9702C8.85221 60.801 9.09192 60.707 9.3409 60.707C9.58988 60.707 9.82959 60.801 10.0117 60.9702C10.1938 61.1393 10.3047 61.3709 10.322 61.6183V67.6189C10.3226 67.7474 10.2978 67.8746 10.2489 67.9934C10.2 68.1122 10.128 68.2203 10.037 68.3113C9.94608 68.4023 9.83798 68.4745 9.71893 68.5238C9.59987 68.5731 9.47221 68.5984 9.34329 68.5984ZM9.34329 50.6012C9.08245 50.6012 8.8323 50.498 8.64786 50.3144C8.46342 50.1307 8.35981 49.8815 8.35981 49.6217V43.6258C8.35041 43.4918 8.36881 43.3572 8.41386 43.2306C8.45891 43.1039 8.52965 42.9878 8.62167 42.8896C8.71369 42.7913 8.82503 42.7129 8.94877 42.6594C9.0725 42.6058 9.20598 42.5781 9.3409 42.5781C9.47582 42.5781 9.6093 42.6058 9.73303 42.6594C9.85676 42.7129 9.96811 42.7913 10.0601 42.8896C10.1522 42.9878 10.2229 43.1039 10.2679 43.2306C10.313 43.3572 10.3314 43.4918 10.322 43.6258V49.6217C10.3226 49.7502 10.2978 49.8774 10.2489 49.9962C10.2 50.115 10.128 50.223 10.037 50.3141C9.94608 50.4051 9.83798 50.4773 9.71893 50.5266C9.59987 50.5759 9.47221 50.6012 9.34329 50.6012Z"
          fill="black"
        />
        <path
          d="M9.34285 32.6043C9.08202 32.6043 8.83186 32.5011 8.64743 32.3174C8.46299 32.1337 8.35938 31.8845 8.35938 31.6247V28.6957C8.35938 28.436 8.46299 28.1868 8.64743 28.0031C8.83186 27.8194 9.08202 27.7162 9.34285 27.7162H12.2837C12.4183 27.7069 12.5534 27.7252 12.6806 27.7701C12.8078 27.8149 12.9243 27.8854 13.023 27.977C13.1217 28.0687 13.2003 28.1796 13.2541 28.3028C13.3079 28.4261 13.3357 28.559 13.3357 28.6934C13.3357 28.8277 13.3079 28.9607 13.2541 29.0839C13.2003 29.2072 13.1217 29.318 13.023 29.4097C12.9243 29.5013 12.8078 29.5718 12.6806 29.6167C12.5534 29.6615 12.4183 29.6798 12.2837 29.6705H10.3216V31.6247C10.3222 31.7532 10.2973 31.8804 10.2484 31.9992C10.1995 32.118 10.1275 32.2261 10.0366 32.3171C9.94565 32.4081 9.83755 32.4803 9.71849 32.5296C9.59944 32.5789 9.47178 32.6043 9.34285 32.6043Z"
          fill="black"
        />
        <path
          d="M66.4945 29.6705H60.4695C60.3349 29.6798 60.1998 29.6615 60.0726 29.6167C59.9455 29.5718 59.8289 29.5013 59.7302 29.4097C59.6316 29.318 59.5529 29.2072 59.4991 29.0839C59.4453 28.9607 59.4175 28.8277 59.4175 28.6934C59.4175 28.559 59.4453 28.4261 59.4991 28.3028C59.5529 28.1796 59.6316 28.0687 59.7302 27.977C59.8289 27.8854 59.9455 27.8149 60.0726 27.7701C60.1998 27.7252 60.3349 27.7069 60.4695 27.7162H66.4945C66.7429 27.7335 66.9754 27.844 67.1452 28.0253C67.3151 28.2066 67.4095 28.4454 67.4095 28.6934C67.4095 28.9413 67.3151 29.1801 67.1452 29.3614C66.9754 29.5428 66.7429 29.6532 66.4945 29.6705ZM48.4243 29.6705H42.4041C42.2695 29.6798 42.1344 29.6615 42.0072 29.6167C41.88 29.5718 41.7635 29.5013 41.6648 29.4097C41.5661 29.318 41.4874 29.2072 41.4337 29.0839C41.3799 28.9607 41.3521 28.8277 41.3521 28.6934C41.3521 28.559 41.3799 28.4261 41.4337 28.3028C41.4874 28.1796 41.5661 28.0687 41.6648 27.977C41.7635 27.8854 41.88 27.8149 42.0072 27.7701C42.1344 27.7252 42.2695 27.7069 42.4041 27.7162H48.429C48.6774 27.7335 48.91 27.844 49.0798 28.0253C49.2496 28.2066 49.344 28.4454 49.344 28.6934C49.344 28.9413 49.2496 29.1801 49.0798 29.3614C48.91 29.5428 48.6774 29.6532 48.429 29.6705H48.4243ZM30.3541 29.6705H24.3291C24.0807 29.6532 23.8481 29.5428 23.6783 29.3614C23.5085 29.1801 23.4141 28.9413 23.4141 28.6934C23.4141 28.4454 23.5085 28.2066 23.6783 28.0253C23.8481 27.844 24.0807 27.7335 24.3291 27.7162H30.3541C30.4887 27.7069 30.6237 27.7252 30.7509 27.7701C30.8781 27.8149 30.9946 27.8854 31.0933 27.977C31.192 28.0687 31.2707 28.1796 31.3245 28.3028C31.3783 28.4261 31.406 28.559 31.406 28.6934C31.406 28.8277 31.3783 28.9607 31.3245 29.0839C31.2707 29.2072 31.192 29.318 31.0933 29.4097C30.9946 29.5013 30.8781 29.5718 30.7509 29.6167C30.6237 29.6615 30.4887 29.6798 30.3541 29.6705Z"
          fill="black"
        />
        <path
          d="M81.4859 32.6043C81.2251 32.6043 80.9749 32.5011 80.7905 32.3174C80.606 32.1337 80.5024 31.8845 80.5024 31.6247V29.6705H78.5402C78.4056 29.6798 78.2706 29.6615 78.1434 29.6167C78.0162 29.5718 77.8997 29.5013 77.801 29.4097C77.7023 29.318 77.6236 29.2072 77.5698 29.0839C77.516 28.9607 77.4883 28.8277 77.4883 28.6934C77.4883 28.559 77.516 28.4261 77.5698 28.3028C77.6236 28.1796 77.7023 28.0687 77.801 27.977C77.8997 27.8854 78.0162 27.8149 78.1434 27.7701C78.2706 27.7252 78.4056 27.7069 78.5402 27.7162H81.4859C81.7467 27.7162 81.9969 27.8194 82.1813 28.0031C82.3658 28.1868 82.4694 28.436 82.4694 28.6957V31.6247C82.4694 31.8845 82.3658 32.1337 82.1813 32.3174C81.9969 32.5011 81.7467 32.6043 81.4859 32.6043Z"
          fill="black"
        />
        <path
          d="M81.4857 86.5911C81.3559 86.595 81.2267 86.5725 81.1059 86.525C80.9851 86.4774 80.8753 86.4058 80.7833 86.3146C80.6912 86.2234 80.6188 86.1144 80.5705 85.9943C80.5222 85.8742 80.499 85.7456 80.5023 85.6163V79.6157C80.5023 79.3559 80.6059 79.1067 80.7903 78.923C80.9748 78.7394 81.2249 78.6362 81.4857 78.6362C81.7466 78.6362 81.9967 78.7394 82.1812 78.923C82.3656 79.1067 82.4692 79.3559 82.4692 79.6157V85.6163C82.4725 85.7456 82.4493 85.8742 82.401 85.9943C82.3527 86.1144 82.2803 86.2234 82.1882 86.3146C82.0962 86.4058 81.9864 86.4774 81.8656 86.525C81.7448 86.5725 81.6156 86.595 81.4857 86.5911ZM81.4857 68.5986C81.2249 68.5986 80.9748 68.4954 80.7903 68.3117C80.6059 68.128 80.5023 67.8789 80.5023 67.6191V61.6232C80.5023 61.3634 80.6059 61.1143 80.7903 60.9306C80.9748 60.7469 81.2249 60.6437 81.4857 60.6437C81.7466 60.6437 81.9967 60.7469 82.1812 60.9306C82.3656 61.1143 82.4692 61.3634 82.4692 61.6232V67.6191C82.4692 67.8789 82.3656 68.128 82.1812 68.3117C81.9967 68.4954 81.7466 68.5986 81.4857 68.5986ZM81.4857 50.6014C81.2249 50.6014 80.9748 50.4982 80.7903 50.3145C80.6059 50.1308 80.5023 49.8817 80.5023 49.6219V43.626C80.5023 43.3662 80.6059 43.1171 80.7903 42.9334C80.9748 42.7497 81.2249 42.6465 81.4857 42.6465C81.7466 42.6465 81.9967 42.7497 82.1812 42.9334C82.3656 43.1171 82.4692 43.3662 82.4692 43.626V49.6219C82.4692 49.8817 82.3656 50.1308 82.1812 50.3145C81.9967 50.4982 81.7466 50.6014 81.4857 50.6014Z"
          fill="black"
        />
        <path
          d="M9.34263 37.4968C7.59349 37.4978 5.88336 36.982 4.42861 36.0147C2.97386 35.0475 1.83985 33.6722 1.17005 32.0629C0.500247 30.4536 0.324748 28.6826 0.665757 26.974C1.00677 25.2654 1.84896 23.6958 3.08579 22.464C4.32262 21.2322 5.8985 20.3934 7.61408 20.0537C9.32966 19.7141 11.1078 19.8889 12.7236 20.556C14.3395 21.2231 15.7203 22.3525 16.6915 23.8014C17.6627 25.2503 18.1806 26.9535 18.1796 28.6956C18.1771 31.029 17.2452 33.2662 15.5885 34.9162C13.9318 36.5662 11.6856 37.4943 9.34263 37.4968ZM9.34263 21.8485C7.98292 21.8485 6.65375 22.2501 5.52319 23.0025C4.39264 23.7548 3.51148 24.8242 2.99114 26.0753C2.4708 27.3264 2.33466 28.7032 2.59993 30.0313C2.86519 31.3595 3.51995 32.5796 4.48141 33.5371C5.44287 34.4947 6.66784 35.1468 8.00142 35.411C9.335 35.6752 10.7173 35.5396 11.9735 35.0214C13.2297 34.5031 14.3034 33.6256 15.0588 32.4996C15.8142 31.3736 16.2174 30.0498 16.2174 28.6956C16.2136 26.8817 15.4878 25.1433 14.1991 23.8615C12.9103 22.5798 11.1639 21.8593 9.34263 21.858V21.8485Z"
          fill="black"
        />
        <path
          d="M80.659 37.4968C78.9099 37.4978 77.1998 36.982 75.745 36.0147C74.2903 35.0475 73.1563 33.6722 72.4865 32.0629C71.8167 30.4536 71.6412 28.6826 71.9822 26.974C72.3232 25.2654 73.1654 23.6958 74.4022 22.464C75.639 21.2322 77.2149 20.3934 78.9305 20.0537C80.6461 19.7141 82.4242 19.8889 84.0401 20.556C85.6559 21.2231 87.0367 22.3525 88.0079 23.8014C88.9791 25.2503 89.497 26.9535 89.496 28.6956C89.491 31.0283 88.5583 33.264 86.9022 34.9135C85.246 36.5629 83.0012 37.4918 80.659 37.4968ZM80.659 21.8485C79.2993 21.8485 77.9702 22.2501 76.8396 23.0025C75.709 23.7548 74.8279 24.8242 74.3076 26.0753C73.7872 27.3264 73.6511 28.7032 73.9163 30.0313C74.1816 31.3595 74.8364 32.5796 75.7978 33.5371C76.7593 34.4947 77.9842 35.1468 79.3178 35.411C80.6514 35.6752 82.0337 35.5396 83.2899 35.0214C84.5461 34.5031 85.6198 33.6256 86.3752 32.4996C87.1306 31.3736 87.5338 30.0498 87.5338 28.6956C87.53 26.8817 86.8042 25.1433 85.5155 23.8615C84.2268 22.5798 82.4803 21.8593 80.659 21.858V21.8485Z"
          fill="black"
        />
        <path
          d="M9.34263 109.348C7.59349 109.349 5.88336 108.833 4.42861 107.866C2.97386 106.899 1.83985 105.523 1.17005 103.914C0.500247 102.305 0.324748 100.534 0.665757 98.8251C1.00677 97.1164 1.84896 95.5469 3.08579 94.3151C4.32262 93.0832 5.8985 92.2444 7.61408 91.9048C9.32966 91.5652 11.1078 91.74 12.7236 92.4071C14.3395 93.0742 15.7203 94.2036 16.6915 95.6525C17.6627 97.1013 18.1806 98.8046 18.1796 100.547C18.1771 102.88 17.2452 105.117 15.5885 106.767C13.9318 108.417 11.6856 109.345 9.34263 109.348ZM9.34263 93.6948C7.98177 93.6939 6.65121 94.095 5.51924 94.8473C4.38727 95.5996 3.50476 96.6694 2.98333 97.9213C2.4619 99.1732 2.32498 100.551 2.58989 101.88C2.8548 103.21 3.50964 104.431 4.47157 105.39C5.43351 106.349 6.65932 107.002 7.99396 107.266C9.3286 107.531 10.7121 107.396 11.9694 106.877C13.2268 106.359 14.3015 105.481 15.0577 104.354C15.8138 103.227 16.2174 101.902 16.2174 100.547C16.2149 98.731 15.4899 96.9904 14.2013 95.7062C12.9128 94.4219 11.1656 93.6986 9.34263 93.6948Z"
          fill="black"
        />
        <path
          d="M80.659 109.348C78.9099 109.349 77.1998 108.833 75.745 107.866C74.2903 106.899 73.1563 105.523 72.4865 103.914C71.8167 102.305 71.6412 100.534 71.9822 98.8251C72.3232 97.1164 73.1654 95.5469 74.4022 94.3151C75.639 93.0832 77.2149 92.2444 78.9305 91.9048C80.6461 91.5652 82.4242 91.74 84.0401 92.4071C85.6559 93.0742 87.0367 94.2036 88.0079 95.6525C88.9791 97.1013 89.497 98.8046 89.496 100.547C89.4922 102.88 88.56 105.116 86.9035 106.766C85.2471 108.416 83.0016 109.344 80.659 109.348ZM80.659 93.6948C79.2982 93.6939 77.9676 94.095 76.8356 94.8473C75.7037 95.5996 74.8212 96.6694 74.2997 97.9213C73.7783 99.1732 73.6414 100.551 73.9063 101.88C74.1712 103.21 74.826 104.431 75.788 105.39C76.7499 106.349 77.9757 107.002 79.3104 107.266C80.645 107.531 82.0285 107.396 83.2859 106.877C84.5432 106.359 85.6179 105.481 86.3741 104.354C87.1302 103.227 87.5338 101.902 87.5338 100.547C87.5313 98.731 86.8063 96.9904 85.5177 95.7062C84.2292 94.4219 82.482 93.6986 80.659 93.6948Z"
          fill="black"
        />
        <path
          d="M53.971 25.1721L55.2457 21.8389L53.971 18.4392L55.2457 15.8335H35.5762L36.8509 18.4392L35.5762 21.8389L36.8509 25.1721L35.5762 27.8396H55.2457L53.971 25.1721Z"
          fill="white"
        />
        <path
          d="M55.2451 28.8193H35.5708C35.4034 28.8158 35.2396 28.7704 35.0944 28.6873C34.9492 28.6042 34.8274 28.4861 34.7401 28.3438C34.6504 28.203 34.5985 28.0416 34.5893 27.8751C34.5801 27.7086 34.6139 27.5426 34.6876 27.3928L35.7809 25.101L34.6542 22.1577C34.5663 21.9346 34.5663 21.6866 34.6542 21.4635L35.7809 18.4631L34.6923 16.2331C34.6153 16.0835 34.5783 15.9167 34.5847 15.7488C34.5911 15.5808 34.6409 15.4173 34.7291 15.274C34.8173 15.1307 34.9411 15.0125 35.0885 14.9306C35.2359 14.8488 35.402 14.8061 35.5708 14.8066H55.2451C55.4128 14.8088 55.5772 14.8536 55.7226 14.9368C55.8679 15.0201 55.9896 15.1389 56.0758 15.2821C56.167 15.4225 56.2194 15.5843 56.2278 15.7512C56.2362 15.9182 56.2002 16.0844 56.1236 16.2331L55.0351 18.4631L56.1618 21.4635C56.2496 21.6866 56.2496 21.9346 56.1618 22.1577L55.0351 25.101L56.1283 27.3928C56.202 27.5426 56.2358 27.7086 56.2267 27.8751C56.2175 28.0416 56.1655 28.203 56.0758 28.3438C55.9885 28.4861 55.8667 28.6042 55.7215 28.6873C55.5764 28.7704 55.4125 28.8158 55.2451 28.8193ZM37.1033 26.8603H53.6649L53.0586 25.5907C52.9993 25.4718 52.9657 25.3418 52.96 25.2091C52.9542 25.0764 52.9764 24.944 53.0251 24.8204L54.1709 21.8344L53.0251 18.7817C52.9784 18.6577 52.9572 18.5255 52.963 18.3932C52.9687 18.2608 53.0012 18.131 53.0586 18.0114L53.6458 16.8132H37.151L37.7383 18.0114C37.7964 18.1307 37.8297 18.2603 37.8363 18.3927C37.8428 18.5251 37.8225 18.6574 37.7765 18.7817L36.6211 21.8581L37.7669 24.8442C37.8144 24.9679 37.8364 25.0999 37.8314 25.2322C37.8265 25.3646 37.7948 25.4946 37.7383 25.6145L37.1033 26.8603Z"
          fill="#231F20"
        />
        <path
          d="M41.8871 107.698L45.4152 115.02L48.0219 109.59L49.9459 105.577H40.875L41.8871 107.698Z"
          fill="#A6C5D6"
        />
        <path
          d="M45.411 116.005C45.2254 116.007 45.043 115.956 44.8861 115.858C44.7293 115.759 44.6047 115.617 44.5277 115.448L39.9875 106.019C39.9082 105.871 39.8696 105.705 39.8756 105.538C39.8816 105.37 39.932 105.207 40.0217 105.065C40.1114 104.923 40.2371 104.807 40.3862 104.729C40.5354 104.652 40.7025 104.614 40.8707 104.621H49.9416C50.1073 104.622 50.2703 104.664 50.4153 104.743C50.5604 104.823 50.6829 104.938 50.7715 105.077C50.8601 105.217 50.9119 105.376 50.922 105.541C50.9322 105.706 50.9004 105.87 50.8296 106.019L46.2989 115.463C46.2219 115.631 46.0974 115.773 45.9405 115.872C45.7837 115.971 45.6013 116.022 45.4157 116.019L45.411 116.005ZM42.4271 106.561L45.411 112.743L48.3853 106.561H42.4271Z"
          fill="#231F20"
        />
        <path
          d="M55.4988 15.8337V6.89456C55.4994 6.11801 55.3464 5.34894 55.0484 4.63132C54.7505 3.9137 54.3134 3.26159 53.7623 2.71226C53.2112 2.16294 52.5568 1.72717 51.8365 1.42985C51.1162 1.13253 50.3442 0.979494 49.5645 0.979494H41.2622C40.4819 0.978869 39.7091 1.13141 38.9879 1.42839C38.2668 1.72537 37.6116 2.16097 37.0596 2.7103C36.5076 3.25963 36.0697 3.91191 35.7709 4.62987C35.4721 5.34782 35.3184 6.11738 35.3184 6.89456V15.8337H55.4988Z"
          fill="white"
        />
        <path
          d="M55.4985 16.8132H35.3181C35.0619 16.807 34.8183 16.7009 34.6398 16.5178C34.4612 16.3346 34.362 16.089 34.3633 15.8337V6.89457C34.3658 5.06679 35.096 3.31459 36.3936 2.02215C37.6913 0.729713 39.4506 0.00251588 41.2858 0H49.5642C51.3994 0.00251588 53.1587 0.729713 54.4564 2.02215C55.7541 3.31459 56.4843 5.06679 56.4868 6.89457V15.8337C56.4868 15.9628 56.4612 16.0905 56.4115 16.2097C56.3617 16.3288 56.2889 16.437 56.197 16.528C56.1052 16.6191 55.9962 16.6911 55.8764 16.7401C55.7565 16.789 55.6281 16.8139 55.4985 16.8132ZM36.3016 14.8542H54.515V6.89457C54.5138 5.58635 53.9921 4.33193 53.0642 3.40598C52.1363 2.48004 50.8778 1.95803 49.5642 1.95425H41.262C39.9472 1.95677 38.6869 2.47807 37.7572 3.40402C36.8275 4.32996 36.3041 5.58509 36.3016 6.89457V14.8542Z"
          fill="#231F20"
        />
        <path d="M35.5762 94.5605L40.8755 105.578H49.9464L55.2457 94.5605H35.5762Z" fill="white" />
        <path
          d="M49.9421 106.557H40.8712C40.6856 106.56 40.5033 106.509 40.3464 106.41C40.1895 106.311 40.065 106.169 39.988 106.001L34.6887 94.9789C34.6094 94.8309 34.5708 94.6647 34.5768 94.4971C34.5828 94.3295 34.6332 94.1665 34.7229 94.0246C34.8125 93.8826 34.9383 93.7668 35.0874 93.6889C35.2365 93.611 35.4037 93.5738 35.5719 93.581H55.2462C55.4145 93.5738 55.5816 93.611 55.7307 93.6889C55.8798 93.7668 56.0056 93.8826 56.0953 94.0246C56.1849 94.1665 56.2353 94.3295 56.2413 94.4971C56.2473 94.6647 56.2087 94.8309 56.1294 94.9789L50.8301 106.02C50.75 106.185 50.6235 106.324 50.4659 106.419C50.3084 106.515 50.1264 106.562 49.9421 106.557ZM41.4919 104.593H49.3263L53.6851 95.559H37.1521L41.4919 104.593Z"
          fill="#231F20"
        />
        <path
          d="M55.2464 95.5588H35.572C35.3158 95.5526 35.0722 95.4465 34.8937 95.2634C34.7151 95.0802 34.6159 94.8346 34.6172 94.5793V27.8399C34.6159 27.5846 34.7151 27.339 34.8937 27.1558C35.0722 26.9727 35.3158 26.8666 35.572 26.8604H55.2464C55.5026 26.8666 55.7462 26.9727 55.9247 27.1558C56.1033 27.339 56.2025 27.5846 56.2012 27.8399V94.546C56.2077 94.806 56.1111 95.058 55.9324 95.2475C55.7537 95.4371 55.5072 95.5489 55.2464 95.5588ZM36.5555 93.6046H54.2629V28.8194H36.5555V93.6046Z"
          fill="#231F20"
        />
      </g>
      <defs>
        <clipPath id="clip0_542_48621">
          <rect fill="white" height="116" transform="translate(0.5)" width="89" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default DrawingPencil
