// Turf
import * as turf from '@turf/turf'

export const assignSamplingAreasToMonitoringPoints = (samplingAreas, monitoringSites) => {
  let newMonitoringSites = []
  if (monitoringSites?.length && samplingAreas?.length) {
    newMonitoringSites = monitoringSites.filter(ms => {
      const parentArea = samplingAreas.find(sa => turf.booleanPointInPolygon(ms, sa))
      if (parentArea) {
        // eslint-disable-next-line no-param-reassign
        ms.properties.samplingAreaId = parentArea.properties.id
        return ms
      }
      return false
    })
  }
  return newMonitoringSites
}
