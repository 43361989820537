import endpoints from '../ruutsApi/endpoints'
import updateApiData from '../ruutsApi/updateApiData'

export const updateFarmSubdivision = async (token, farmSubdivision) => {
  try {
    const farmSubdivisionBody = {
      id: farmSubdivision.id,
      data: {
        ...farmSubdivision,
      },
    }
    const farmStatus = await updateApiData(endpoints.farmSubdivisions, farmSubdivisionBody, token)
    return farmStatus
  } catch (error) {
    console.error({ error })
    throw new Error('Unable to update farm subdivision')
  }
}
