import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

const FindingModal = ({
  formDefinition,
  open,
  handleClose,
  handleConfirm,
  buttonValue,
  saveLabel,
  cancelLabel,
  loading = false,
}) => {
  const theme = useTheme()

  const [fields, setFields] = useState([])
  const [comment, setComment] = useState('')
  const [fieldSelected, setFieldSelected] = useState([])
  const [observationTypeSelected, setObservationTypeSelected] = useState(null)
  const [nonComplianceTypeSelected, setNonComplianceTypeSelected] = useState(null)

  const handleChange = event => {
    const {
      target: { value },
    } = event
    setFieldSelected(typeof value === 'string' ? value.split(',') : value)
  }

  const handleCloseAndReset = () => {
    setObservationTypeSelected(null)
    setNonComplianceTypeSelected(null)
    setComment('')
    setFieldSelected([])
    handleClose()
  }

  const handleConfirmAndReset = () => {
    const findingData = {
      type: observationTypeSelected === '0' ? observationTypeSelected : nonComplianceTypeSelected,
      metricEventsFieldsObserved: fieldSelected,
      comment,
    }
    setObservationTypeSelected(null)
    setNonComplianceTypeSelected(null)
    setComment('')
    setFieldSelected([])
    handleConfirm(findingData)
  }

  useEffect(() => {
    const newFields = formDefinition.fields
      .map((field, index) => {
        return field.name
          ? {
              fieldId: index,
              label: field.label,
              name: field.name,
            }
          : null
      })
      .filter(field => field !== null)
    setFields(newFields)
  }, [formDefinition.fields])

  return (
    <Modal open={open} onClose={handleCloseAndReset}>
      <Box sx={modalStyle}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                margin: 2,
              }}
            >
              {`Nueva observación de ${formDefinition.label}`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Close
              sx={{
                float: 'right',
                margin: 2,
                cursor: 'pointer',
                '&:hover': {
                  color: 'red',
                },
              }}
              onClick={handleCloseAndReset}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            <FormControl sx={{ m: 2 }}>
              <Typography alignItems="flex-start" display="flex" fontSize={16} m={1}>
                Tipo de observación
              </Typography>
              <RadioGroup>
                <FormControlLabel
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#e9e925',
                        },
                      }}
                    />
                  }
                  label="Observación"
                  value={0}
                  onChange={e => {
                    setNonComplianceTypeSelected(null)
                    setObservationTypeSelected(e.target.value)
                  }}
                />
                <FormControlLabel
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: 'grey',
                        },
                      }}
                    />
                  }
                  label="No conformidad"
                  value={1}
                  onChange={e => {
                    setObservationTypeSelected(e.target.value)
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {observationTypeSelected === '1' && (
            <Grid item>
              <FormControl sx={{ m: 2 }}>
                <Typography alignItems="flex-start" display="flex" fontSize={16} m={1}>
                  Tipo de no conformidad
                </Typography>
                <RadioGroup>
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: 'orange',
                          },
                        }}
                      />
                    }
                    label="A corregir"
                    value={1}
                    onChange={e => {
                      setNonComplianceTypeSelected(e.target.value)
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: 'red',
                          },
                        }}
                      />
                    }
                    label="Grave"
                    value={2}
                    onChange={e => {
                      setNonComplianceTypeSelected(e.target.value)
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </Grid>

        <FormControl sx={{ m: 2 }}>
          <InputLabel id="fieldsPickerLabel">Campos observados</InputLabel>
          <Select
            multiple
            id="fieldsPicker"
            input={<OutlinedInput id="fieldChip" label="Campos observados" />}
            labelId="fieldsPickerLabel"
            MenuProps={MenuProps}
            renderValue={selected => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {
                  // set a limit of 5 chips
                  selected.length < 4 ? (
                    selected.map(value => <Chip key={value} label={value} />)
                  ) : (
                    <>
                      {selected.slice(0, 3).map(value => (
                        <Chip key={value} label={value} />
                      ))}
                      <Chip label={`+${selected.length - 3}`} />
                    </>
                  )
                }
              </Box>
            )}
            value={fieldSelected}
            onChange={handleChange}
          >
            {fields.map(field => (
              <MenuItem
                key={field.fieldId}
                style={getStyles(field.label, fieldSelected, theme)}
                value={field.label}
              >
                {field.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 2 }}>
          <Typography alignItems="flex-start" display="flex" fontSize={16} m={1}>
            Comentario
          </Typography>
          <TextField
            multiline
            inputProps={{ maxLength: 255 }}
            placeholder="Comentario"
            rows={6}
            value={comment}
            onChange={e => {
              setComment(e.target.value)
            }}
          />
        </FormControl>

        <Grid container>
          <Grid item xs={6}>
            <Button
              disabled={
                observationTypeSelected === null ||
                (observationTypeSelected === '1' && nonComplianceTypeSelected === null) ||
                comment === '' ||
                fieldSelected.length === 0
              }
              sx={{
                backgroundColor: '#1976d2',
                color: 'white',
                borederRadius: 4,
                margin: 2,
                px: 2,
                fontWeight: 600,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#1976d2',
                  color: 'white',
                  opacity: 0.8,
                },
                '&:disabled': {
                  backgroundColor: 'grey',
                  color: 'white',
                  opacity: 0.8,
                },
              }}
              value={buttonValue}
              onClick={handleConfirmAndReset}
            >
              {saveLabel ?? 'Confirmar'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{
                color: '#1976d2',
                backgroundColor: 'white',
                margin: 2,
                px: 2,
                fontWeight: 600,
                textTransform: 'none',
              }}
              onClick={handleCloseAndReset}
            >
              {cancelLabel ?? 'Cancelar'}
            </Button>
          </Grid>
        </Grid>
        <LinearProgress
          sx={{
            display: loading ? 'block' : 'none',
          }}
        />
      </Box>
    </Modal>
  )
}

export default FindingModal
