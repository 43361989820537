import * as React from 'react'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import CloseIcon from '@mui/icons-material/Close'

const CustomAlert = ({ title, message, severity, action }) => {
  const [open, setOpen] = React.useState(true)
  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          action={
            action === null ? null : (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
          severity={severity}
        >
          <AlertTitle sx={{ fontSize: '1rem', fontWeight: 'bold' }}>{title}</AlertTitle>
          {message}
        </Alert>
      </Collapse>
    </Box>
  )
}

export default CustomAlert
