import endpoints from '../endpoints'
import getApiData from '../getApiData'

export default async function getFormDefinition(id, token) {
  try {
    const formDefinition = await getApiData(`${endpoints.formDefinitions}/${id}`, null, token)
    return formDefinition[0]
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get program config.')
  }
}
