const ColorDot = ({ color }) => {
  return (
    <div
      style={{
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        border: '0.5px solid gray',
        backgroundColor: color,
      }}
    />
  )
}

export default ColorDot
