import dayjs from 'dayjs'

const formatDate = 'YYYY-MM-DD'
const displayFormatDate = 'DD/MM/YYYY'

export function getDateRangeDays(fieldName, startDateValue, fieldArrayValues, daysToAdd) {
  if (!startDateValue || Number.isNaN(daysToAdd) || !fieldArrayValues.length) return ''

  const startDate = dayjs(startDateValue, formatDate)
  if (!startDate.isValid()) return ''

  let fieldArrayIndex = fieldName.split('.')[1]
  if (Number.isNaN(fieldArrayIndex)) return ''
  fieldArrayIndex = Number(fieldArrayIndex)

  let usedDaysBeforeCurrentField = 0
  for (let i = 0; i < fieldArrayIndex; i++) {
    let { restingDaysByPaddock } = fieldArrayValues[i]
    let { daysGrazedByPaddock } = fieldArrayValues[i]
    if (!restingDaysByPaddock && !daysGrazedByPaddock) return ''

    restingDaysByPaddock = restingDaysByPaddock ? Number(restingDaysByPaddock) : 0
    daysGrazedByPaddock = daysGrazedByPaddock ? Number(daysGrazedByPaddock) : 0
    const daysUsed = restingDaysByPaddock + daysGrazedByPaddock

    // accumulate days used before current field
    // if current field is the first one, we don't need to accumulate days used because
    // the we start counting from the initial startDate
    if (i < fieldArrayIndex) usedDaysBeforeCurrentField += daysUsed
  }
  const newDays = daysToAdd - 1
  const fieldStartDate = startDate.add(usedDaysBeforeCurrentField, 'day')
  const fieldEndDate = fieldStartDate.add(newDays, 'day')

  return `${fieldStartDate.format(displayFormatDate)} al ${fieldEndDate.format(displayFormatDate)}`
}
