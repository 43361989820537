/* eslint-disable no-case-declarations */
// Leaflet
import L from 'leaflet'

// Turf
import * as turf from '@turf/turf'

// Custom
import omnivore from '@mapbox/leaflet-omnivore'
import { GeoJSON } from '../GeoJSON/index'
import { dataLayersAreas, dataLayersPoints } from '../FakeDataBase/data_fake'
import { messages } from '../messages'
import { errors } from '../../services/ruutsApi/errors'

const namingMap = {
  Perímetro: 'perimeter',
  Lotes: 'paddocks',
  Estratos: 'estratas',
  'Áreas de exclusión': 'exclusion',
  'Sitios de monitoreo': 'monitoringSites',
  'Otros sitios': 'otherSites',
  'Otras áreas': 'otherPolygons',
}

function objectToXML(obj) {
  let xml = ''

  const convertValueToXML = (value, propName) => {
    if (Array.isArray(value)) {
      return value
        .map(arrayItem => `<${propName}>\n${objectToXML(arrayItem)}</${propName}>\n`)
        .join('')
    }
    if (typeof value === 'object') {
      return objectToXML(value)
    }
    return value.toString()
  }

  Object.keys(obj).forEach(prop => {
    xml += `<${prop}>`
    xml += convertValueToXML(obj[prop], prop)
    xml += `</${prop}>\n`
  })

  xml = xml.replace(/<\/?[0-9]{1,}>/g, '')

  return xml
}

const handlePoint = (point, name) => {
  // Convierto las coordenadas de un string único gigante a un array de arrays
  const partialCoord = point.coordinates.split(',')
  const coordinates = [
    parseFloat(partialCoord[0]),
    parseFloat(partialCoord[1]),
    parseFloat(partialCoord[2]),
  ]

  // Creo el nuevo punto
  const newPoint = turf.point(coordinates, {
    name,
    featureGroup: 'unassignedPoints',
  })

  return newPoint
}

function featureFromPlacemark(placemark, category, name) {
  const placemarkXML = objectToXML({ Placemark: placemark })
  const features = omnivore.kml.parse(placemarkXML).toGeoJSON()
  if (features.features.length !== 1) {
    throw new errors.BusinessEntityError({
      needSupport: false,
      message: messages.getMessage(
        messages.keys
          .ERROR_IMPORTING_FARM_FROM_KML_UNSUPPORTED_MULTI_GEOMETRY_DUPLICATES_NAMES_GEOMETRY_MESSAGE,
        [category, name],
      ),
    })
  }
  const feature = features.features[0]
  feature.properties.featureGroup = namingMap[category]

  return feature
}

const placemarkToFeatures = (placemark, category) => {
  const newFeatures = []
  if (!placemark) return newFeatures

  // If it's not an array, it's a single element
  if (!Array.isArray(placemark) && !placemark?.length) {
    let newFeature
    // Si es un Point
    if (placemark?.Point) {
      newFeature = handlePoint(placemark.Point, placemark.name)
    } else {
      newFeature = featureFromPlacemark(placemark, category, placemark.name)
    }
    newFeatures.push(newFeature)
  } else {
    // If it's an array, it's a list of elements we need to iterate over to create all the features
    placemark.forEach(p => {
      const newFeature = placemarkToFeatures(p, category)
      newFeatures.push(...newFeature)
    })
  }

  return newFeatures
}

export const plantillaParsing = plantilla => {
  // Condiciones basicas para que la plantilla sea valida
  if (!(plantilla?.kml?.Document?.Folder && plantilla?.kml?.Document?.Folder?.length === 7)) {
    return null
  }

  /* Se crea objeto folders con key values para facilmente catalogar las features por key */
  const folders = {}
  let i = 0

  dataLayersAreas.forEach(layer => {
    folders[layer.farmLayerType] = {
      features: [],
    }
  })
  folders.exclusion = { features: [] }

  dataLayersPoints.forEach(layer => {
    folders[layer.farmLayerType] = {
      features: [],
    }
  })

  let featuresToAssign = []
  plantilla?.kml?.Document?.Folder?.forEach(placemark => {
    const features = placemarkToFeatures(placemark.Placemark, placemark.name)

    const featuresByPlacemark = features.map(feature => {
      let returnFeature = { ...feature }
      const { type } = feature.geometry

      switch (type) {
        case 'Point':
          returnFeature.properties.featureGroup = 'unassignedPoints'
          returnFeature.properties.id = i
          break
        case 'Polygon':
          returnFeature.properties.area = L.GeometryUtil.readableArea(
            GeoJSON.area(returnFeature),
            true,
          )
          returnFeature.properties.id = i
          break
        case 'MultiPolygon':
          returnFeature.properties.area = L.GeometryUtil.readableArea(
            GeoJSON.area(returnFeature),
            true,
          )
          returnFeature.properties.id = i
          break
        case 'GeometryCollection':
          const polygons = returnFeature.geometry.geometries
          const turfUnion = polygons.reduce((a, b) => turf.union(a, b), polygons[0])
          turfUnion.properties = {
            ...returnFeature.properties,
            name: returnFeature.properties.name,
            area: L.GeometryUtil.readableArea(GeoJSON.area(returnFeature), true),
            id: i,
          }
          returnFeature = turfUnion
          break
        default:
          return null
      }
      i++
      return returnFeature
    })

    featuresToAssign = [...featuresToAssign, ...featuresByPlacemark]
  })

  /* Cataloga las featues */
  featuresToAssign.forEach(fta => folders[fta.properties.featureGroup].features.push(fta))

  /* mapea el objeto Folders a un array */
  const foldersArray = Object.keys(folders).map(folderName => {
    const obj = {
      name: folderName,
      features: folders[folderName].features,
    }
    return obj
  })
  return foldersArray
}
