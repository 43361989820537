/* eslint-disable react/no-array-index-key */
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useEffect, useState } from 'react'
import {
  FeatureGroup,
  GeoJSON,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  Tooltip,
} from 'react-leaflet'
import BaseLayers from '../map/BaseLayers'

const EventMap = ({
  samplingAreas,
  monitoringSites,
  selectedMonitoringSites,
  mapWidth,
  mapHeight,
  selectedSamplingAreas,
  perimeter,
}) => {
  const [map, setMap] = useState()

  useEffect(() => {
    if (map && perimeter) {
      map.fitBounds(L.geoJSON(perimeter).getBounds())
    }

    if (map && samplingAreas && selectedSamplingAreas?.length) {
      map.fitBounds(L.geoJSON(samplingAreas).getBounds())
    }
  }, [map, samplingAreas, selectedSamplingAreas])

  useEffect(() => {
    if (map) {
      let count = 0
      map.eachLayer(layer => {
        if (layer instanceof L.Polygon) {
          count++
        }
      })
      if (!Array.isArray(selectedSamplingAreas)) {
        return
      }
      if (count !== selectedSamplingAreas.length + 1) {
        map.eachLayer(layer => {
          if (layer instanceof L.Polygon) {
            map.removeLayer(layer)
          }
        })
        if (perimeter) {
          L.geoJSON(perimeter, {
            style: {
              color: 'black',
              weight: 1,
              opacity: 1,
              fillOpacity: 0,
            },
          }).addTo(map)
        }
        if (selectedSamplingAreas.length > 0) {
          selectedSamplingAreas.forEach(strata => {
            L.geoJSON(strata, {
              style: {
                fillColor: strata.properties.strataolor,
                color: strata.properties.color,
                weight: 1,
                opacity: 1,
                fillOpacity: 0.4,
              },
              onEachFeature: (feature, layer) => {
                layer.bindTooltip(feature.properties.name, {
                  permanent: false,
                  direction: 'left',
                  sticky: true,
                  className: 'strata-label',
                })
              },
            }).addTo(map)
          })
        }
      }
    }
  }, [map, selectedSamplingAreas])

  return (
    <Container disableGutters sx={{ width: '100%' }}>
      <MapContainer
        ref={setMap}
        scrollWheelZoom
        zoomControl
        attributionControl={false}
        closePopupOnClick={false}
        doubleClickZoom={false}
        dragging={false}
        style={{ height: mapHeight - 100, width: mapWidth }}
        trackResize={false}
        wheelPxPerZoomLevel={60}
        zoom={5}
        zoomDelta={1}
        zoomSnap={0.1}
      >
        <LayersControl>
          <BaseLayers />
        </LayersControl>
        ;
        {selectedSamplingAreas && (
          <FeatureGroup>
            <LayerGroup>
              <GeoJSON
                data={{
                  type: 'FeatureCollection',
                  features: selectedSamplingAreas?.map(strata => {
                    return strata
                  }),
                }}
                style={feature => {
                  return {
                    fillColor: feature.properties.color,
                    color: feature.properties.color,
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 0.4,
                  }
                }}
                onEachFeature={(feature, layer) => {
                  layer.bindTooltip(feature.properties.name, {
                    permanent: false,
                    direction: 'left',
                    sticky: true,
                    className: 'strata-label',
                  })
                }}
              />
            </LayerGroup>
          </FeatureGroup>
        )}
        {monitoringSites && selectedMonitoringSites && (
          <FeatureGroup>
            <LayerGroup>
              {selectedMonitoringSites &&
                selectedMonitoringSites.map((marker, index) => {
                  return (
                    <Marker
                      key={index}
                      icon={L.divIcon({
                        className: 'my-div-icon',
                        html: `<span style="background-color: ${
                          samplingAreas.features.find(
                            sa => sa.properties.name === marker.properties.strata,
                          ).properties.color
                        }; filter: saturate(2.5);  border-radius: 50%; border: 2px solid #000; box-shadow: rgba(0,0,0,.5) 1px 1px 3px; width: 100%; height: 100%; display: inline-block; transform: scale(0.8);"></span>`,
                      })}
                      position={[marker.geometry.coordinates[1], marker.geometry.coordinates[0]]}
                    >
                      <Tooltip>
                        <div>
                          <Typography variant="p">{marker.properties.name}</Typography>
                        </div>
                      </Tooltip>
                    </Marker>
                  )
                })}
            </LayerGroup>
          </FeatureGroup>
        )}
      </MapContainer>
    </Container>
  )
}

export default EventMap
