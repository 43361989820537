/* eslint-disable indent */
import { Alert, Typography } from '@mui/material'

const EmptyAlert = () => {
  return (
    <Alert
      severity="error"
      sx={{
        width: window.innerWidth / 3,
        borderRadius: 0,
        padding: 2,
      }}
      variant="filled"
    >
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        El archivo no posee áreas
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
        }}
      >
        Por favor, verifique que su archivo tenga áreas dibujadas y vuelva a intentarlo.
      </Typography>
    </Alert>
  )
}

const SuccessfulAlert = ({ handleCloseAlert }) => {
  return (
    <Alert
      severity="info"
      sx={{
        width: window.innerWidth / 3,
        borderRadius: 0,
        padding: 2,
      }}
      variant="filled"
      onClose={handleCloseAlert}
    >
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        El archivo se cargó correctamente
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
        }}
      >
        Seleccionar un perímetro
      </Typography>
    </Alert>
  )
}

const AlertBanner = ({
  etapa,
  alert,
  handleCloseAlert,
  saved,
  perimeter,
  paddocks,
  samplingAreas,
  exclusionAreas,
  unassigned,
  unassignedPoints,
}) => {
  return (
    <>
      {
        // Alerta de Archivo vacio
        alert &&
          !perimeter &&
          !paddocks &&
          !samplingAreas &&
          !exclusionAreas &&
          !unassigned &&
          !unassignedPoints &&
          !saved &&
          etapa === 'showingFoldersArchivo' && <EmptyAlert />
      }
      {
        // Alerta de que el archivo se cargó correctamente
        alert &&
          etapa === 'showingFoldersArchivo' &&
          !saved &&
          (!perimeter || perimeter.length === 0) &&
          (paddocks || samplingAreas || exclusionAreas || unassigned || unassignedPoints) && (
            <SuccessfulAlert handleCloseAlert={handleCloseAlert} />
          )
      }

      {
        // Alerta de que las áreas se guardaron correctamente
        alert && etapa === 'showingFoldersArchivo' && !saved && perimeter ? (
          <Alert
            severity="info"
            sx={{
              width: window.innerWidth / 3,
              borderRadius: 0,
              padding: 2,
            }}
            variant="filled"
            onClose={handleCloseAlert}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Identifique las Áreas y Sitios sin asignar
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
              }}
            >
              Asigne los elementos a continuación, al momento de guardar, los elementos que queden
              sin asignar se descartarán
            </Typography>
          </Alert>
        ) : null
      }

      {
        // Alerta de que se cargo en la BD correctamente
        alert && etapa === 'showingFoldersApi' && saved ? (
          <Alert
            severity="success"
            sx={{
              width: window.innerWidth / 3,
              borderRadius: 0,
              padding: 2,
            }}
            variant="filled"
            onClose={handleCloseAlert}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Las áreas se guardaron correctamente
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
              }}
            >
              Continúe cargando la información de su establecimiento en Información General
            </Typography>
          </Alert>
        ) : null
      }
    </>
  )
}

export default AlertBanner
