const general = {
  namespace: 'farm_management_general',
  label: 'General',
  required: true,
  layout: {
    showMap: false,
    showTable: true,
  },
  fields: [
    {
      component_type: 'Title',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Información general',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'animalLoadByHectareAvg',
      unit: 'equiv/vaca',
      placeholder: 'Carga animal promedio',
      label: 'Carga animal promedio',
      defaultValue: 0,
      min: 0,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'plannedBurnedHectares',
      unit: 'has',
      placeholder: 'Quema planificada de pastizales en el año',
      label: 'Quema planificada de pastizales en el año',
      defaultValue: 0,
      min: 0,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'accidentalBurnedHectares',
      unit: 'has',
      placeholder: 'Quema accidental de pastizales en el año',
      label: 'Quema accidental de pastizales en el año',
      defaultValue: 0,
      min: 0,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'CheckBox',
      type: 'boolean',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'nutritionalSupplement',
      placeholder: 'Se utiliza suplemento de alimento',
      label: 'Utiliza suplemento de alimento',
      defaultValue: false,
      fullWidth: true,
      required: false,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'nutritionalSupplementPercentage',
      unit: '%',
      placeholder: 'Porcentaje de suplemento de alimento sobre el total',
      label: 'Porcentaje de suplemento de alimento sobre el total',
      defaultValue: 0,
      min: 0,
      max: 100,
      fullWidth: true,
      required: false,
      dependencies: {
        disabled: [],
        display: [{ nutritionalSupplement: { eq: true } }],
      },
    },
    {
      component_type: 'FileInput',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'grazingPlanFiles',
      placeholder: 'Arrastre las fotos del plan de pastoreo',
      label: 'Plan de pastoreo',
      defaultValue: [],
      fullWidth: true,
      required: false,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'FileInput',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'boughtAnimalFiles',
      placeholder: 'Arrastre los archivos de compra de animales',
      label: 'Compra de animales',
      defaultValue: [],
      fullWidth: true,
      required: false,
      dependencies: {
        disabled: [],
        display: [],
      },
    },
  ],
}
export default general
