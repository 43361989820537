import { LayersControl } from 'react-leaflet'
import BaseLayers from './BaseLayers'
import DrawingLayers from './DrawingLayers'

const MapElements = ({
  drawingType,
  etapa,
  handleEtapa,
  center,
  perimeter,
  handlePerimeter,
  paddocks,
  handlePaddocks,
  samplingAreas,
  handleSamplingAreas,
  monitoringSites,
  handleMonitoringSites,
  unassigned,
  handleUnassigned,
  unassignedPoints,
  handleUnassignedPoints,
  exclusionAreas,
  handleExclusionAreas,
  mapUpdate,
  handleMapUpdate,
  map,
  otherPolygons,
  handleOtherPolygons,
  otherSites,
  handleOtherSites,
}) => {
  return (
    <LayersControl collapsed={false} position="topright">
      <BaseLayers />
      <DrawingLayers
        center={center}
        drawingType={drawingType}
        etapa={etapa}
        exclusionAreas={exclusionAreas}
        handleEtapa={handleEtapa}
        handleExclusionAreas={handleExclusionAreas}
        handleMapUpdate={handleMapUpdate}
        handleMonitoringSites={handleMonitoringSites}
        handleOtherPolygons={handleOtherPolygons}
        handleOtherSites={handleOtherSites}
        handlePaddocks={handlePaddocks}
        handlePerimeter={handlePerimeter}
        handleSamplingAreas={handleSamplingAreas}
        handleUnassigned={handleUnassigned}
        handleUnassignedPoints={handleUnassignedPoints}
        map={map}
        mapUpdate={mapUpdate}
        monitoringSites={monitoringSites}
        otherPolygons={otherPolygons}
        otherSites={otherSites}
        paddocks={paddocks}
        perimeter={perimeter}
        samplingAreas={samplingAreas}
        unassigned={unassigned}
        unassignedPoints={unassignedPoints}
      />
    </LayersControl>
  )
}

export default MapElements
