/* eslint-disable react/no-array-index-key */
import { useAuth0 } from '@auth0/auth0-react'
import { CheckCircleOutline, HourglassEmpty } from '@mui/icons-material'
import { Stack, TextField, Tooltip, Button, Chip, Typography, Box } from '@mui/material'
import { useContext } from 'react'
import AppContext from '../../context/appContext'
import { dataCollectionStatementService } from '../../services/farmMapping/datacollectionStatement'
import resolveFinding from '../../services/ruutsApi/findings/resolveFinding'
import { findingsTypesConventions } from '../../utils/findingColors'
import FindingCommentsAccordion from './FindingCommentsAccordion'

const FindingCard = ({ finding, readOnly, handleMetricReset }) => {
  const { getAccessTokenSilently } = useAuth0()
  const { userRoleSelected, setLoadingModalConfig, currentFarm } = useContext(AppContext)

  const findingsTypes = [
    {
      label: 'Observación',
      color: findingsTypesConventions.observed.color,
    },
    {
      label: 'No Conformidad a Corregir',
      color: findingsTypesConventions.nonComplaintToFix.color,
    },
    {
      label: 'No Conformidad Grave',
      color: findingsTypesConventions.nonComplaintSevere.color,
    },
  ]

  const handleResolveFinding = async () => {
    try {
      setLoadingModalConfig({
        open: true,
        dialogMessage: 'Guardando observación',
      })

      const token = await getAccessTokenSilently()
      const dataCollectionStatements = await dataCollectionStatementService.getByFarmId(
        currentFarm.id,
        userRoleSelected,
        token,
      )
      const dataCollectionStatement = dataCollectionStatements.find(statement =>
        statement.metricsEventsIds.includes(finding.metricEventId),
      )

      await resolveFinding(userRoleSelected, dataCollectionStatement.id, token, finding.id)

      handleMetricReset()

      const successfulMessage = 'Observación resuelta exitosamente'
      setLoadingModalConfig({
        open: false,
        successfulMessage,
      })
    } catch (error) {
      setLoadingModalConfig({
        open: false,
        error,
        errorMessage: 'Error al resolver la observación, por favor intente nuevamente',
      })
    }
  }

  return (
    <Box
      key={finding.id}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        borderRadius: 2,
        padding: 1,
        margin: 0.5,
        backgroundColor: 'lightgray',
      }}
    >
      <Stack alignItems="center" direction="row" pb={0.5}>
        <Typography sx={{ fontWeight: 'bold' }} variant="body1">
          Tipo
        </Typography>
        <Typography variant="body1">{`: ${findingsTypes[finding.type].label}`}</Typography>
        <Box
          backgroundColor={findingsTypes[finding.type].color}
          border="1px solid black"
          borderRadius="100%"
          height="16px"
          mx={0.5}
          width="16px"
        />
      </Stack>
      <Stack direction="column" pb={0.5}>
        <Typography
          sx={{
            fontWeight: 'bold',
            alignSelf: 'flex-start',
          }}
          variant="body1"
        >
          Campos afectados
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 0.3,
            },
          }}
        >
          {finding.metricEventsFieldsObserved.map((field, index) => {
            return (
              <Chip
                key={index}
                color="primary"
                label={field?.label ? field.label : field}
                sx={{
                  height: '24px',
                }}
              />
            )
          })}
        </Box>
      </Stack>

      {/* Comentario inicial de la finding */}
      <Stack direction="column" pb={1} width="100%">
        <Typography
          sx={{
            fontWeight: 'bold',
            alignSelf: 'flex-start',
          }}
          variant="body1"
        >
          Descripción
        </Typography>
        <TextField multiline readOnly placeholder="Comentario" rows={2} value={finding.comment} />
      </Stack>

      {/* Commentarios posteriores de comunicación y arreglos */}
      <FindingCommentsAccordion finding={finding} />

      {readOnly ? (
        <Box
          mt={0.5}
          sx={{
            alignSelf: 'flex-end',
          }}
        >
          {!finding.resolved ? (
            <Tooltip title="En espera de resolución">
              <HourglassEmpty
                color="warning"
                sx={{
                  fontSize: 24,
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Resuelta">
              <CheckCircleOutline
                color="success"
                sx={{
                  fontSize: 28,
                }}
              />
            </Tooltip>
          )}
        </Box>
      ) : (
        <Box
          mt={0.5}
          sx={{
            alignSelf: 'flex-end',
          }}
        >
          {!finding.resolved ? (
            <Button color="success" variant="contained" onClick={handleResolveFinding}>
              <Typography variant="body1">Resolver</Typography>
            </Button>
          ) : (
            <Tooltip title="Resuelta">
              <CheckCircleOutline
                color="success"
                sx={{
                  fontSize: 28,
                }}
              />
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  )
}

export default FindingCard
