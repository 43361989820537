/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */
import L from 'leaflet'
import * as turf from '@turf/turf'
import { GeoJSON } from './GeoJSON/index.js'

const splitStraIntoPolygonsOrdered = strata => {
  const strataArea = turf.area(strata)
  const minimunPercentajeOfStrata = 5
  const minimunAreaOfStrata = 50000

  const stratasSplitIntoPolygonsOrdered = []

  if (strata.geometry.type.toLowerCase() === 'polygon') {
    const polygon = strata
    const polygonArea = turf.area(polygon)

    stratasSplitIntoPolygonsOrdered.push({
      polygon,
      area: polygonArea,
      index: 0,
    })
  }

  if (strata.geometry.type.toLowerCase() === 'multipolygon') {
    strata.geometry.coordinates.forEach((coordinates, index) => {
      const polygon = turf.polygon(coordinates)
      const polygonArea = turf.area(polygon)
      const percentajeOfStrata = (polygonArea / strataArea) * 100

      if (percentajeOfStrata >= minimunPercentajeOfStrata && polygonArea >= minimunAreaOfStrata) {
        stratasSplitIntoPolygonsOrdered.push({
          polygon,
          area: polygonArea,
          index,
        })
      }
    })
  }

  return stratasSplitIntoPolygonsOrdered
}

const randomize = (
  samples,
  strata,
  stratasSplitIntoPolygonsOrdered,
  points,
  index,
  minDistanceToPointFactor,
  minDistanceToBoundsFactor,
) => {
  if (!stratasSplitIntoPolygonsOrdered?.length) {
    return points
  }
  // maximo de iteraciones para generar puntos en el while
  const maxIterations = 3000
  // distancia minima entre puntos
  const minDistanceToPoint = Math.sqrt(turf.area(strata)) / minDistanceToPointFactor // incrementar el divisor si la cant de samples no se cumple.

  let iterations = 0

  // Mientras sean menos puntos que muestras
  while (points.length < samples && iterations < maxIterations) {
    iterations++
    // El subpoligono aleatorio dentro de los subpoligonos del estrato
    // Si el array de polígonos tiene mas de un polígono (Strata Multipolígono), devuelve el valor random del index
    // Si tiene un solo polígono (Strata era un Polygon), devuelve el índice 0

    const subpolygonStrataIndex =
      stratasSplitIntoPolygonsOrdered.length > 1
        ? Math.floor(Math.random() * stratasSplitIntoPolygonsOrdered.length)
        : 0
    const subpolygonStrata = stratasSplitIntoPolygonsOrdered[subpolygonStrataIndex].polygon

    // obtener limites del estrato
    const bounds = L.geoJson(subpolygonStrata).getBounds()
    const x_min = bounds.getEast()
    const x_max = bounds.getWest()
    const y_min = bounds.getSouth()
    const y_max = bounds.getNorth()

    // generar punto aleatorio
    const lat = y_min + Math.random() * (y_max - y_min)
    const lng = x_min + Math.random() * (x_max - x_min)

    // verificar si el punto esta dentro del estrato
    const point = turf.point([lng, lat])

    const inside = turf.inside(point, subpolygonStrata)
    // si no esta dentro que haga nueva iteracion
    if (!inside) continue

    // calcular distancia a los puntos existentes
    let nearToPoint = false
    for (let i = 0; i < points.length; i++) {
      const distanceToPoint = GeoJSON.distance(point, points[i])
      // si la distancia es menor a la minima, que haga nueva iteracion
      if (distanceToPoint < minDistanceToPoint) {
        nearToPoint = true
        break
      }
    }
    if (nearToPoint) continue

    // calcular si el punto esta dentro del estrato con un buffer negativo
    let buffer = turf.buffer(subpolygonStrata, -minDistanceToBoundsFactor, {
      units: 'meters',
    })

    const areaBuffer = buffer ? GeoJSON.hectareArea(buffer) : 0
    buffer = areaBuffer < 0.5 ? subpolygonStrata : buffer

    const insideBuffer = turf.inside(point, buffer)
    // si no esta dentro que haga nueva iteracion
    if (!insideBuffer) continue

    // Si el punto esta dentro del estrato, no esta cerca de otro punto y esta dentro del buffer
    if (inside && !nearToPoint && insideBuffer) {
      // Si no tiene indice, agregar al final
      if (index === null || index === undefined) {
        points.push(point)
      } else if (index === 0) {
        // Sino, si el indice es 0 agregar al principio
        points.unshift(point)
      } else {
        // Sino, agregar en el indice
        points.splice(index, 0, point)
      }
    }
  }

  if (!index) {
    for (let i = 0; i < points.length; i++) {
      points[i].properties.name = `${strata.properties.name}-${i + 1}`
      points[i].properties.strata = strata.properties.name
    }
  }

  return points
}

// calcular cobertura de los puntos sobre el estrato con un radio
const calculateCoverage = (points, strata) => {
  if (points.length && strata) {
    let coveredArea
    for (let i = 0; i < points.length; i++) {
      if (coveredArea === undefined) {
        coveredArea = turf.buffer(points[i], Math.sqrt(turf.area(strata)) / 4, {
          units: 'meters',
        })
      } else {
        coveredArea = turf.union(
          turf.buffer(points[i], Math.sqrt(turf.area(strata)) / 4, {
            units: 'meters',
          }),
          coveredArea,
        )
      }
    }

    const coveredIntersection = GeoJSON.intersect(coveredArea, strata)
    const coverage = (turf.area(coveredIntersection) / turf.area(strata)) * 100 // posibilidad cambiar a UTM area
    return coverage
  }
  return 0
}

export default function randomPointsInPolygonV2(samples, strata, points, index) {
  let minDistanceToPointFactor = 6
  const minDistanceToBoundsFactor = 100
  let generatedPoints = 0
  let coverageOk = false
  let iterations = 0
  let randomPoints
  let stratasSplitIntoPolygonsOrdered

  while (generatedPoints < samples || !coverageOk) {
    iterations++

    stratasSplitIntoPolygonsOrdered = splitStraIntoPolygonsOrdered(strata)

    randomPoints = randomize(
      samples,
      strata,
      stratasSplitIntoPolygonsOrdered,
      points,
      index,
      minDistanceToPointFactor,
      minDistanceToBoundsFactor,
    )
    generatedPoints = randomPoints.length

    if (minDistanceToPointFactor < 20) {
      minDistanceToPointFactor += 2
    }
    const coverage = calculateCoverage(randomPoints, strata)
    coverageOk = coverage >= 50 || iterations >= 2
  }
  return randomPoints
}
