import axios from 'axios'

export default async function resolveFinding(
  userRole,
  dataCollectionStatementId,
  token,
  findingId,
) {
  try {
    const options = {
      url: `${process.env.REACT_APP_RUUTS_API_URL}/v1/findings/${findingId}`,
      method: 'PUT',
      data: {
        data: {
          resolved: true,
          dataCollectionStatementId,
          userRole,
        },
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.request(options)
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error(`Unable to save finding: ${error.message}`)
  }
}
