import { useAuth0 } from '@auth0/auth0-react'
import Box from '@mui/material/Box'
import { blueGrey } from '@mui/material/colors'
import { useEffect } from 'react'
import getReferenceData from '../../services/getReferenceData'
import FarmSelector from '../FarmSelector/FarmSelector'
import VersionLabel from './VersionLabel'

const HomePage = () => {
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    async function getRefData() {
      const token = await getAccessTokenSilently()
      await getReferenceData(token)
    }
    getRefData()
  }, [getAccessTokenSilently])

  return (
    <Box
      sx={{
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: blueGrey[50],
      }}
    >
      <FarmSelector />
      <VersionLabel />
    </Box>
  )
}

export default HomePage
