import lfStore from '../../lfstore/lfStore'

export default async function refDataByKey(key) {
  try {
    if (!key) return null
    return await lfStore.getItem(key)
  } catch (error) {
    console.error(error)
    throw new Error(`Unable to retrieve reference data for ${key}`)
  }
}
