import * as utm from 'utm'
import * as turf from '@turf/turf'

function getDistance(x1, y1, x2, y2) {
  const y = x2 - x1
  const x = y2 - y1

  return Math.sqrt(x * x + y * y)
}

export default function getDistanceUtm(from, to) {
  const fromUtm = utm.fromLatLon(from.lat, from.lng)
  const toUtm = utm.fromLatLon(to.lat, to.lng)

  const distance = getDistance(toUtm.easting, toUtm.northing, fromUtm.easting, fromUtm.northing)
  const bearing = turf.bearing(turf.point([from.lng, from.lat]), turf.point([to.lng, to.lat]))

  return {
    distance,
    bearing: Number.isNaN(bearing) ? null : bearing,
  }
}
