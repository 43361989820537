import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useRoutes } from 'react-router'
import Authorizing from '../components/Authorizing/Authorizing'
import MainRoutes from './MainRoutes'

const AllRoutes = () => {
  return useRoutes([MainRoutes])
}

export default withAuthenticationRequired(AllRoutes, { onRedirecting: () => <Authorizing /> })
