import Tooltip from '@mui/material/Tooltip'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { IconButton, Stack } from '@mui/material'

const TableDocumentsButtons = ({ recordData, handleEditDocuments }) => {
  return (
    <Stack alignItems="center" direction="row" display="flex" justifyContent="center">
      <Tooltip title="Documentos">
        <IconButton size="small" onClick={() => handleEditDocuments(recordData)}>
          <NoteAddIcon sx={{ height: '18px', width: '18px' }} />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}

export default TableDocumentsButtons
