import endpoints from '../../ruutsApi/endpoints'
import deleteApiData from '../../ruutsApi/deleteApiData'

export const deleteSamplingAreas = async ({ entities, token }) => {
  entities.forEach(async entityToDelete =>
    deleteApiData(`${endpoints.samplingAreas}/${entityToDelete.id}`, null, token),
  )
}

export const deleteSamplingAreasByFarm = async ({ farmId, token }) =>
  deleteApiData(`${endpoints.samplingAreas}/farms/${farmId}`, null, token)
