import { Box } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Stack from '@mui/material/Stack'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useCallback, useContext, useEffect, useState } from 'react'
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet'
import AppContext from '../../context/appContext'
import { DCNamespaces } from '../../utils/dataCollection/namespaces'
import PaddocksList from './PaddocksList'

const DataCollectionMap = ({
  paddocksGeoJson,
  allPaddockIds,
  updateSelectedPaddockIds,
  metricEvents,
  paddockProductionEvents,
  selectedMetricEventIndex,
  namespace,
  resetFormSignal,
  formMode,
}) => {
  const [map, setMap] = useState()
  const [selectedPaddockIds, setSelectedPaddockIds] = useState([])
  const [usedPaddockIds, setUsedPaddockIds] = useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [mapReady, setMapReady] = useState(false)
  const { programConfig } = useContext(AppContext)

  const handleListSelection = selection => {
    const newSelected = selection.map(s => s.id)
    setSelectedPaddockIds(newSelected)
    updateSelectedPaddockIds(newSelected)
  }

  const featureUsed = feature => {
    return usedPaddockIds.includes(feature.properties.id)
  }

  const handleStyle = feature => {
    return {
      fillColor: featureUsed(feature)
        ? 'gray'
        : selectedPaddockIds.filter(id => id === feature.properties.id).length
          ? 'red'
          : 'blue',
      color: featureUsed(feature)
        ? 'lightgray'
        : selectedPaddockIds.filter(id => id === feature.properties.id).length
          ? 'red'
          : 'black',
      weight: 1,
      opacity: 1,
      fillOpacity: 0.5,
    }
  }

  const featureAlreadySelected = feature => {
    return selectedPaddockIds.includes(feature.properties.id)
  }

  const handleMouseOver = event => {
    const { layer } = event
    const tooltipContent = layer?.feature?.properties?.name
    layer.bindTooltip(tooltipContent).openTooltip()
  }

  const handleUpdateSelectedPaddocks = clickEvent => {
    const { feature } = clickEvent.layer
    if (!featureUsed(feature)) {
      let newSelected = []
      if (featureAlreadySelected(feature)) {
        // filter out feature and return the rest
        newSelected = selectedPaddockIds.filter(id => id !== feature.properties.id)
        setAllSelected(false)
      } else {
        // add feature to selected array
        newSelected = [...selectedPaddockIds, feature.properties.id]
        setAllSelected(newSelected.length === allPaddockIds.length - usedPaddockIds.length)
      }
      // call selection change
      setSelectedPaddockIds(newSelected)
      updateSelectedPaddockIds(newSelected)
    }
  }

  const resetPaddocksState = useCallback(() => {
    if (selectedMetricEventIndex != null && metricEvents[selectedMetricEventIndex].paddockIds) {
      setSelectedPaddockIds(metricEvents[selectedMetricEventIndex].paddockIds)
    } else {
      setSelectedPaddockIds([])
    }
    let newUsedPaddocks = []
    if (namespace !== DCNamespaces.grazing) {
      metricEvents.forEach(event => {
        if (
          event.paddockIds &&
          event.paddockIds.length > 0 &&
          event.id !== metricEvents[selectedMetricEventIndex]?.id
        ) {
          newUsedPaddocks = newUsedPaddocks.concat(event.paddockIds)
        }
      })
    } else {
      paddockProductionEvents.forEach(event => {
        if (
          event.paddockIds &&
          event.paddockIds.length > 0 &&
          event.id !== metricEvents[selectedMetricEventIndex]?.id &&
          event.namespace === DCNamespaces.paddocks &&
          !programConfig.allowedGrazingPaddockFieldUsages.includes(event.data.fieldUsageId)
        ) {
          newUsedPaddocks = newUsedPaddocks.concat(event.paddockIds)
        }
      })
    }
    setUsedPaddockIds(newUsedPaddocks)
  }, [
    programConfig.allowedGrazingPaddockFieldUsages,
    selectedMetricEventIndex,
    metricEvents,
    namespace,
    paddockProductionEvents,
  ])

  const handleSelectAll = () => {
    if (!allSelected) {
      const newSelected = allPaddockIds.filter(id => !usedPaddockIds.includes(id))
      setAllSelected(true)
      setSelectedPaddockIds(newSelected)
      updateSelectedPaddockIds(newSelected)
    } else {
      setAllSelected(false)
      setSelectedPaddockIds([])
      updateSelectedPaddockIds([])
    }
  }

  useEffect(() => {
    if (!map || !paddocksGeoJson?.features?.length) return
    setMapReady(true)

    map.fitBounds(L.geoJSON(paddocksGeoJson).getBounds())
  }, [map, paddocksGeoJson])

  // Reset paddocks state when metricEvents data changes.
  useEffect(() => {
    if (metricEvents) {
      resetPaddocksState(metricEvents)
    }
  }, [metricEvents, resetPaddocksState, selectedMetricEventIndex, resetFormSignal])

  return (
    <Container disableGutters sx={{ width: '100%' }}>
      <Stack
        direction="row"
        display="flex"
        justifyContent="space-between"
        pb={1}
        pt={1}
        sx={{ width: '100%', alignItems: 'center', backgroundColor: '#eceff1' }}
      >
        <PaddocksList
          formMode={formMode}
          paddocks={paddocksGeoJson.features.map(feature => {
            return { id: feature.properties.id, label: feature.properties.name }
          })}
          selectedPaddockIds={selectedPaddockIds}
          updateSelectedPaddocks={handleListSelection}
          usedPaddockIds={usedPaddockIds}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={allSelected}
                disabled={formMode.readOnly}
                onChange={handleSelectAll}
              />
            }
            label="Seleccionar todos los lotes disponibles"
          />
        </FormGroup>
      </Stack>
      <Divider />
      {!mapReady && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255,255,255,0.4)',
            zIndex: 9999,
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <MapContainer
        ref={setMap}
        scrollWheelZoom
        zoomControl
        attributionControl={false}
        closePopupOnClick={false}
        doubleClickZoom={false}
        dragging={false}
        style={{ height: '72vh', width: '50vw' }}
        touchZoom={false}
        trackResize={false}
        wheelPxPerZoomLevel={60}
        zoom={5}
        zoomDelta={1}
        zoomSnap={0.1}
      >
        <TileLayer
          attribution=""
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
        />
        {paddocksGeoJson && (
          <GeoJSON
            data={paddocksGeoJson}
            eventHandlers={{
              click: (feature, layer) => {
                if (formMode.readOnly) return

                handleUpdateSelectedPaddocks(feature, layer)
              },
              mouseover: handleMouseOver,
            }}
            style={handleStyle}
          />
        )}
      </MapContainer>
    </Container>
  )
}

export default DataCollectionMap
