/* eslint-disable max-classes-per-file */
class RuutsError extends Error {
  constructor({ request, response, message, unexpected, needSupport }) {
    super(message)
    this.request = request
    this.response = response
    this.unexpected = unexpected
    this.needSupport = needSupport
  }
}

class BusinessEntityError extends RuutsError {
  constructor({ request, message, response, needSupport }) {
    super({
      request,
      message,
      unexpected: false,
      response,
      needSupport,
    })
  }
}

class UnexpectedEntityError extends RuutsError {
  constructor({ request, message }) {
    super({
      request,
      message,
      unexpected: true,
      needSupport: true,
    })
  }
}

export const errors = {
  RuutsError,
  BusinessEntityError,
  UnexpectedEntityError,
}
