/* eslint-disable import/no-cycle */
import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import BoxWrapper from './wrappers/BoxWrapper'
import CheckBoxGroupWrapper from './wrappers/CheckBoxGroupWrapper'
import CheckBoxWrapper from './wrappers/CheckBoxWrapper'
import DateFieldWrapper from './wrappers/DateFieldWrapper'
import DividerWrapper from './wrappers/DividerWrapper'
import FieldArrayWrapper from './wrappers/FieldArrayWrapper'
import FileInputWrapper from './wrappers/FileInputWrapper'
import InfoText from './wrappers/InfoText'
import LinkWrapper from './wrappers/LinkWrapper'
import RadioGroupWrapper from './wrappers/RadioGroupWrapper'
import SelectWrapper from './wrappers/SelectWrapper'
import TextFieldWrapper from './wrappers/TextFieldWrapper'
import TitleWrapper from './wrappers/TitleWrapper'

const DynamicFieldSwitcher = ({
  formField,
  checkDependencies,
  index,
  year,
  readOnly,
  fieldArrayName,
  fieldArrayIndex,
}) => {
  const { control, watch } = useFormContext()

  const isReadOnly = () => {
    return watch('notApplicable') || readOnly
  }

  if (
    formField.component_type === 'Title' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <TitleWrapper key={index} formField={formField} index={index} />
      </Grid>
    )
  }

  if (
    formField.component_type === 'Divider' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <DividerWrapper
          key={index}
          fieldArrayIndex={fieldArrayIndex}
          fieldArrayName={fieldArrayName}
          formField={formField}
          index={index}
        />
      </Grid>
    )
  }

  if (
    formField.component_type === 'InfoText' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <InfoText
          key={index}
          fieldArrayIndex={fieldArrayIndex}
          fieldArrayName={fieldArrayName}
          formField={formField}
          index={index}
        />
      </Grid>
    )
  }

  if (
    formField.component_type === 'Link' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <LinkWrapper
          key={index}
          fieldArrayIndex={fieldArrayIndex}
          fieldArrayName={fieldArrayName}
          formField={formField}
          index={index}
        />
      </Grid>
    )
  }

  if (
    formField.component_type === 'TextField' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <TextFieldWrapper
          key={index}
          control={control}
          fieldArrayIndex={fieldArrayIndex}
          fieldArrayName={fieldArrayName}
          formField={formField}
          index={index}
          readOnly={isReadOnly()}
          rules={{
            required: formField.required ? `${formField.label}: Campo requerido` : false,
            min: formField.min,
            max: formField.max,
          }}
        />
      </Grid>
    )
  }

  if (
    formField.component_type === 'DateField' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <DateFieldWrapper
          key={index}
          control={control}
          fieldArrayIndex={fieldArrayIndex}
          fieldArrayName={fieldArrayName}
          formField={formField}
          index={index}
          readOnly={isReadOnly()}
          year={year}
        />
      </Grid>
    )
  }

  if (
    formField.component_type === 'CheckBox' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <CheckBoxWrapper
          key={index}
          control={control}
          fieldArrayIndex={fieldArrayIndex}
          fieldArrayName={fieldArrayName}
          formField={formField}
          index={index}
          readOnly={isReadOnly()}
          rules={{
            required: formField.required ? `${formField.label}: Campo requerido` : false,
          }}
        />
      </Grid>
    )
  }

  if (
    formField.component_type === 'RadioGroup' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <RadioGroupWrapper
          key={index}
          control={control}
          fieldArrayIndex={fieldArrayIndex}
          fieldArrayName={fieldArrayName}
          formField={formField}
          readOnly={isReadOnly()}
          rules={{
            required: formField.required ? `${formField.label}: Campo requerido` : false,
          }}
        />
      </Grid>
    )
  }

  if (
    formField.component_type === 'CheckBoxGroup' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <CheckBoxGroupWrapper
          key={index}
          control={control}
          fieldArrayIndex={fieldArrayIndex}
          fieldArrayName={fieldArrayName}
          formField={formField}
          readOnly={isReadOnly()}
          rules={{
            required: formField.required ? `${formField.label}: Campo requerido` : false,
          }}
        />
      </Grid>
    )
  }

  if (
    formField.component_type === 'Select' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <SelectWrapper
          key={index}
          control={control}
          fieldArrayIndex={fieldArrayIndex}
          fieldArrayName={fieldArrayName}
          formField={formField}
          readOnly={isReadOnly()}
          rules={{
            required: formField.required ? `${formField.label}: Campo requerido` : false,
          }}
        />
      </Grid>
    )
  }

  if (
    formField.component_type === 'FieldArray' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch, false)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <FieldArrayWrapper
          key={index}
          checkDependencies={checkDependencies}
          control={control}
          fieldArray={formField}
          readOnly={isReadOnly()}
        />
      </Grid>
    )
  }

  if (
    formField.component_type === 'FileInput' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <FileInputWrapper
          key={index}
          control={control}
          fieldArrayIndex={fieldArrayIndex}
          fieldArrayName={fieldArrayName}
          formField={formField}
          readOnly={isReadOnly()}
          year={year}
        />
      </Grid>
    )
  }

  if (
    formField.component_type === 'Box' &&
    checkDependencies(formField.dependencies.display, 'display', index, watch)
  ) {
    return (
      <Grid
        key={formField.name}
        item
        lg={formField.gridSizing.lg || 6}
        sm={formField.gridSizing.sm || 6}
        xs={formField.gridSizing.xs || 6}
      >
        <BoxWrapper
          key={index}
          checkDependencies={checkDependencies}
          control={control}
          fieldArray={formField}
          fieldArrayIndex={fieldArrayIndex}
          fieldArrayName={fieldArrayName}
          readOnly={isReadOnly()}
        />
      </Grid>
    )
  }

  return null
}

export default DynamicFieldSwitcher
