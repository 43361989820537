import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext'
import lfStore from '../../lfstore/lfStore'
import { a11yProps } from '../../utils/tabsHandle'
import AllotmentHome from '../Allotment/AllotmentHome'
import ManagementSummary from '../DataCollectionHome/DataCollectionHome'
import TabPanel from '../NavBar/TabPanel'

const ManagementDataHome = () => {
  const [value, setValue] = useState(0)
  const [program, setProgram] = useState(null)
  const [programConfig, setProgramConfig] = useState(null)
  const { partialChanges, setPartialChanges, setConfirmationModalConfig } = useContext(AppContext)

  const handleChange = (_event, newValue) => {
    const doChange = () => {
      localStorage.setItem('management-home-last-index', newValue)
      setValue(newValue)
    }

    if (!partialChanges) {
      doChange()
      return
    }

    setConfirmationModalConfig({
      open: true,
      title: 'Cambios sin guardar',
      message: 'Si cambia de pantalla, se perderán los cambios. ¿Desea continuar?',
      confirmLabel: 'Continuar',
      cancelLabel: 'Cancelar',
      confirmAction: () => {
        setPartialChanges(false)
        doChange()
      },
    })
  }

  useEffect(() => {
    // Ultima tab seleccionada
    const lastIndex = localStorage.getItem('management-home-last-index')

    setValue(
      lastIndex && program && programConfig.dataCollectionEnabled ? parseInt(lastIndex, 10) : 0,
    )
  }, [program, programConfig])

  useEffect(() => {
    async function getProgramData() {
      const programData = await lfStore.getItem('program')
      const programConfigData = await lfStore.getItem('programConfig')
      setProgram(programData)
      setProgramConfig(programConfigData)
    }

    getProgramData()
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs aria-label="basic tabs example" gap={2} value={value || 0} onChange={handleChange}>
          <Tab label="Loteo" sx={{ textTransform: 'none' }} {...a11yProps(0)} />
          <Tab
            label="Datos Anualizados"
            sx={{ textTransform: 'none' }}
            {...a11yProps(1)}
            disabled={!program || !programConfig.dataCollectionEnabled}
          />
        </Tabs>
      </Box>
      {/* Tabs Panels */}
      <TabPanel index={0} value={value}>
        <AllotmentHome />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <ManagementSummary />
      </TabPanel>
    </Box>
  )
}

export default ManagementDataHome
