import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useContext } from 'react'
import AppContext from '../../context/appContext'

const RecreateClassificationButton = ({
  children,
  setLoading,
  deleteClassification,
  messageConfirmation,
}) => {
  const { setConfirmationModalConfig } = useContext(AppContext)

  return (
    <Button
      variant="contained"
      onClick={() => {
        setConfirmationModalConfig({
          open: true,
          title: 'Advertencia!',
          message: messageConfirmation,
          confirmLabel: 'Continuar',
          cancelLabel: 'Cancelar',
          confirmAction: () => {
            setLoading(true)

            deleteClassification()

            setLoading(false)
          },
        })
      }}
    >
      {children}
    </Button>
  )
}

const ClassificationButtons = ({
  children,
  clasificationType,
  clasificationAlreadyCreatedMessage,
  nextStepTabIndex,
  nextStepMessage,
  setLoading,
  setDrawActivated,
  setWasSaved,
  editMessageButton,
  deleteClasification,
  deleteMessageConfirmation,
  canEditClasification,
  setRestratification,
  setTabValue,
}) => {
  const { setHideNavbar } = useContext(AppContext)

  return (
    <Box sx={{ padding: '20px', textAlign: 'center' }}>
      <Typography variant="h6">{clasificationType}</Typography>
      <Typography variant="p">{clasificationAlreadyCreatedMessage}</Typography>
      <br />
      <br />
      <Box sx={{ justifyContent: 'center' }}>
        <Button variant="contained" onClick={e => setTabValue(e, nextStepTabIndex)}>
          {nextStepMessage}
        </Button>
        {canEditClasification && (
          <>
            <Button
              sx={{ marginTop: '20px' }}
              variant="text"
              onClick={() => {
                setHideNavbar(true)
                setDrawActivated(true)
                setWasSaved(false)
                setRestratification(true)
              }}
            >
              {editMessageButton}
            </Button>
            <RecreateClassificationButton
              deleteClassification={deleteClasification}
              messageConfirmation={deleteMessageConfirmation}
              setDrawActivated={setDrawActivated}
              setLoading={setLoading}
              setWasSaved={setWasSaved}
            >
              {children}
            </RecreateClassificationButton>
          </>
        )}
      </Box>
    </Box>
  )
}

export default ClassificationButtons
