import endpoints from '../ruutsApi/endpoints'
import getApiData from '../ruutsApi/getApiData'
import updateApiData from '../ruutsApi/updateApiData'

async function getByFarmYear(farmId, year, userRole, token) {
  try {
    const dataCollectionStatements = await getApiData(
      endpoints.dataCollectionStatements,
      {
        farmId,
        year,
        userRole,
      },
      token,
    )
    return dataCollectionStatements[0] ? dataCollectionStatements[0] : null
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get data collection statements')
  }
}

async function getByFarmId(farmId, userRole, token) {
  try {
    const dataCollectionStatements = await getApiData(
      endpoints.dataCollectionStatements,
      {
        farmId,
        userRole,
      },
      token,
    )
    return dataCollectionStatements
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get data collection statements')
  }
}

async function sendReview(dataCollectionStatementsId, userRole, token) {
  try {
    const dataCollectionStatements = await updateApiData(
      `${endpoints.dataCollectionStatements}/review/send/${dataCollectionStatementsId}`,
      {
        userRole,
      },
      token,
    )
    return dataCollectionStatements
  } catch (error) {
    console.error(error)
    throw new Error('Unable to send to review data collection statement')
  }
}

async function startReview(dataCollectionStatementsId, userRole, token) {
  try {
    const dataCollectionStatements = await updateApiData(
      `${endpoints.dataCollectionStatements}/review/start/${dataCollectionStatementsId}`,
      {
        userRole,
      },
      token,
    )
    return dataCollectionStatements
  } catch (error) {
    console.error(error)
    throw new Error('Unable to send to review data collection statement')
  }
}

async function approve(dataCollectionStatementsId, token) {
  try {
    const dataCollectionStatements = await updateApiData(
      `${endpoints.dataCollectionStatements}/approve/${dataCollectionStatementsId}`,
      {},
      token,
    )
    return dataCollectionStatements
  } catch (error) {
    console.error(error)
    throw new Error('Unable to approve the data collection statement')
  }
}

async function changeOwnership(dataCollectionStatementsId, userRole, token) {
  try {
    const dataCollectionStatements = await updateApiData(
      `${endpoints.dataCollectionStatements}/ownership/change/${dataCollectionStatementsId}`,
      {
        userRole,
      },
      token,
    )
    return dataCollectionStatements
  } catch (error) {
    console.error(error)
    throw new Error('Unable to change data collection statement ownership')
  }
}

export const dataCollectionStatementService = {
  getByFarmYear,
  getByFarmId,
  sendReview,
  startReview,
  changeOwnership,
  approve,
}
