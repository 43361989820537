import endpoints from '../ruutsApi/endpoints'
import postApiData from '../ruutsApi/postApiData'

export const saveOtherPolygons = async (currentFarm, data, token) => {
  try {
    const otherPolygonBody = {
      name: data.properties.name,
      farmId: currentFarm.id,
      geometry: data.geometry,
      color: 'hsl(300, 76%, 72%)',
    }
    const otherPolygonStatus = await postApiData(endpoints.otherPolygons, otherPolygonBody, token)
    return otherPolygonStatus
  } catch (error) {
    console.error({ error })
    return null
  }
}
