import L from 'leaflet'
import 'leaflet-draw/dist/leaflet.draw.css'
import markerIconPngBlue from 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/leaflet.css'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { FeatureGroup, LayersControl, useMap } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import AppContext from '../../context/appContext'
import { GeoJSON } from '../../utils/GeoJSON/index'
import markerIconPngOrange from '../../utils/marker-icons/marker-icon-orange.png'
import markerIconPngRed from '../../utils/marker-icons/marker-icon-red.png'
import markerIconPngYellow from '../../utils/marker-icons/marker-icon-yellow.png'
import markerIconShadow from '../../utils/marker-icons/marker-shadow.png'

const DrawingLayers = ({
  drawingType,
  center,
  etapa,
  perimeter,
  handlePerimeter,
  paddocks,
  samplingAreas,
  monitoringSites,
  unassigned,
  unassignedPoints,
  exclusionAreas,
  otherPolygons,
  otherSites,
}) => {
  const map = useMap()
  const { currentFarm, setPartialChanges } = useContext(AppContext)

  const perimeterRef = useRef()
  const paddocksRef = useRef()
  const samplingAreasRef = useRef()
  const exclusionAreasRef = useRef()
  const monitoringSitesRef = useRef()
  const unassignedRef = useRef()
  const unassignedPointsRef = useRef()
  const otherPolygonsRef = useRef()
  const otherSitesRef = useRef()

  const handleCreate = () => {
    const perimeterFeatures = []
    perimeterRef.current.eachLayer(layer => {
      const perimeterFeature = layer.toGeoJSON()

      if (perimeterFeature.type === 'Feature') {
        perimeterFeature.properties = {
          name: currentFarm.name,
          area: GeoJSON.hectareArea(layer.toGeoJSON()),
          featureGroup: 'perimeter',
        }
        perimeterFeatures.push(perimeterFeature)
      }

      if (perimeterFeature.type === 'FeatureCollection') {
        perimeterFeature.features.forEach(feature => {
          perimeterFeatures.push(feature)
        })
      }
    })
    handlePerimeter(perimeterFeatures)
    setPartialChanges(true)
  }

  const pointToLayer = (feature, latlng) => {
    const type = feature?.properties?.featureGroup

    const icon = L.icon({
      iconUrl: feature.properties.selected
        ? markerIconPngYellow
        : type === 'unassignedPoints'
          ? markerIconPngRed
          : type === 'otherSites'
            ? markerIconPngOrange
            : markerIconPngBlue,
      shadowUrl: markerIconShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [0, -32],
    })

    const marker = L.marker(latlng, { icon })
    marker.bindPopup(feature.properties.name)
    return marker
  }

  const cropElementByPerimeter = useCallback(
    element => {
      let elementInsidePerimeter = element
      perimeter.forEach(perimeterItem => {
        elementInsidePerimeter = GeoJSON.intersect(elementInsidePerimeter, perimeterItem)
      })
      return elementInsidePerimeter
    },
    [perimeter],
  )

  useEffect(() => {
    if (map && center) {
      map.setView(center, 13)
    }
  }, [center, map])

  function getCommonFeatureStyles(feature, color) {
    return {
      fillColor: color,
      color: feature.properties.selected ? 'yellow' : color,
      weight: feature.properties.selected ? 4 : 2,
      opacity: 1,
      fillOpacity: 0.2,
    }
  }

  // Perimeter
  useEffect(() => {
    if (map && perimeterRef.current && perimeter?.length) {
      perimeterRef.current.clearLayers()
      perimeter.forEach(element => {
        L.geoJSON(element, getCommonFeatureStyles(element, 'yellow'))
          .bindPopup(element.properties.name)
          .addTo(perimeterRef.current)
      })
      map.fitBounds(perimeterRef.current.getBounds())
    } else if (map && perimeterRef.current && !perimeter) {
      perimeterRef.current.clearLayers()
    }
  }, [map, perimeter])

  // Paddocks
  useEffect(() => {
    if (map && paddocksRef.current && paddocks) {
      paddocksRef.current.clearLayers()
      paddocks.forEach(element => {
        const elementCroppedByPerimeter = cropElementByPerimeter(element)
        L.geoJSON(elementCroppedByPerimeter, getCommonFeatureStyles(element, 'purple'))
          .bindPopup(element.properties.name)
          .addTo(paddocksRef.current)
      })
    } else if (map && paddocksRef.current && !paddocks) {
      paddocksRef.current.clearLayers()
    }
  }, [map, paddocks, cropElementByPerimeter])

  // Sampling Areas
  useEffect(() => {
    if (map && samplingAreasRef.current && samplingAreas) {
      samplingAreasRef.current.clearLayers()
      samplingAreas.forEach(element => {
        const elementCroppedByPerimeter = cropElementByPerimeter(element)
        L.geoJSON(
          elementCroppedByPerimeter,
          getCommonFeatureStyles(element, element.properties.color),
        )
          .bindPopup(element.properties.name)
          .addTo(samplingAreasRef.current)
      })
    } else if (map && samplingAreasRef.current && !samplingAreas) {
      samplingAreasRef.current.clearLayers()
    }
  }, [cropElementByPerimeter, map, samplingAreas])

  // Exclusion Areas
  useEffect(() => {
    if (map && exclusionAreasRef.current && exclusionAreas) {
      exclusionAreasRef.current.clearLayers()
      exclusionAreas.forEach(element => {
        L.geoJSON(element, getCommonFeatureStyles(element, 'black'))
          .bindPopup(element.properties.name)
          .addTo(exclusionAreasRef.current)
      })
    } else if (map && exclusionAreasRef.current && !exclusionAreas) {
      exclusionAreasRef.current.clearLayers()
    }
  }, [map, exclusionAreas])

  // Unassigned
  useEffect(() => {
    if (map && unassignedRef.current && unassigned) {
      unassignedRef.current.clearLayers()
      unassigned.forEach(element => {
        L.geoJSON(element, getCommonFeatureStyles(element, 'red'))
          .bindPopup(element.properties.name)
          .addTo(unassignedRef.current)
      })
    } else if (map && unassignedRef.current && !unassigned) {
      unassignedRef.current.clearLayers()
    }
  }, [map, unassigned])

  // Other polygons
  useEffect(() => {
    if (map && otherPolygonsRef.current && otherPolygons) {
      otherPolygonsRef.current.clearLayers()
      otherPolygons.forEach(element => {
        L.geoJSON(element, getCommonFeatureStyles(element, 'pink'))
          .bindPopup(element.properties.name)
          .addTo(otherPolygonsRef.current)
      })
    } else if (map && otherPolygonsRef.current && !otherPolygons) {
      otherPolygonsRef.current.clearLayers()
    }
  }, [map, otherPolygons])

  // Monitoring Sites
  useEffect(() => {
    if (map && monitoringSitesRef.current && monitoringSites) {
      monitoringSitesRef.current.clearLayers()
      monitoringSites.forEach(element => {
        L.geoJSON(element, {
          pointToLayer,
        })
          .bindPopup(element.properties.name)
          .addTo(monitoringSitesRef.current)
      })
    } else if (map && monitoringSitesRef.current && !monitoringSites) {
      monitoringSitesRef.current.clearLayers()
    }
  }, [map, monitoringSites])

  // Unassigned points
  useEffect(() => {
    if (map && unassignedPointsRef.current && unassignedPoints) {
      unassignedPointsRef.current.clearLayers()
      unassignedPoints.forEach(element => {
        L.geoJSON(element, {
          pointToLayer,
        })
          .bindPopup(element.properties.name)
          .addTo(unassignedPointsRef.current)
      })
    } else if (map && unassignedPointsRef.current && !unassignedPoints) {
      unassignedPointsRef.current.clearLayers()
    }
  }, [map, unassignedPoints])

  // Other sites
  useEffect(() => {
    if (map && otherSitesRef.current && otherSites) {
      otherSitesRef.current.clearLayers()
      otherSites.forEach(element => {
        L.geoJSON(element, {
          pointToLayer,
        })
          .bindPopup(element.properties.name)
          .addTo(otherSitesRef.current)
      })
    } else if (map && otherSitesRef.current && !otherSites) {
      otherSitesRef.current.clearLayers()
    }
  }, [map, otherSites])

  return (
    <>
      {/* Unassigned */}
      <LayersControl.Overlay checked name="Area sin asignar">
        <FeatureGroup
          ref={unassignedRef}
          init_info={{
            farmLayerFG: 'FG_unassigned',
          }}
          pathOptions={{
            color: 'red',
            fillColor: 'red',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.2,
          }}
        />
      </LayersControl.Overlay>

      {/* Unassigned Points */}
      <LayersControl.Overlay checked name="Sitios sin asignar">
        <FeatureGroup
          ref={unassignedPointsRef}
          init_info={{
            farmLayerFG: 'FG_unassignedPoints',
          }}
          pathOptions={{
            color: 'red',
            fillColor: 'red',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.2,
          }}
        />
      </LayersControl.Overlay>

      {/* 
        Perímetro:
        - capa de carga por Api o por Archivo
        - capa de dibujo (si no hay capa de carga, es la unica que dibuja)
      */}

      <LayersControl.Overlay checked name="Perímetro">
        <FeatureGroup
          ref={perimeterRef}
          init_info={{
            farmLayerFG: 'FG_perimeter',
          }}
          pathOptions={{
            color: 'yellow',
            fillColor: 'yellow',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.2,
          }}
        >
          {drawingType === 'perimeter' && etapa === 'drawingStarted' ? (
            <EditControl
              draw={{
                rectangle: false,
                circle: false,
                polyline: false,
                circlemarker: false,
                marker: false,
                polygon: {
                  allowIntersection: false,
                  showArea: true,
                },
              }}
              edit={{
                edit: false,
                remove: false,
              }}
              position="topleft"
              onCreated={handleCreate}
            />
          ) : null}
        </FeatureGroup>
      </LayersControl.Overlay>

      {/* Paddocks o Lotes */}
      <LayersControl.Overlay checked name="Lotes">
        <FeatureGroup
          ref={paddocksRef}
          init_info={{
            farmLayerFG: 'FG_paddocks',
          }}
          pathOptions={{
            color: 'purple',
            fillColor: 'purple',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.2,
          }}
        />
      </LayersControl.Overlay>

      {/* SamplingAreas o Estratos */}
      <LayersControl.Overlay checked name="Estratos">
        <FeatureGroup
          ref={samplingAreasRef}
          init_info={{
            farmLayerFG: 'FG_estratas',
          }}
          pathOptions={{
            weight: 2,
            opacity: 1,
            fillOpacity: 0.2,
          }}
        />
      </LayersControl.Overlay>

      {/* Zonas de exclusión */}
      <LayersControl.Overlay checked name="Áreas de exclusión">
        <FeatureGroup
          ref={exclusionAreasRef}
          init_info={{
            farmLayerFG: 'FG_exclusionAreas',
          }}
          pathOptions={{
            color: 'black',
            fillColor: 'black',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.2,
          }}
        />
      </LayersControl.Overlay>

      {/* Otras areas */}
      <LayersControl.Overlay checked name="Otras áreas">
        <FeatureGroup
          ref={otherPolygonsRef}
          init_info={{
            farmLayerFG: 'FG_otherPolygons',
          }}
          pathOptions={{
            color: 'pink',
            fillColor: 'pink',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.2,
          }}
        />
      </LayersControl.Overlay>

      {/* Sitios de monitoreo */}
      <LayersControl.Overlay checked name="Sitios de monitoreo">
        <FeatureGroup
          ref={monitoringSitesRef}
          init_info={{
            farmLayerFG: 'FG_monitoringSites',
          }}
          pathOptions={{
            color: 'white',
            fillColor: 'white',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.2,
          }}
        />
      </LayersControl.Overlay>

      {/* Otros sitios */}
      <LayersControl.Overlay checked name="Otros sitios">
        <FeatureGroup
          ref={otherSitesRef}
          init_info={{
            farmLayerFG: 'FG_otherSites',
          }}
          pathOptions={{
            color: 'orange',
            fillColor: 'orange',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.2,
          }}
        />
      </LayersControl.Overlay>
    </>
  )
}

export default DrawingLayers
