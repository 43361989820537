import { messagesKeys } from './messages.js'

function getLangKey() {
  // Default to es_AR if the language is not found
  return localStorage?.getItem('lang') || 'es_AR'
}
// Function to get a message based on language key and message key
function getMessage(messages, replacementArray = []) {
  const langKey = getLangKey()
  let message = messages[langKey]
  if (!message) {
    // Default to es_AR if the message language is not found.
    // This will prevent to load all the messages in all languages
    // For example we don't need to load all the message for Paraguay,
    // but we can't set an specific message for Paraguay
    message = messages.es_AR
    // If there is no message in es_AR, return fixed not found message
    if (!message) return `Message not found for ${JSON.stringify(messages)}`
  }

  // Replace wildcards with values from the array
  // For example: `This is a %1 and %2 wildcards to replace based on each element of the array`, ['V1', 'V2']
  return message.replace(/%(\d+)/g, (match, number) => {
    const index = parseInt(number, 10) - 1
    const replacement = replacementArray[index]

    // If there is no replacement, return the original match
    if (!replacement) return match

    // If the replacement is an object, return the value based on the language key
    return typeof replacement !== 'object'
      ? replacement
      : replacement?.[langKey] || replacement?.es_AR
  })
}

export const messages = {
  keys: messagesKeys,
  getMessage,
  getLangKey,
}
