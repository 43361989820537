import { DCNamespaces } from '../namespaces'
import getUnusedPaddocks from '../../../services/farmMapping/getUnusedPaddocks'

export async function unusedRequiredPaddocks(farm, year, token) {
  try {
    const unusedPaddocks = await getUnusedPaddocks(farm, year, DCNamespaces.paddocks, token)
    const disabled = Boolean(unusedPaddocks?.length)

    return {
      disabled,
      totalError: 0,
      error: disabled
        ? 'Existen lotes sin uso. Se habilitará cuando cargue el uso a todos los lotes.'
        : null,
    }
  } catch (error) {
    // The unused required paddocks is required, so if there is an error loading it, the tab is disabled
    return {
      disabled: true,
      totalError: 0,
      error: 'No se pudo cargar la información del uso de lotes. Intente nuevamente.',
    }
  }
}

export async function unusedWarningPaddocks(farm, year, token) {
  try {
    const unusedPaddocks = await getUnusedPaddocks(farm, year, DCNamespaces.paddocks, token)

    return {
      disabled: false,
      totalError: unusedPaddocks.length,
      error: unusedPaddocks?.length
        ? 'Existen lotes sin uso. Se habilitará la carga de Pastoreo cuando cargue el uso a todos los lotes.'
        : null,
    }
  } catch (error) {
    // The unused warning paddocks is optional, so if there is an error loading it the tab is enabled
    return {
      disabled: false,
      totalError: 0,
      error: 'No se pudo cargar la información del uso de lotes. Intente nuevamente.',
    }
  }
}
