import endpoints from '../../ruutsApi/endpoints'
import updateApiData from '../../ruutsApi/updateApiData'
import { postApiDataWithPayloadResponse } from '../../ruutsApi/postApiData'
import { getRandomColor } from '../../../utils/mapDrawHandle'

function featuresFarmToEntities({ farmId, features }) {
  return features.map(feature => ({
    farmId,
    geometry: feature.geometry,
    id: feature?.properties?.id,
    name: feature.properties.name,
    color: feature.properties.color ? feature.properties.color : getRandomColor(),
  }))
}

export const createSamplingAreasFeatureByFarm = async ({ farmId, features, token }) => {
  if (!features?.length) return []

  const featuresToCreate = featuresFarmToEntities({ farmId, features })
  return postApiDataWithPayloadResponse(endpoints.samplingAreas, featuresToCreate, token)
}

export const updateSamplingAreasFeatureByFarm = async ({ farmId, features, token }) => {
  if (!features?.length) return []

  const featuresToUpdate = featuresFarmToEntities({ farmId, features })
  return updateApiData(endpoints.samplingAreas, featuresToUpdate, token)
}
