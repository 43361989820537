import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocation } from 'react-router'
import GlobalConfirmationModal, {
  ModalTypes,
} from './components/GlobalConfirmationModal/GlobalConfirmationModal'
import GlobalLoadingModal, {
  displayErrorMessageModes,
} from './components/GlobalLoadingModal/GlobalLoadingModal'
import NavBar from './components/NavBar/NavBar'
import AppContext from './context/appContext'
import AppRoutes from './routes'
import { themeOptions } from './themes/main.js'
import getNavbarTheme from './themes/navbar/getNavbarTheme.js'
import { ErrorFallback } from './utils/Errors/ErrorFallback'

const theme = createTheme(themeOptions)

const App = () => {
  const [user, setUser] = useState({})
  const [userRoles, setUserRoles] = useState([])
  const [userRoleSelected, setUserRoleSelected] = useState()
  const [currentFarm, setCurrentFarm] = useState()
  const [programConfig, setProgramConfig] = useState()
  const [hideNavbar, setHideNavbar] = useState()
  const [confirmationModalConfig, setConfirmationModalConfig] = useState({
    open: false,
  })
  const [loadingModalConfig, setLoadingModalConfig] = useState({
    open: false,
  })
  const [partialChanges, setPartialChanges] = useState(false)
  const [innerTab, setInnerTab] = useState(null)
  const [navbarTheme, setNavbarTheme] = useState(null)
  const location = useLocation()

  useEffect(() => {
    const newNavbarTheme = createTheme(getNavbarTheme())
    setNavbarTheme(newNavbarTheme)
  }, [])

  const appContextValue = useMemo(
    () => ({
      user,
      setUser,
      userRoles,
      setUserRoles,
      userRoleSelected,
      setUserRoleSelected,
      currentFarm,
      setCurrentFarm,
      programConfig,
      setProgramConfig,
      hideNavbar,
      setHideNavbar,
      confirmationModalConfig,
      setConfirmationModalConfig,
      loadingModalConfig,
      setLoadingModalConfig,
      partialChanges,
      setPartialChanges,
      innerTab,
      setInnerTab,
    }),
    [
      user,
      setUser,
      userRoles,
      setUserRoles,
      userRoleSelected,
      setUserRoleSelected,
      currentFarm,
      setCurrentFarm,
      programConfig,
      setProgramConfig,
      hideNavbar,
      setHideNavbar,
      confirmationModalConfig,
      setConfirmationModalConfig,
      loadingModalConfig,
      setLoadingModalConfig,
      partialChanges,
      setPartialChanges,
      innerTab,
      setInnerTab,
    ],
  )

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={appContextValue}>
        <GlobalConfirmationModal
          cancelLabel={confirmationModalConfig.cancelLabel || 'Cancelar'}
          confirmLabel={confirmationModalConfig.confirmLabel || 'Continuar'}
          handleClose={
            confirmationModalConfig.handleClose ||
            (() => setConfirmationModalConfig({ open: false }))
          }
          handleConfirm={confirmationModalConfig.confirmAction || null}
          message={
            confirmationModalConfig.message ||
            'Si cambia de pantalla, se perderán los cambios. ¿Desea continuar?'
          }
          modalType={confirmationModalConfig.modalType || ModalTypes.QuestionModalType}
          open={confirmationModalConfig.open || false}
          requiredTextField={confirmationModalConfig.requiredTextField || null}
          title={confirmationModalConfig.title || 'Cambios sin guardar'}
        >
          {confirmationModalConfig.children || null}
        </GlobalConfirmationModal>
        <SnackbarProvider>
          <GlobalLoadingModal
            dialogMessage={loadingModalConfig.dialogMessage || null}
            displayErrorMessageMode={
              loadingModalConfig.displayErrorMessageMode || displayErrorMessageModes.snackbar
            }
            dryRun={loadingModalConfig.dryRun || false}
            error={loadingModalConfig.error || null}
            errorMessage={loadingModalConfig.errorMessage || null}
            open={loadingModalConfig.open || false}
            successfulMessage={loadingModalConfig.successfulMessage || null}
            title={loadingModalConfig.title || 'Espere por favor...'}
          />
          <CssBaseline />
          <Box
            sx={{
              height: '100vh',
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {navbarTheme && (
              <ThemeProvider theme={navbarTheme}>
                <NavBar />
              </ThemeProvider>
            )}
            <ErrorBoundary key={location?.pathname} FallbackComponent={ErrorFallback}>
              <AppRoutes />
            </ErrorBoundary>
          </Box>
        </SnackbarProvider>
      </AppContext.Provider>
    </ThemeProvider>
  )
}

export default App
