const fertilizantesOrganicos = {
  namespace: 'paddock_organic_fertilizers',
  label: 'Fertilizantes orgánicos',
  required: false,
  requiredMessage: 'No aplica: declaro no haber utilizado fertilizantes orgánicos este año',
  layout: {
    showMap: true,
    showTable: true,
  },
  fields: [
    {
      component_type: 'Title',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Uso de fertilizantes orgánicos',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'InfoText',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Datos de consumo de fertilizantes orgánicos para todo el establecimiento.',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'FieldArray',
      required: false,
      name: 'organicFertilizers',
      label: 'Fertilizante Orgánico',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      dependencies: {
        disabled: [],
        display: [],
      },
      fields: [
        {
          component_type: 'Select',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
          name: 'organicFertilizerTypeId',
          placeholder: 'Tipo de fertilizante orgánico',
          label: 'Tipo de fertilizante orgánico',
          defaultValue: 0,
          fullWidth: true,
          required: true,
          dependencies: {
            disabled: [],
            display: [],
          },
          refDataKey: 'RefOrganicFertilizerType',
        },
        {
          component_type: 'TextField',
          type: 'number',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
          name: 'organicFertilizerByHectare',
          unit: 'Kg/Ha',
          placeholder: 'Cantidad',
          label: 'Cantidad',
          defaultValue: 0,
          min: 0,
          fullWidth: true,
          required: true,
          dependencies: {
            disabled: [],
            display: [],
          },
        },
      ],
    },
  ],
}

export default fertilizantesOrganicos
