export const findingsTypesConventions = {
  observed: {
    id: 0,
    label: 'Observación',
    color: '#e9e925',
  },
  nonComplaintToFix: {
    id: 1,
    label: 'No Conformidad a Corregir',
    color: '#ffa500',
  },
  nonComplaintSevere: {
    id: 2,
    label: 'No Conformidad Grave',
    color: '#ff0000',
  },
  resolved: {
    id: 99,
    label: 'Resuelta',
    color: '#2e7d32',
  },
}
