export const dataCollectionStatementTypesConventions = [
  {
    id: 0,
    type: 'Pending',
    color: '#BBC8F7',
  },
  {
    id: 1,
    type: 'InProcess',
    color: '#BFCAC9',
  },
  {
    id: 2,
    type: 'ToReview',
    color: '#AAE9F6',
  },
  {
    id: 3,
    type: 'InReview',
    color: '#3EDFC2',
  },
  {
    id: 4,
    type: 'Observed',
    color: '#FFCD29',
  },
  {
    id: 5,
    type: 'Approved',
    color: '#8CD219',
  },
  {
    id: 6,
    type: 'FullApproved',
    color: '#6EDB18',
  },
  {
    id: 7,
    type: 'NonCompliant',
    color: '#ff0000',
  },
]
