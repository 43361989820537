import { useCallback, useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import AppContext from '../../context/appContext'
import { processLoading } from './processLoading'

export function useProcessLoading() {
  const { getAccessTokenSilently } = useAuth0()
  const { setLoadingModalConfig } = useContext(AppContext)

  const handleProcessLoading = useCallback(
    async ({ doAction, loadingMessage, errorMessage, successfulMessage = '' }) =>
      processLoading({
        getToken: getAccessTokenSilently,
        setLoadingModalConfig,
        loadingMessage,
        successfulMessage,
        errorMessage,
        doAction,
      }),
    [getAccessTokenSilently, setLoadingModalConfig],
  )

  return { processLoading: handleProcessLoading }
}
