export function geoJSONFromGeometry({ geometry }) {
  return {
    type: 'Feature',
    properties: {},
    geometry,
  }
}

export function getFeatureCollection(features) {
  return {
    type: 'FeatureCollection',
    features,
  }
}
