import { area, hectareArea } from './area.js'
import { getGeoJSONFromBounds } from './bounds.js'
import { intersect } from './intersect.js'
import { difference } from './difference.js'
import { truncate } from './truncate.js'
import { booleanContains } from './boolean-contains.js'
import { distance } from './distance.js'
import { geoJSONFromGeometry, getFeatureCollection } from './geometry.js'

export const GeoJSON = {
  area,
  hectareArea,
  getGeoJSONFromBounds,
  intersect,
  difference,
  truncate,
  booleanContains,
  distance,
  geoJSONFromGeometry,
  getFeatureCollection,
}
