import { useTheme } from '@emotion/react'
import { Stack, Typography } from '@mui/material'

const RuutsLogo = () => {
  const theme = useTheme()

  return (
    <Stack>
      <img
        alt="Ruuts Logo"
        src={theme.logo.default}
        style={{
          display: 'flex',
          maxWidth: '50px',
        }}
      />
      {theme.logo.isTraining && <Typography color="#FFFFFF">Training</Typography>}
    </Stack>
  )
}

export default RuutsLogo
