/* eslint-disable no-shadow */
import { useAuth0 } from '@auth0/auth0-react'
import { Close } from '@mui/icons-material'
import { Alert, Box, Button, Grid, Modal, Snackbar, Stack, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import AppContext from '../../context/appContext.js'
import { getFarmManagementSupportiveDocumentationFormDefinition } from '../../services/ruutsApi/formDefinitions/getFormDefinitions.js'
import { ruutsApi } from '../../services/ruutsApi/index.js'
import { processLoading } from '../../utils/Loading/processLoading.js'
import DynamicFieldSwitcher from '../DynamicForm/DynamicFieldSwitcher.jsx'
import { checkDependencies, parseErrors } from '../DynamicForm/utils.js'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}

const farmSubdivisionEntityTypeId = 0

const FarmSubdivisionDocumentsDialog = ({
  readOnly,
  farmSubdivisionId,
  year,
  open,
  handleClose,
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    setLoadingModalConfig,
    programConfig,
    partialChanges,
    setPartialChanges,
    setConfirmationModalConfig,
  } = useContext(AppContext)
  const [formData, setFormData] = useState(null)
  const [formDefinition, setFormDefinition] = useState(null)
  const [documentsFromEntity, setDocumentsFromEntity] = useState(null)
  const [fieldInputsFromFormDefinition, setFieldInputsFromFormDefinition] = useState(null)

  useEffect(() => {
    async function getFormDefinitions() {
      await processLoading({
        getToken: getAccessTokenSilently,
        setLoadingModalConfig,
        loadingMessage: 'Cargando documentación por año...',
        successfulMessage: '',
        doAction: async ({ token }) => {
          const documentsFormDefinition =
            await getFarmManagementSupportiveDocumentationFormDefinition(programConfig, token)

          // Get documents types from file input types
          const fieldInputsFromFormDefinition = documentsFormDefinition.fields.filter(
            field => field.component_type === 'FileInput',
          )
          const documentsTypesFromFormDefinition = fieldInputsFromFormDefinition.map(
            field => field.fileType,
          )

          // Get documents from entity
          const documentsFromEntity = await ruutsApi.documents.getByEntityAndDocumentsTypes({
            entityId: farmSubdivisionId,
            entityTypeId: farmSubdivisionEntityTypeId,
            documentTypes: documentsTypesFromFormDefinition,
            token,
          })

          // Generate form data from documents entities
          const formDataFromDocumentsEntity = {}
          fieldInputsFromFormDefinition.forEach(fieldInputFromFormDefinition => {
            const documentFromEntityInFieldDefinition = documentsFromEntity
              .filter(documentFromEntity => {
                return fieldInputFromFormDefinition.fileType === documentFromEntity.documentTypeId
              })
              .map(documentFromEntity => documentFromEntity.url)
            formDataFromDocumentsEntity[fieldInputFromFormDefinition.name] =
              documentFromEntityInFieldDefinition
          })

          setDocumentsFromEntity(documentsFromEntity)
          setFormData(formDataFromDocumentsEntity)
          setFormDefinition(documentsFormDefinition)
          setFieldInputsFromFormDefinition(fieldInputsFromFormDefinition)
        },
      })
    }

    if (open && farmSubdivisionId) {
      getFormDefinitions()
    }
  }, [open, programConfig, getAccessTokenSilently, farmSubdivisionId, setLoadingModalConfig])

  const handlePartialChangesClose = () => {
    handleClose()
    setPartialChanges(false)
  }

  const handleSaveFormData = async data => {
    await processLoading({
      getToken: getAccessTokenSilently,
      setLoadingModalConfig,
      loadingMessage: 'Guardando documentos...',
      successfulMessage: 'Documentos guardados',
      doAction: async ({ token }) => {
        // Convert file inputs data to documents entities
        const documentsArraysFromFileInput = []
        Object.keys(data).forEach(fileInputType => {
          const fileInputURLByType = data[fileInputType]
          if (fileInputURLByType && fileInputURLByType.length) {
            documentsArraysFromFileInput.push(
              fileInputURLByType.map(documentURL => {
                const fileInputFileByName = fieldInputsFromFormDefinition.find(
                  fieldInputFromFormDefinition => {
                    return fieldInputFromFormDefinition.name === fileInputType
                  },
                )
                return {
                  documentTypeId: fileInputFileByName.fileType,
                  entityTypeId: farmSubdivisionEntityTypeId,
                  entityId: farmSubdivisionId,
                  url: documentURL,
                }
              }),
            )
          }
        })
        const documentsFromFileInput = [].concat(...documentsArraysFromFileInput)

        // Filter documents action to save in API
        const documentsToUpdate = []
        const documentsToCreate = []
        documentsFromFileInput.forEach(documentFromFileInput => {
          const documentOriginalLoaded = documentsFromEntity.find(documentFromEntity => {
            return documentFromEntity.url === documentFromFileInput.url
          })
          if (!documentOriginalLoaded) {
            documentsToCreate.push(documentFromFileInput)
          } else {
            documentsToUpdate.push({ ...documentOriginalLoaded })
          }
        })

        // Filter documents to deleted from file input and the original documents saved
        const documentsToDelete = documentsFromEntity.filter(documentFromEntity => {
          return documentsFromFileInput.every(documentFromFileInput => {
            return documentFromEntity.url !== documentFromFileInput.url
          })
        })

        if (documentsToCreate.length)
          await ruutsApi.documents.create({ document: documentsToCreate, token })
        if (documentsToUpdate.length)
          await ruutsApi.documents.update({ document: documentsToUpdate, token })
        if (documentsToDelete.length)
          await ruutsApi.documents.remove({ documents: documentsToDelete, token })

        handlePartialChangesClose()
      },
    })
  }

  const handleCloseAndReset = () => {
    if (partialChanges) {
      setConfirmationModalConfig({
        open: true,
        confirmAction: () => {
          handlePartialChangesClose()
        },
      })
    } else {
      handleClose()
    }
  }

  return (
    formDefinition &&
    formDefinition.fields &&
    formDefinition.fields.length > 0 &&
    formData &&
    documentsFromEntity &&
    fieldInputsFromFormDefinition && (
      <FormDefinitionDialog
        data={formData}
        formDefinition={formDefinition}
        handleClose={handleCloseAndReset}
        open={open}
        readOnly={readOnly}
        saveFormData={handleSaveFormData}
        title={`Año ${year}`}
        year={year}
      />
    )
  )
}

const FormDefinitionDialog = ({
  title,
  readOnly,
  year,
  formDefinition,
  open,
  saveFormData,
  handleClose,
  data,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    defaultValues: data,
  })

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid container>
          {title && (
            <Grid item sx={{ textAlign: 'left' }} xs={11}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                {title}
              </Typography>
            </Grid>
          )}

          <Grid item xs={1}>
            <Close
              sx={{
                float: 'right',
                margin: 1,
                cursor: 'pointer',
                '&:hover': {
                  color: 'red',
                },
              }}
              onClick={handleClose}
            />
          </Grid>

          <Grid item sx={{ textAlign: 'left' }} xs={12}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {formDefinition.label}
            </Typography>
          </Grid>

          <Grid item sx={{ maxHeight: '80vh', overflowY: 'scroll' }} xs={12}>
            <FormProvider {...{ watch, control, reset, setError }}>
              <Box
                component="form"
                pb={2}
                pl={2}
                pr={2}
                pt={1}
                onSubmit={handleSubmit(saveFormData)}
              >
                {!watch('notApplicable') && (
                  <fieldset style={{ color: 'inherit' }}>
                    <Grid container spacing={1}>
                      {formDefinition.fields.map((formField, index) => (
                        <DynamicFieldSwitcher
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          checkDependencies={checkDependencies}
                          control={control}
                          formField={formField}
                          readOnly={readOnly}
                          year={year}
                        />
                      ))}
                    </Grid>
                  </fieldset>
                )}
              </Box>
              <Stack direction="column" display="flex" position="sticky" spacing={1}>
                {errors && Object.keys(errors).length > 0 && (
                  <Snackbar
                    key="error-snackbar"
                    autoHideDuration={5000}
                    open={!!errors}
                    onClose={() => clearErrors()}
                  >
                    <Alert
                      severity="error"
                      onClose={() => {
                        clearErrors()
                      }}
                    >
                      {parseErrors(errors)[0]}
                    </Alert>
                  </Snackbar>
                )}
              </Stack>

              <Stack direction="column" display="flex" position="sticky" spacing={1}>
                <Button
                  disabled={readOnly}
                  size="small"
                  variant="contained"
                  onClick={handleSubmit(saveFormData)}
                >
                  GUARDAR
                </Button>
              </Stack>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default FarmSubdivisionDocumentsDialog
