import ConfirmationModal from './ConfirmationModal'

const CategorizationConfirm = ({
  open,
  handleClose,
  handleConfirm,
  buttonValue,
  loading = false,
}) => {
  return (
    <ConfirmationModal
      buttonValue={buttonValue}
      cancelLabel="Continuar editando"
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      loading={loading}
      message="Una vez que guarde, el Perímetro no podrá ser modificado. El resto de las áreas serán editables en sus secciones correspondientes."
      open={open}
      saveLabel="Guardar áreas"
      title="Guardar Áreas Categorizadas"
    />
  )
}

export default CategorizationConfirm
