import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const ExampleFileDownloader = () => {
  return (
    <Box m={2}>
      <Typography
        component="span"
        sx={{
          fontSize: 14,
          m: 2,
          mt: 4,
          fontStyle: 'italic',
        }}
      >
        Para crear el KML de su establecimiento en GoogleEarth y utilizarlo de manera óptima en esta
        plataforma,{' '}
        <span>
          <Link download target="_blank" to="/kml-example/Ruuts_-_KML_Base.kml">
            descargue la Plantilla
          </Link>
        </span>
      </Typography>
    </Box>
  )
}

export default ExampleFileDownloader
