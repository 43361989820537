import { useContext, useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Container } from '@mui/material'
// eslint-disable-next-line no-unused-vars
import tableau from 'tableau-api' // Aunque parezca no usarse, es necesario para que funcione el componente tableau-viz
import getApiData from '../../services/ruutsApi/getApiData'
import AppContext from '../../context/appContext'

const TableauView = () => {
  const { getAccessTokenSilently, user } = useAuth0()
  const [tableauToken, setTableauToken] = useState(null)
  const { setHideNavbar } = useContext(AppContext)
  const viz = useRef()

  // Al usar la URL de Tableau, cambiar "/#/site/" por "/t/"
  const url =
    'https://us-west-2b.online.tableau.com/t/ruutsreporting/views/SeguimientoDataCollectionv1/Dashboard2?:toolbar=no'

  useEffect(() => {
    setHideNavbar(true)
  }, [setHideNavbar])

  useEffect(() => {
    if (!user['/roles'].includes('Auditor')) {
      window.location.href = '/'
    }
  }, [user])

  useEffect(() => {
    async function getTableauToken() {
      const apiToken = await getAccessTokenSilently()
      const authData = await getApiData('/v1/auth/tableau/token', null, apiToken)
      setTableauToken(authData.token)
    }
    getTableauToken()
  }, [getAccessTokenSilently])

  return (
    tableauToken && (
      <Container
        maxWidth={false}
        sx={{
          height: '100vh',
          width: '100vw',
          padding: 0,
          margin: 0,
          overflow: 'hidden',
        }}
      >
        <tableau-viz
          ref={viz}
          hide-tabs
          id="tableauViz"
          src={url}
          token={tableauToken}
          toolbar="hidden"
        />
      </Container>
    )
  )
}

export default TableauView
