const lotes = {
  namespace: 'paddock_production',
  label: 'Lotes',
  required: true,
  layout: {
    showMap: true,
    showTable: true,
  },
  fields: [
    {
      component_type: 'Title',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Uso anual de lotes',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'InfoText',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Ingrese datos por lote o conjunto de lotes con el mismo uso.',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'Select',
      name: 'fieldUsageId',
      placeholder: 'Uso del lote',
      label: 'Uso',
      variant: 'standard',
      fullWidth: true,
      required: true,
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      maxWidth: '200px',
      direction: 'row',
      defaultValue: '',
      dependencies: {
        disabled: [],
        display: [],
      },
      refDataKey: 'RefFieldUsage',
    },
    {
      component_type: 'CheckBoxGroup',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'livestockRaisingTypeId',
      placeholder: 'Tipo de ganadería',
      label: 'Tipo de ganadería',
      variant: 'outlined',
      defaultValue: [],
      fullWidth: true,
      required: true,
      direction: 'row',
      dependencies: {
        disabled: [],
        display: [{ fieldUsageId: { in: [0, 3] } }],
      },
      refDataKey: 'RefLivestockRaisingType',
    },
    {
      component_type: 'Select',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'forageSourceId',
      placeholder: 'Recurso Forrajero',
      label: 'Recurso forrajero',
      variant: 'outlined',
      fullWidth: true,
      required: true,
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
      direction: 'row',
      defaultValue: '',
      dependencies: {
        disabled: [],
        display: [{ fieldUsageId: { in: [0, 3] } }, { livestockRaisingTypeId: { arrNotEq: [3] } }],
      },
      refDataKey: 'RefForageSource',
    },
    {
      component_type: 'RadioGroup',
      name: 'pasturesGrowthTypeId',
      placeholder: 'Tipo de crecimiento de pastizal',
      label: 'Tipo de crecimiento de pastizal',
      variant: 'outlined',
      defaultValue: '',
      fullWidth: true,
      required: true,
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 4,
        lg: 4,
      },
      direction: 'column',
      dependencies: {
        disabled: [],
        display: [{ fieldUsageId: { in: [0, 3] } }],
      },
      refDataKey: 'RefPasturesGrowthType',
    },
    {
      component_type: 'RadioGroup',
      name: 'pasturesTypeId',
      placeholder: 'Tipo de pastizal',
      label: 'Tipo de pastizal',
      variant: 'outlined',
      defaultValue: '',
      fullWidth: true,
      required: true,
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 4,
        lg: 4,
      },
      direction: 'column',
      dependencies: {
        disabled: [],
        display: [{ fieldUsageId: { in: [0, 3] } }],
      },
      refDataKey: 'RefPasturesType',
    },
    {
      component_type: 'RadioGroup',
      name: 'pasturesFamilyId',
      placeholder: 'Familia de pastizal predominante',
      label: 'Familia de pastizal predominante',
      variant: 'outlined',
      defaultValue: '',
      fullWidth: true,
      required: true,
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 4,
        lg: 4,
      },
      direction: 'column',
      dependencies: {
        disabled: [],
        display: [{ fieldUsageId: { in: [0, 3] } }],
      },
      refDataKey: 'RefPasturesFamily',
    },
    {
      component_type: 'TextField',
      type: 'number',
      name: 'dryMatterProduction',
      placeholder: 'Productividad anual del pastizal (producción de materia seca)',
      label: 'Productividad anual del pastizal (producción de materia seca)',
      unit: 'kg/Ha',
      variant: 'standard',
      defaultValue: 0,
      min: 0,
      fullWidth: true,
      required: true,
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      dependencies: {
        disabled: [],
        display: [{ fieldUsageId: { in: [0, 3] } }],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      name: 'biomassProduction',
      unit: 'kg/Ha',
      placeholder: 'Medición de biomasa del pastizal (inicio de invierno)',
      label: 'Medición de biomasa del pastizal (inicio de invierno)',
      variant: 'standard',
      defaultValue: 0,
      min: 0,
      fullWidth: true,
      required: true,
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      dependencies: {
        disabled: [],
        display: [{ fieldUsageId: { in: [0, 3] } }],
      },
    },
  ],
}
export default lotes
