const pastoreo = {
  namespace: 'farm_management_grazing',
  label: 'Pastoreo',
  required: true,
  layout: {
    showMap: true,
    showTable: true,
  },
  fields: [
    {
      component_type: 'Title',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Ingresar datos de pastoreo',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'InfoText',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Los datos deben ser cargados por lote o conjunto de lotes (célula).',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'Select',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'grazingTypeId',
      placeholder: 'Tipo de pastoreo',
      label: 'Tipo de pastoreo',
      variant: 'outlined',
      fullWidth: true,
      required: true,
      defaultValue: '',
      dependencies: {
        disabled: [],
        display: [],
      },
      refDataKey: 'RefGrazingType',
    },
    {
      component_type: 'Select',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      name: 'grazingPlanTypeId',
      placeholder: 'Plan',
      label: 'Plan',
      variant: 'outlined',
      fullWidth: true,
      required: true,
      defaultValue: '',
      dependencies: {
        disabled: [],
        display: [{ grazingTypeId: { notIn: [1, 2] } }],
      },
      refDataKey: 'RefGrazingPlanType',
    },
    {
      component_type: 'FieldArray',
      required: true,
      name: 'animals',
      label: 'Animales',
      defaultValues: [{ cattleType: 1, headcount: 0, averageWeigth: 0 }],
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      dependencies: {
        disabled: [],
        display: [],
      },
      fields: [
        {
          component_type: 'Select',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
          },
          name: 'cattleType',
          placeholder: 'Categoría animal',
          label: 'Categoría animal',
          variant: 'outlined',
          fullWidth: true,
          required: true,
          direction: 'row',
          defaultValue: 0,
          dependencies: {
            disabled: [],
            display: [],
          },
          refDataKey: 'RefCattleType',
        },
        {
          component_type: 'TextField',
          type: 'number',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
          },
          name: 'headcount',
          unit: '',
          placeholder: 'Cantidad de animales',
          label: 'Cantidad de animales',
          variant: 'standard',
          defaultValue: 0,
          min: 0,
          fullWidth: true,
          required: true,
          dependencies: {
            disabled: [],
            display: [],
          },
        },
        {
          component_type: 'TextField',
          type: 'number',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
          },
          name: 'averageWeigth',
          unit: 'Kg',
          placeholder: 'Peso promedio',
          label: 'Peso promedio',
          defaultValue: 0,
          min: 0,
          fullWidth: true,
          required: true,
          dependencies: {
            disabled: [],
            display: [],
          },
        },
      ],
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'occupationDaysClosingPeriod',
      unit: 'días',
      placeholder: 'Ocupación promedio otoño/invierno',
      label: 'Ocupación promedio otoño/invierno',
      defaultValue: 0,
      min: 0,
      max: 365,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [{ grazingTypeId: { neq: 1 } }, { grazingPlanTypeId: { neq: 0 } }],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'restingDaysClosingPeriod',
      unit: 'días',
      placeholder: 'Descanso promedio otoño/invierno ',
      label: 'Descanso promedio otoño/invierno ',
      defaultValue: 0,
      min: 0,
      max: 365,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [{ grazingTypeId: { neq: 1 } }, { grazingPlanTypeId: { neq: 0 } }],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'occupationDaysSpring',
      unit: 'días',
      placeholder: 'Ocupación promedio primavera',
      label: 'Ocupación promedio primavera',
      defaultValue: 0,
      min: 0,
      max: 365,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [{ grazingTypeId: { neq: 1 } }, { grazingPlanTypeId: { neq: 1 } }],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'restingDaysSpringAvg',
      unit: 'días',
      placeholder: 'Descanso promedio primavera',
      label: 'Descanso promedio primavera',
      defaultValue: 0,
      min: 0,
      max: 365,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [{ grazingTypeId: { neq: 1 } }, { grazingPlanTypeId: { neq: 1 } }],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'occupationDaysSummer',
      unit: 'días',
      placeholder: 'Ocupación promedio verano',
      label: 'Ocupación promedio verano',
      defaultValue: 0,
      min: 0,
      max: 365,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [{ grazingTypeId: { neq: 1 } }, { grazingPlanTypeId: { neq: 1 } }],
      },
    },
    {
      component_type: 'TextField',
      type: 'number',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      name: 'restingDaysSummerAvg',
      unit: 'días',
      placeholder: 'Descanso promedio verano ',
      label: 'Descanso promedio verano ',
      defaultValue: 0,
      min: 0,
      max: 365,
      fullWidth: true,
      required: true,
      dependencies: {
        disabled: [],
        display: [{ grazingTypeId: { neq: 1 } }, { grazingPlanTypeId: { neq: 1 } }],
      },
    },
  ],
}
export default pastoreo
