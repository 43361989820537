import dayjs from 'dayjs'

const CUSTOM_YEAR = 'LP'

export const dateBoundaries = {
  currentYear: ({ year, lastFarmSubdivisionYear }) => {
    if (year === CUSTOM_YEAR) {
      return {
        minDate: dayjs(`${lastFarmSubdivisionYear}-01-01`),
      }
    }
    return {
      minDate: dayjs(`${year}-01-01`),
      maxDate: dayjs(`${year}-12-31`),
    }
  },
  plusOneYear: ({ year, lastFarmSubdivisionYear }) => {
    let newYear = parseInt(lastFarmSubdivisionYear, 10) + 1
    if (year === CUSTOM_YEAR) {
      return {
        minDate: dayjs(`${lastFarmSubdivisionYear}-01-01`),
      }
    }
    newYear = parseInt(year, 10) + 1
    return {
      minDate: dayjs(`${year}-01-01`),
      maxDate: dayjs(`${newYear}-12-31`),
    }
  },
  formFieldsRange: ({ startDate, endDate }) => {
    return {
      minDate: dayjs(startDate),
      maxDate: dayjs(endDate).add(1, 'day'),
    }
  },
}
