import endpoints from '../ruutsApi/endpoints'
import getApiData from '../ruutsApi/getApiData'
import { getFrontPaddocks } from './getPaddocks'

export async function getPaddockById({ id, token }) {
  const paddocks = await getApiData(
    endpoints.paddocks,
    {
      id,
    },
    token,
  )
  return getFrontPaddocks(paddocks)
}

export async function getCroppedPaddockById({ id, token }) {
  return getApiData(
    endpoints.paddocks,
    {
      id,
    },
    token,
  )
}
