/* eslint-disable react/no-array-index-key */
import { Box, Typography } from '@mui/material'
import { Fragment } from 'react'

const InfoText = ({ formField }) => {
  return (
    <Box>
      <Typography
        fontSize={formField?.fontSize ? formField?.fontSize : 'default'}
        variant={formField?.variant ? formField.variant : 'body2'}
      >
        {formField.labels.map((label, index) => (
          <Fragment key={index}>
            <Typography component="span" sx={{ ...label?.properties }}>
              {label.text}
            </Typography>
          </Fragment>
        ))}
      </Typography>
    </Box>
  )
}

export default InfoText
