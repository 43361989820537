import endpoints from '../endpoints'
import getApiData from '../getApiData'

export async function getCommentsByFinding(findingId, token) {
  try {
    const comments = await getApiData(
      `${endpoints.findingComments}/findings/${findingId}`,
      null,
      token,
    )
    return comments
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get has fingings.')
  }
}
