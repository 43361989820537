import { Box, Button, Grid, LinearProgress, Modal, Typography } from '@mui/material'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
}

const ConfirmationModal = ({
  title,
  message,
  open,
  handleClose,
  handleConfirm,
  buttonValue,
  saveLabel,
  cancelLabel,
  loading = false,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <ReportProblemOutlinedIcon
          sx={{
            position: 'absolute',
            top: -30,
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: 60,
            backgroundColor: 'white',
            color: '#ff9800',
            padding: 1,
            borderRadius: '50%',
          }}
        />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            margin: 2,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            margin: 2,
          }}
        >
          {message}
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <Button
              sx={{
                backgroundColor: '#1976d2',
                color: 'white',
                borederRadius: 4,
                margin: 2,
                px: 2,
                fontWeight: 600,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#1976d2',
                  color: 'white',
                  opacity: 0.8,
                },
              }}
              value={buttonValue}
              onClick={handleConfirm}
            >
              {saveLabel ?? 'Confirmar'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{
                color: '#1976d2',
                backgroundColor: 'white',
                margin: 2,
                px: 2,
                fontWeight: 600,
                textTransform: 'none',
              }}
              onClick={handleClose}
            >
              {cancelLabel ?? 'Cancelar'}
            </Button>
          </Grid>
        </Grid>
        <LinearProgress
          sx={{
            display: loading ? 'block' : 'none',
          }}
        />
      </Box>
    </Modal>
  )
}

export default ConfirmationModal
