import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const PaddocksList = ({
  paddocks,
  selectedPaddockIds,
  updateSelectedPaddocks,
  usedPaddockIds,
  formMode,
}) => {
  return (
    paddocks &&
    usedPaddockIds &&
    selectedPaddockIds && (
      <Autocomplete
        disableCloseOnSelect
        multiple
        defaultValue={paddocks.filter(p => selectedPaddockIds.includes(p.id))}
        disabled={formMode.readOnly}
        getOptionDisabled={option => usedPaddockIds.includes(option.id)}
        getOptionLabel={option => option.label}
        id="checkboxes-tags-demo"
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
        limitTags={2}
        options={paddocks}
        renderInput={params => <TextField {...params} label="Selecciona Lotes" placeholder="" />}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              checked={selected || selectedPaddockIds.includes(option.id)}
              checkedIcon={checkedIcon}
              icon={icon}
              size="small"
              style={{ marginRight: 8 }}
            />
            {option.label}
          </li>
        )}
        size="small"
        sx={{ width: '350px', paddingRight: 1, whiteSpace: 'nowrap' }}
        value={paddocks.filter(p => selectedPaddockIds.includes(p.id))}
        onChange={(event, value) => {
          updateSelectedPaddocks(value)
        }}
      />
    )
  )
}

export default PaddocksList
