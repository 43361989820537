import endpoints from '../ruutsApi/endpoints'
import getApiData from '../ruutsApi/getApiData'

export default async function getMonitoringSoc(farm, token) {
  return getApiData(
    endpoints.monitoringExportForSoc,
    {
      farmId: farm.id,
    },
    token,
  )
}
