const cultivos = {
  namespace: 'paddock_crops',
  label: 'Cultivos',
  required: false,
  layout: {
    showMap: true,
    showTable: true,
  },
  fields: [
    {
      component_type: 'Title',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Cultivos',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'InfoText',
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      label: 'Los datos deben ser cargados por lote o conjunto de lotes con los mismos valores.',
      dependencies: {
        disabled: [],
        display: [],
      },
    },
    {
      component_type: 'FieldArray',
      required: false,
      name: 'crops',
      label: 'cultivo',
      defaultValues: [
        {
          cropTypeId: 0,
          performanceKgHectare: 0,
          sowingDate: '',
          harvestDate: '',
        },
      ],
      gridSizing: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      dependencies: {
        disabled: [],
        display: [],
      },
      fields: [
        {
          component_type: 'Select',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
          },
          name: 'cropTypeId',
          placeholder: 'Tipo cultivo',
          label: 'Tipo de cultivo',
          variant: 'outlined',
          fullWidth: true,
          required: true,
          direction: 'row',
          defaultValue: 0,
          dependencies: {
            disabled: [],
            display: [],
          },
          refDataKey: 'RefCropType',
        },
        {
          component_type: 'TextField',
          type: 'date',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
          },
          name: 'sowingDate',
          unit: '',
          placeholder: 'Fecha de siembra',
          label: 'Fecha de siembra',
          variant: 'standard',
          defaultValue: 0,
          fullWidth: true,
          required: true,
          dependencies: {
            disabled: [],
            display: [],
          },
        },
        {
          component_type: 'TextField',
          type: 'date',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
          },
          name: 'harvestDate',
          unit: '',
          placeholder: 'Fecha de cosecha',
          label: 'Fecha de cosecha',
          variant: 'standard',
          defaultValue: 0,
          fullWidth: true,
          required: true,
          dependencies: {
            disabled: [],
            display: [],
          },
        },
        {
          component_type: 'TextField',
          type: 'number',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
          },
          name: 'performanceKgHectare',
          unit: 'Kg/Ha',
          placeholder: 'Rendimiento',
          label: 'Rendimiento',
          variant: 'standard',
          defaultValue: 0,
          min: 0,
          fullWidth: true,
          required: true,
          dependencies: {
            disabled: [],
            display: [{ cropTypeId: { notIn: [6, 7] } }],
          },
        },
        {
          component_type: 'TextField',
          type: 'number',
          gridSizing: {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
          },
          name: 'performanceCoverCropTnHectare',
          unit: 'Tn/Ha',
          placeholder: 'Rendimiento (materia seca)',
          label: 'Rendimiento (materia seca)',
          variant: 'standard',
          defaultValue: 0,
          min: 0,
          fullWidth: true,
          required: true,
          dependencies: {
            disabled: [],
            display: [{ cropTypeId: { in: [6, 7] } }],
          },
        },
      ],
    },
  ],
}

export default cultivos
