import { getUncroppedSamplingAreasByFarm, getCroppedSamplingAreasByFarm } from './getSamplingAreas'
import {
  createSamplingAreasFeatureByFarm,
  updateSamplingAreasFeatureByFarm,
} from './saveSamplingAreas'
import { deleteSamplingAreas, deleteSamplingAreasByFarm } from './deleteSamplingAreas'

export const samplingAreas = {
  getUncroppedByFarm: getUncroppedSamplingAreasByFarm,
  getCroppedByFarm: getCroppedSamplingAreasByFarm,
  createByFarm: createSamplingAreasFeatureByFarm,
  updateByFarm: updateSamplingAreasFeatureByFarm,
  deleteEntities: deleteSamplingAreas,
  deleteByFarm: deleteSamplingAreasByFarm,
}
