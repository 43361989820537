import * as turf from '@turf/turf'
import { GeoJSON } from './GeoJSON/index'
import { errors } from '../services/ruutsApi/errors'

export const miniumFeatureHectareAllowed = 0.1

const checkFeatureValid = (feature, message) => {
  if (GeoJSON.hectareArea(feature) < miniumFeatureHectareAllowed) {
    throw new errors.BusinessEntityError({
      message:
        message ??
        `Edición no guardada. El lote ${feature.properties.name} resulta en un área inferior al mínimo permitido de ${miniumFeatureHectareAllowed} has.`,
    })
  }
}

const checkFeaturesValid = features => {
  features.forEach(feature => checkFeatureValid(feature))
}

export const checkIntersectionWithPerimeter = (feature, perimeter, validateFeature = false) => {
  const newGeometryInsidePerimeter = GeoJSON.intersect(feature, perimeter)
  if (!newGeometryInsidePerimeter) {
    throw new errors.BusinessEntityError({
      message: `La capa ${feature?.properties?.name || ''} resulta en un área inválida. Por favor, dibújela dentro dentro del perímetro.`,
    })
  }
  const newFeatureInsidePerimeter = {
    ...feature,
    geometry: { ...newGeometryInsidePerimeter.geometry },
  }
  if (validateFeature) checkFeatureValid(newFeatureInsidePerimeter)
  return newFeatureInsidePerimeter
}

export const processIntersectionWithPerimeter = (feature, perimeter) => {
  const intersection = GeoJSON.intersect(feature, perimeter)
  if (!intersection) return null

  return {
    ...feature,
    geometry: { ...intersection.geometry },
  }
}

export const checkIntersectionWithExclusionAreas = (
  feature,
  exclusionAreas,
  validateFeature = false,
) => {
  if (!exclusionAreas?.length) return feature

  const croppedArea = exclusionAreas?.reduce(
    (feat, ea) => GeoJSON.difference(feat, ea) || GeoJSON.truncate(feat),
    feature,
  )

  if (validateFeature) checkFeatureValid(croppedArea)

  return croppedArea
}

export const cropExclusionAreasFromGeoJSON = (exclusionAreas, featuresToCrop) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  featuresToCrop.map(featureToCrop => {
    const croppedArea = exclusionAreas?.reduce((area, exclusionArea) => {
      let polygonArea
      if (area.geometry.type === 'MultiPolygon') {
        const bboxSA = turf.bbox(area)
        polygonArea = turf.bboxPolygon(bboxSA)
      } else {
        polygonArea = area
      }
      const within = turf.booleanWithin(polygonArea, exclusionArea)
      if (within) {
        // Si esta incluida por completo, se marca para borrar
        // eslint-disable-next-line no-param-reassign
        area.properties.isDeleted = true
      } else {
        // En el caso de que no se tocan, retorna null y retornas el sa original
        // eslint-disable-next-line no-param-reassign
        area = GeoJSON.difference(area, exclusionArea) || GeoJSON.truncate(area)
      }
      return area
    }, featureToCrop)
    return croppedArea
  })

export const cropExclusionAreasFromFeatures = (exclusionAreas, features) => {
  const geoJSONFeatures = features.map(geoJSONFeature => geoJSONFeature.toGeoJSON)
  return cropExclusionAreasFromGeoJSON(exclusionAreas, geoJSONFeatures)
}

// Esta función se puede reemplazar por que se encuentra comentada arriba,
// funciona bien según las pruebas que hice pero dado el momento de salida del golive,
// podemos hacer más pruebas en otro momento ya que esta se usa en todas las operaciones
// del clasification map. Se debe incluir las features ares que incluye por completao a atro
export const checkIntersectionWithOtherFeatures = (
  feature,
  features,
  validateFeature = false,
  templateMessageError = 'Edición no guardada. Al expandir el lote {originPaddockName}, el lote {destinyPaddockName} resulta en un área inferior al mínimo permitido de {miniumFeatureHectareAllowed} has.',
) => {
  const newFeatures = []

  features.forEach(f => {
    const equal = turf.booleanEqual(feature, f)
    const difference = GeoJSON.difference(f, feature)
    const intersect = GeoJSON.intersect(f, feature)
    let contains
    let within
    if (f.geometry.type === 'Polygon' && feature.geometry.type === 'Polygon') {
      contains = turf.booleanContains(feature, f)
      within = turf.booleanWithin(feature, f)
    }

    if (!equal) {
      if (intersect && difference) {
        if (contains) {
          // el polígono nuevo es contiene al viejo, eliminar el viejo
          return
        }
        if (difference) {
          if (validateFeature) {
            const replacements = {
              '{originPaddockName}': feature.properties.name,
              '{destinyPaddockName}': f.properties.name,
              '{miniumFeatureHectareAllowed}': miniumFeatureHectareAllowed,
            }
            const messageError = Object.entries(replacements).reduce(
              (message, [placeholder, value]) => message.replace(placeholder, value),
              templateMessageError,
            )
            checkFeatureValid(difference, messageError)
          }
          difference.metrics = f.metrics
          difference.properties = f.properties
          difference.properties.area = GeoJSON.hectareArea(difference)
          if (within) {
            // el polígono nuevo está dentro del viejo, guardar la diferencia (con agujero en el centro)
            newFeatures.push(difference)
            return
          }
          newFeatures.push(difference)
        }
      } else {
        newFeatures.push(f)
      }
    }
  })

  if (validateFeature) checkFeaturesValid(features)

  return newFeatures
}

export const getRandomColor = () => {
  const color = `hsl(${Math.floor(360 * Math.random())}, ${Math.floor(
    50 + 50 * Math.random(),
  )}%, ${Math.floor(30 + 50 * Math.random())}%)`
  return color
}
