import { Box, Divider } from '@mui/material'

const DividerWrapper = () => {
  return (
    <Box>
      <Divider
        sx={{ borderBottomWidth: 1, bgcolor: 'black', marginTop: 2, marginBottom: 2 }}
        variant="fullWidth"
      />
    </Box>
  )
}

export default DividerWrapper
