import { useAuth0 } from '@auth0/auth0-react'
import {
  Audiotrack,
  Description,
  InsertDriveFile,
  InsertPhoto,
  PictureAsPdf,
  Theaters,
} from '@mui/icons-material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { DropzoneAreaBase, createFileFromUrl, readFile } from 'mui-file-dropzone'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import AppContext from '../../context/appContext.js'
import uploadFiles from '../../services/objectStore/uploadFiles'
import { customSnackbarError } from '../../utils/Snackbar/Snackbar'
import './FileInput.css'

const maxFilesLimit = process.env.REACT_APP_FILE_INPUT_MAX_FILES_LIMIT
  ? Number(process.env.REACT_APP_FILE_INPUT_MAX_FILES_LIMIT)
  : 30
const maxFileSizeLimit = process.env.REACT_APP_FILE_INPUT_MAX_FILE_SIZE_LIMIT
  ? Number(process.env.REACT_APP_FILE_INPUT_MAX_FILE_SIZE_LIMIT)
  : 10000000

const FileInput = ({ definition, year, onAdd, onDelete, files, readOnly }) => {
  const [fileObjects, setFileObjects] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { getAccessTokenSilently } = useAuth0()
  const { currentFarm, setLoadingModalConfig } = useContext(AppContext)

  const handlePreviewIcon = fileObject => {
    const { type } = fileObject.file
    const iconProps = {
      sx: { fontSize: 50 },
    }

    if (type.startsWith('video/')) return <Theaters {...iconProps} />
    if (type.startsWith('audio/')) return <Audiotrack {...iconProps} />

    switch (type) {
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <Description {...iconProps} />
      case 'application/pdf':
        return <PictureAsPdf {...iconProps} />
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return <InsertPhoto {...iconProps} />
      default:
        return <InsertDriveFile {...iconProps} />
    }
  }

  useEffect(() => {
    async function getFileObjects() {
      try {
        const filesObjects = await Promise.all(
          files.map(async file => {
            // Files could be loaded from URL and custom FileObject need by the component.
            // If file is not an string, it means file is FileObject.
            // If file is string, it means file is URL and a FileObject is created from it.
            if (typeof file !== 'string') {
              return file
            }
            const fileFromURL = await createFileFromUrl(file)
            const fielData = await readFile(fileFromURL)
            const fileObj = { file: fileFromURL, data: fielData }
            return fileObj
          }),
        )
        setFileObjects(filesObjects)
      } catch (e) {
        customSnackbarError('Error al carga la imagen', e, enqueueSnackbar, closeSnackbar)
      }
    }

    getFileObjects()
  }, [files, enqueueSnackbar, closeSnackbar])

  return (
    <div>
      {currentFarm && (
        <div>
          {isUploading ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '26vh',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                overflowY: 'scroll',
              }}
            >
              <DropzoneAreaBase
                showFileNames
                dropzoneParagraphClass="fileInputDropzoneParagraph"
                dropzoneProps={{ disabled: readOnly }}
                dropzoneText={<ReactMarkdown>{definition.placeholder}</ReactMarkdown>}
                fileObjects={fileObjects}
                filesLimit={maxFilesLimit}
                getPreviewIcon={fileObj => handlePreviewIcon(fileObj)}
                maxFileSize={maxFileSizeLimit}
                previewChipProps={{
                  style: { maxWidth: '230px' },
                  onClick: event => {
                    const fileName = event.currentTarget.textContent
                    const fileURL = files.find(file => file.indexOf(fileName) >= 0)
                    if (!fileURL) return
                    window.open(fileURL, '_blank')
                  },
                }}
                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                previewText=""
                showAlerts={false}
                showPreviews={definition.showPreviews}
                showPreviewsInDropzone={definition.showPreviewsInDropzone}
                useChipsForPreview={definition.useChipsForPreview}
                onAdd={newFileObjs => {
                  async function uploadFile() {
                    try {
                      setLoadingModalConfig({
                        open: true,
                        title: 'Subiendo archivo...',
                      })
                      setIsUploading(true)
                      const token = await getAccessTokenSilently()
                      const directoryName = `farms/${currentFarm.id}/${year}/${definition.name}`
                      const fileLinks = await uploadFiles(directoryName, newFileObjs, token)
                      onAdd(fileLinks)
                      const newFileObjects = [].concat(fileObjects, newFileObjs)
                      setFileObjects(newFileObjects)
                    } catch (e) {
                      customSnackbarError(
                        'Error al subir el archivo, por favor intente nuevamente',
                        e,
                        enqueueSnackbar,
                        closeSnackbar,
                      )
                      setFileObjects(fileObjects)
                    }

                    setLoadingModalConfig({
                      open: false,
                    })
                    setIsUploading(false)
                  }

                  uploadFile()
                }}
                onAlert={(message, variant) => {
                  if (variant !== 'error') return
                  customSnackbarError(message, null, enqueueSnackbar, closeSnackbar)
                }}
                onDelete={(_, index) => {
                  if (readOnly) return
                  onDelete(index)
                }}
              />
            </Box>
          )}
        </div>
      )}
    </div>
  )
}

export default FileInput
