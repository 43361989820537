import endpoints from '../endpoints'
import postApiData from '../postApiData'

export async function saveComment(dataCollectionStatementId, findingId, userRole, message, token) {
  try {
    const data = {
      dataCollectionStatementId,
      userRole,
      findingId,
      message,
    }
    const comments = await postApiData(`${endpoints.findingComments}`, data, token)
    return comments
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get comments.')
  }
}
