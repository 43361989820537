import lfStore from '../../lfstore/lfStore'

export default async function refDataById(key, id) {
  if (!key) {
    throw new Error('Missing key')
  }
  if (id === undefined) {
    throw new Error('Missing ID')
  }
  try {
    const item = await lfStore.getItem(key)
    const [data] = item.filter(obj => obj.id === id)
    return data
  } catch (error) {
    console.error(error)
    throw new Error(`Unable to retrieve reference data for ${key}`)
  }
}
