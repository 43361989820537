import endpoints from '../../ruutsApi/endpoints'
import getApiData from '../../ruutsApi/getApiData'

export const getUncroppedFeatures = ({ features }) =>
  features.map(feature => ({
    ...feature,
    geometry: feature.uncroppedGeometry,
    toGeoJSON: feature.toUncroppedGeoJSON,
  }))

export const getUncroppedSamplingAreasByFarm = async ({ farmId, token }) => {
  const samplingAreas = await getApiData(endpoints.samplingAreas, { farmId }, token)
  return getUncroppedFeatures({ features: samplingAreas })
}

export const getCroppedSamplingAreasByFarm = async ({ farmId, token }) =>
  getApiData(endpoints.samplingAreas, { farmId }, token)
