import { Grid, TextField, Typography } from '@mui/material'

const FindingCommentCard = ({ comment }) => {
  return (
    <Grid container mb={0.5}>
      <Grid item xs={12}>
        <TextField key={comment.id} fullWidth multiline readOnly rows={2} value={comment.message} />
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
        xs={12}
      >
        <Typography mr={1} variant="caption">
          {comment.createdBy}
        </Typography>
        <Typography variant="caption">
          {new Date(comment.createdAt).toLocaleDateString()}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default FindingCommentCard
