import { useAuth0 } from '@auth0/auth0-react'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../../context/appContext'
import getExclusionAreaTypes from '../../../services/farmMapping/getExclusionAreaTypes'
import endpoints from '../../../services/ruutsApi/endpoints'
import getApiData from '../../../services/ruutsApi/getApiData'
import { ruutsApi } from '../../../services/ruutsApi/index'
import { useProcessLoading } from '../../../utils/Loading/useProcessLoading'
import { useCustomSnackbarError } from '../../../utils/Snackbar/useCustomSnackbarError'
import ClasificationMap from '../ClasificationMap'
import ClasificationPreview from '../ClasificationPreview'
import ExclusionAreaPanel from './ExclusionAreaPanel'

const ExclusionAreaHome = ({
  setTabValue,
  exclusionAreas,
  setExclusionAreas,
  canEditClasification,
  samplingAreas,
  mapWidth,
  mapHeight,
}) => {
  const { user } = useAuth0()

  const [exclusionAreasFeatures, setExclusionAreasFeatures] = useState([])
  const [mapAction, setMapAction] = useState(null)
  const [selectedArea, setSelectedArea] = useState()
  const [selectedAreaType, setSelectedAreaType] = useState()
  const [wasSaved, setWasSaved] = useState(false)
  const [georaster, setGeoraster] = useState()
  const [vectorized, setVectorized] = useState(false)
  const [drawActivated, setDrawActivated] = useState(false)
  const [perimeter, setPerimeter] = useState()
  const [restratification, setRestratification] = useState(false)
  const [exclusionTypes, setExclusionTypes] = useState()
  const [supportedCreationModes, setSupportedCreationModes] = useState(null)
  const { currentFarm, programConfig } = useContext(AppContext)
  const { processLoading } = useProcessLoading()
  const { getAccessTokenSilently } = useAuth0()
  const { handleError } = useCustomSnackbarError()
  const [farmSubdivisions, setFarmSubdivisions] = useState([])

  useEffect(() => {
    async function getFarm() {
      await processLoading({
        loadingMessage: 'Cargando áreas de exclusión...',
        errorMessage: 'Error al cargar áreas de exclusión.',
        doAction: async ({ token }) => {
          const farm = await ruutsApi.getFarm({ id: currentFarm.id, user, token })

          if (!exclusionAreasFeatures.length && exclusionAreas?.length) {
            setExclusionAreasFeatures(() => exclusionAreas.map(eA => eA.toGeoJSON))
          }

          const newExclusionTypes = await getExclusionAreaTypes(token)
          setExclusionTypes(newExclusionTypes)

          const modesAllowed = programConfig?.exclusionAreasModesAllowed || []
          setSupportedCreationModes(modesAllowed)

          setPerimeter(farm.toGeoJSON)
        },
      })
    }

    getFarm()
  }, [
    currentFarm,
    programConfig,
    exclusionAreas,
    user,
    exclusionAreasFeatures.length,
    processLoading,
  ])

  useEffect(() => {
    const getFarmSubdivisions = async () => {
      try {
        const token = await getAccessTokenSilently()
        const newFarmSubdivisions = await getApiData(
          endpoints.farmSubdivisions,
          { farmId: currentFarm.id },
          token,
        )
        const newPaddocks = await ruutsApi.paddocks.getPaddocks({
          farmId: currentFarm.id,
          token,
        })

        const result = newFarmSubdivisions
          .map(subdivision => {
            const paddocks = newPaddocks
              .filter(paddock => subdivision.paddockIds.includes(paddock.id))
              .map(paddock => paddock.toGeoJSON)
            return {
              name: `Loteo - ${subdivision.year}`,
              features: paddocks,
            }
          })
          .sort((a, b) => {
            const yearA = parseInt(a.name.split(' - ')[1], 10)
            const yearB = parseInt(b.name.split(' - ')[1], 10)

            return yearB - yearA
          })

        setFarmSubdivisions(result)
      } catch (error) {
        handleError(error)
        setFarmSubdivisions([])
      }
    }
    getFarmSubdivisions()
  }, [currentFarm.id, getAccessTokenSilently, handleError])

  const sAreas = samplingAreas
    ? [
        {
          name: 'Estratos',
          features: samplingAreas.map(sA => sA.toGeoJSON),
        },
      ]
    : []

  const layers = [...sAreas, ...farmSubdivisions]

  return (
    perimeter &&
    supportedCreationModes && (
      <Box>
        <Grid container>
          <Grid item xs={4}>
            <ExclusionAreaPanel
              canEditClasification={canEditClasification}
              drawActivated={drawActivated}
              exclusionAreas={exclusionAreas}
              exclusionAreasFeatures={exclusionAreasFeatures}
              exclusionTypes={exclusionTypes}
              farm={currentFarm}
              georaster={georaster}
              mapAction={mapAction}
              restratification={restratification}
              selectedArea={selectedArea}
              setDrawActivated={setDrawActivated}
              setExclusionAreas={setExclusionAreas}
              setExclusionAreasFeatures={setExclusionAreasFeatures}
              setGeoraster={setGeoraster}
              setMapAction={setMapAction}
              setRestratification={setRestratification}
              setSelectedArea={setSelectedArea}
              setSelectedAreaType={setSelectedAreaType}
              setTabValue={setTabValue}
              setVectorized={setVectorized}
              setWasSaved={setWasSaved}
              supportedCreationModes={supportedCreationModes}
              wasSaved={wasSaved}
            />
          </Grid>
          <Grid item xs={8}>
            {(drawActivated || (!georaster && !vectorized)) && (
              <ClasificationMap
                allowSelfIntersection={drawActivated && vectorized}
                exclusionAreasFeatures={exclusionAreasFeatures}
                layers={layers}
                mapAction={mapAction}
                mapHeight={mapHeight}
                mapWidth={mapWidth}
                perimeter={perimeter}
                selectedArea={selectedArea}
                selectedAreaType={selectedAreaType}
                setExclusionAreasFeatures={setExclusionAreasFeatures}
                setMapAction={setMapAction}
                setSelectedArea={setSelectedArea}
                setSelectedAreaType={setSelectedAreaType}
                vectorized={vectorized}
                wasSaved={wasSaved}
              />
            )}
            {georaster && !drawActivated && !vectorized && (
              <ClasificationPreview
                georaster={georaster}
                mapHeight={mapHeight}
                mapWidth={mapWidth}
                perimeter={perimeter}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    )
  )
}

export default ExclusionAreaHome
