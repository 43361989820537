import Tooltip from '@mui/material/Tooltip'
import SendIcon from '@mui/icons-material/Send'
import VisibilityIcon from '@mui/icons-material/Visibility'
import RateReviewIcon from '@mui/icons-material/RateReview'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { IconButton, Stack } from '@mui/material'

const TableActionButtons = ({
  dataCollectionStatement,
  editionYear,
  allowView,
  allowEdit,
  allowSend,
  allowReview,
  handleView,
  handleEdit,
  handleSend,
  handleReview,
}) => {
  return (
    <Stack alignItems="center" direction="row" display="flex" justifyContent="center">
      {allowView && (
        <Tooltip title="Ver">
          <IconButton size="small" onClick={() => handleView(editionYear)}>
            <VisibilityIcon sx={{ height: '18px', width: '18px' }} />
          </IconButton>
        </Tooltip>
      )}

      {allowEdit && (
        <Tooltip title="Editar">
          <IconButton size="small" onClick={() => handleEdit(editionYear)}>
            <EditOutlinedIcon sx={{ height: '18px', width: '18px' }} />
          </IconButton>
        </Tooltip>
      )}

      {allowReview && (
        <Tooltip title="Revisar">
          <IconButton size="small" onClick={() => handleReview(dataCollectionStatement)}>
            <RateReviewIcon sx={{ height: '18px', width: '18px' }} />
          </IconButton>
        </Tooltip>
      )}

      {allowSend && (
        <Tooltip title="Enviar">
          <IconButton size="small" onClick={() => handleSend(dataCollectionStatement)}>
            <SendIcon sx={{ height: '18px', width: '18px' }} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )
}

export default TableActionButtons
