/* eslint-disable import/no-cycle */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unreachable-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import Add from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Box, Button, Grid, Paper } from '@mui/material'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import lfStore from '../../../lfstore/lfStore'
import { fieldArraysValidations } from '../../../utils/dataCollection/fieldArraysValidation'
import DynamicFieldSwitcher from '../DynamicFieldSwitcher'

const FieldArrayWrapper = ({ fieldArray, control, checkDependencies, readOnly }) => {
  const { getValues, setError } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArray.name,
  })

  const transformDeps = (dependencies, index) => {
    if (!dependencies?.length) return []

    return dependencies.map(dep => {
      // MetricYear is a general farm case value, it is not a field from a field array.
      // W need to keep the original dependency in order to check if the metricYear value is present.
      if (dep?.metricYear) {
        return dep
      }
      const oldKey = Object.keys(dep)
      const newKey = !Number.isNaN(index) ? `${fieldArray.name}.${index}.${oldKey}` : `${oldKey}`
      const newObject = {}
      delete Object.assign(newObject, dep, { [newKey]: dep[oldKey] })[oldKey]
      return newObject
    })
  }

  const transformValueDeps = (dependencies, index) => {
    if (!dependencies?.length) return []

    return dependencies.map(depValue => {
      const newDeps = transformDeps(depValue.dependencies, index)
      return { value: depValue.value, dependencies: newDeps }
    })
  }

  const transformDisplayDeps = (formField, index) => {
    const fieldArrayDisplayDeps = transformDeps(formField.dependencies.display, index)
    const globalDisplayDeps = formField?.globalDependencies?.display?.length
      ? formField.globalDependencies.display
      : []
    return [...fieldArrayDisplayDeps, ...globalDisplayDeps]
  }

  const validate = async value => {
    if (!fieldArray?.validations?.length) return true

    for (const fieldArrayValidation of fieldArray.validations) {
      // Check if the validation function exists
      const validation = fieldArraysValidations[fieldArrayValidation.name]
      if (!validation) {
        setError('animals', {
          type: 'range',
          message: `Invalid validation field array name: ${fieldArray.name}.${fieldArrayValidation.name}`,
        })
        return true
      }

      // Get the values of the fields to validate from the form definition fields
      const inputFieldsValues = fieldArrayValidation.inputFieldsValues.reduce(
        (acc, currentValue) => {
          return {
            ...acc,
            [currentValue]: getValues(currentValue),
          }
        },
        {},
      )
      inputFieldsValues.value = value

      // Call the validation function
      const validationMessage = await validation(inputFieldsValues)
      if (validationMessage) {
        setError('animals', { type: 'range', message: validationMessage })
      }
      return true
    }
  }

  return (
    <Controller
      control={control}
      name={`${fieldArray.name}`}
      render={({ _ }) => (
        <Grid item {...fieldArray.gridSizing}>
          {fields.map((item, index) => (
            <Paper
              key={item.id}
              elevation={0}
              sx={{
                padding: 2,
                marginTop: 2,
                borderRadius: '5px 5px 5px 5px',
                border: '1px solid #dcdcdc',
                backgroundColor: 'inherit',
              }}
            >
              <Grid container spacing={1}>
                {fieldArray.fields.map(formField => (
                  <DynamicFieldSwitcher
                    key={`${fieldArray.name}.${item.id}.${formField.name}`}
                    checkDependencies={checkDependencies}
                    control={control}
                    fieldArrayIndex={index}
                    fieldArrayName={fieldArray.name}
                    formField={{
                      ...formField,
                      name: `${fieldArray.name}.${index}.${formField.name}`,
                      dependencies: {
                        display: transformDisplayDeps(formField, index),
                        disabled: transformDeps(formField.dependencies.disabled, index),
                        value: transformValueDeps(formField.dependencies.value, index),
                      },
                    }}
                    index={index}
                    readOnly={readOnly}
                  />
                ))}
              </Grid>
              <Box pt={2}>
                {(!fieldArray.required || index > 0) && (
                  <Button
                    color="error"
                    disabled={readOnly}
                    endIcon={<DeleteIcon />}
                    size="small"
                    type="button"
                    variant="text"
                    onClick={() => remove(index)}
                  >
                    BORRAR
                  </Button>
                )}
              </Box>
            </Paper>
          ))}
          <Box pt={2}>
            <Button
              disabled={readOnly}
              endIcon={<Add />}
              size="small"
              type="button"
              variant="contained"
              onClick={() => {
                append()
                if (!readOnly) lfStore.setItem('dirtyForm', true)
              }}
            >
              AGREGAR {fieldArray.label || ''}
            </Button>
          </Box>
        </Grid>
      )}
      rules={{
        validate,
      }}
    />
  )
}

export default FieldArrayWrapper
